import utils from '@/helpers/utils';

export default {
  state: {
    totals: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
    search: {
      search_text: '',
      start_date: '',
      end_date: '',
      search_tags: [],
    },
  },
  mutations: {
    CHANGE_TARGETS_SEARCH_TAGS(state, payload) {
      state.search.search_tags = payload;
    },
    CHANGE_TARGETS_TOTALS(state, payload) {
      state.totals = payload;
    },
    CHANGE_TARGETS_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    CHANGE_TARGETS_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.search[key] = payload[key];
      });
    },
  },
  actions: {
    setTargetsSearchTags(context, payload) {
      context.commit('CHANGE_TARGETS_SEARCH_TAGS', payload);
    },
    setTargetsTotals(context, payload) {
      context.commit('CHANGE_TARGETS_TOTALS', payload);
    },
    setTargetsPagination(context, payload) {
      context.commit('CHANGE_TARGETS_PAGINATION', payload);
    },
    setTargetsFilters(context, payload = {}) {
      context.commit('CHANGE_TARGETS_FILTERS', payload);
    },
    setTargetsPage(context, payload) {
      context.commit('CHANGE_TARGETS_PAGINATION', {
        ...context.state.pagination,
        page: payload,
      });
    },
  },
  getters: {
    getTargetsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.search),
        ...state.pagination,
      };
      if (state.pagination.sortBy) {
        params.order = state.pagination.sortBy[0];
        params.direction = state.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },
  },
};
