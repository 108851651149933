import utils from '@/helpers/utils';

export default {
  state: {
    totals: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },

    projects: {
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      search: {
        search_tags: [],
        search_text: '',
        start_date: '',
        end_date: '',
      },
    },

    executives: {
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      search_tags: [],
    },

    search: {
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      search_tags: [],
      search_text: '',
      start_date: '',
      end_date: '',
    },
  },
  mutations: {
    CHANGE_CLIENTS_SEARCH_TAGS(state, payload) {
      state.search.search_tags = payload;
    },
    CHANGE_CLIENTS_TOTALS(state, payload) {
      state.totals = payload;
    },
    CHANGE_CLIENTS_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    CHANGE_CLIENT_PROJECTS_SEARCH_TAGS(state, payload) {
      state.projects.search.search_tags = payload;
    },
    CHANGE_CLIENT_PROJECTS_TOTALS(state, payload) {
      state.projects.totals = payload;
    },
    CHANGE_CLIENT_EXECUTIVES_TOTALS(state, payload) {
      state.executives.totals = payload;
    },
    CHANGE_CLIENT_PROJECTS_PAGINATION(state, payload) {
      state.projects.pagination = payload;
    },
    CHANGE_CLIENT_EXECUTIVES_PAGINATION(state, payload) {
      state.executives.pagination = payload;
    },
    CHANGE_CLIENT_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.search[key] = payload[key];
      });
    },
    CHANGE_CLIENT_PROJECTS_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.projects.search[key] = payload[key];
      });
    },
  },
  actions: {
    setClientsSearchTags(context, payload) {
      context.commit('CHANGE_CLIENTS_SEARCH_TAGS', payload);
    },
    setClientsTotals(context, payload) {
      context.commit('CHANGE_CLIENTS_TOTALS', payload);
    },
    setClientsPagination(context, payload) {
      context.commit('CHANGE_CLIENTS_PAGINATION', payload);
    },

    setClientProjectsSearchTags(context, payload) {
      context.commit('CHANGE_CLIENT_PROJECTS_SEARCH_TAGS', payload);
    },
    setClientProjectsTotals(context, payload) {
      context.commit('CHANGE_CLIENT_PROJECTS_TOTALS', payload);
    },
    setClientExecutivesTotals(context, payload) {
      context.commit('CHANGE_CLIENT_EXECUTIVES_TOTALS', payload);
    },
    setClientProjectsPagination(context, payload) {
      context.commit('CHANGE_CLIENT_PROJECTS_PAGINATION', payload);
    },
    setClientExecutivesPagination(context, payload) {
      context.commit('CHANGE_CLIENT_EXECUTIVES_PAGINATION', payload);
    },
    setClientFilters(context, payload = {}) {
      context.commit('CHANGE_CLIENT_FILTERS', payload);
    },
    setClientProjectsFilters(context, payload = {}) {
      context.commit('CHANGE_CLIENT_PROJECTS_FILTERS', payload);
    },
    setClientsPage(context, payload) {
      context.commit('CHANGE_CLIENTS_PAGINATION', {
        ...context.state.pagination,
        page: payload,
      });
    },
    setClientsProjectsPage(context, payload) {
      context.commit('CHANGE_CLIENT_PROJECTS_PAGINATION', {
        ...context.state.projects.pagination,
        page: payload,
      });
    },
  },
  getters: {
    getClientsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.search),
        ...state.pagination,
      };
      if (state.pagination.sortBy) {
        params.order = state.pagination.sortBy[0];
        params.direction = state.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },

    getClientProjectsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.projects.search),
        ...state.projects.pagination,
      };
      if (state.projects.pagination.sortBy) {
        params.order = state.projects.pagination.sortBy[0];
        params.direction = state.projects.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },

    getClientExecutivesSearchParams(state) {
      const params = {
        search_tags: utils.mapTagsArrayToSearchObject(state.executives.search_tags),
        ...state.executives.pagination,
      };
      if (state.executives.pagination.sortBy) {
        params.order = state.executives.pagination.sortBy[0];
        params.direction = state.executives.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },
  },
};
