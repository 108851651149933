import utils from '@/helpers/utils';

export default {
  state: {
    activityDateRange: [new Date().toISOString().substr(0, 10)],
    candidate: {
      project: {
        id: null,
        name: '',
      },
      company: {
        id: null,
        name: '',
      },
      contact: {
        id: null,
        name: '',
      },
      project_id: null,
      target_remark_id: null,
      interest_level_id: null,
    },
    dateRange: [],
    search: {
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      search_tags: [],
      search_text: '',
      start_date: '',
      end_date: '',
    },
  },

  mutations: {
    CHANGE_ACTIVITY_DATE_RANGE(state, payload) {
      state.activityDateRange = payload;
    },
    CHANGE_CANDIDATE_PROJECT_ID(state, payload) {
      state.candidate.project_id = payload;
    },
    CHANGE_CANDIDATE_COMPANY(state, payload) {
      state.candidate.company = payload;
    },
    CHANGE_CANDIDATE_CONTACT(state, payload) {
      state.candidate.contact = payload;
    },
    CHANGE_TARGET_REMARK(state, payload) {
      state.candidate.target_remark_id = payload;
    },
    CHANGE_INTEREST_LEVEL(state, payload) {
      state.candidate.interest_level_id = payload;
    },
    CLEAR_CANDIDATE(state) {
      state.candidate = {
        project: {
          id: null,
          name: '',
        },
        company: {
          id: null,
          name: '',
        },
        contact: {
          id: null,
          name: '',
        },
        project_id: null,
        target_remark_id: null,
        interest_level_id: null,
      };
    },
    CHANGE_PROJECTS_DATE_RANGE(state, payload) {
      state.dateRange = payload;
    },
    CHANGE_PROJECTS_SEARCH_TAGS(state, payload) {
      state.search.search_tags = payload;
    },
    CHANGE_PROJECTS_TOTALS(state, payload) {
      state.search.totals = payload;
    },
    CHANGE_PROJECTS_PAGINATION(state, payload) {
      state.search.pagination = payload;
    },
    CHANGE_PROJECTS_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.search[key] = payload[key];
      });
    },
  },

  actions: {
    setActivityDateRange(context, payload) {
      context.commit('CHANGE_ACTIVITY_DATE_RANGE', payload);
    },
    setCandidateProjectId(context, payload) {
      context.commit('CHANGE_CANDIDATE_PROJECT_ID', payload);
    },
    setCandidateCompany(context, payload) {
      context.commit('CHANGE_CANDIDATE_COMPANY', payload);
    },
    setCandidateContact(context, payload) {
      context.commit('CHANGE_CANDIDATE_CONTACT', payload);
    },
    setInterestLevel({ commit }, payload) {
      commit('CHANGE_INTEREST_LEVEL', payload);
    },
    setTargetRemark({ commit }, payload) {
      commit('CHANGE_TARGET_REMARK', payload);
    },
    clearCandidate(context) {
      context.commit('CLEAR_CANDIDATE');
    },
    setProjectsDateRange(context, payload) {
      context.commit('CHANGE_PROJECTS_DATE_RANGE', payload);
    },
    setProjectsSearchTags(context, payload) {
      context.commit('CHANGE_PROJECTS_SEARCH_TAGS', payload);
    },
    setProjectsFilters(context, payload = {}) {
      context.commit('CHANGE_PROJECTS_FILTERS', payload);
    },
    setProjectsTotals(context, payload) {
      context.commit('CHANGE_PROJECTS_TOTALS', payload);
    },
    setProjectsPagination(context, payload) {
      context.commit('CHANGE_PROJECTS_PAGINATION', payload);
    },
    clearCandidateAdditionalParams(context) {
      context.commit('CHANGE_CANDIDATE_PROJECT_ID', null);
      context.commit('CHANGE_INTEREST_LEVEL', null);
      context.commit('CHANGE_TARGET_REMARK', null);
    },
    setProjectsPage(context, payload) {
      context.commit('CHANGE_PROJECTS_PAGINATION', {
        ...context.state.search.pagination,
        page: payload,
      });
    },
  },

  getters: {
    getDateRange(state) {
      return state.dateRange;
    },
    getActivityDateRange(state) {
      return state.activityDateRange;
    },
    getCandidateCompany(state) {
      return state.candidate.company;
    },
    getCandidate(state) {
      return state.candidate;
    },
    getProjectsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.search),
        ...state.search.pagination,
      };
      if (state.search.pagination.sortBy) {
        params.order = state.search.pagination.sortBy[0];
        params.direction = state.search.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },
  },
};
