<template>
  <div class="page-callendar-room">
    <v-row>
      <v-col>
        <template v-if="isProcessing">
          <h4 class="mt-md-5 text-center cursor-default">
            <v-progress-circular indeterminate></v-progress-circular>
          </h4>
        </template>

        <template v-else-if="events.length">
          <v-alert class="d-none d-print-block" dense type="info">
            {{ calendarDateFormatted }}
          </v-alert>
          <transition-group name="list" tag="p">
            <CalendarEvent
              v-for="event in events"
              :key="event.id"
              :event-data="event"
              @updateStatus="changeStatus"
              @updated="getEvents"
              @deleted="getEvents"
            />
          </transition-group>
        </template>

        <template v-else>
          <h4 class="mt-md-5 text-center cursor-default">
            There is no events in that time period
          </h4>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import eventBus from '@/eventBus';
import eventsApi from '@/api/v1/recruiter/events';
import CalendarEvent from '@/components/Recruiter/Calendar/CalendarEvent.vue';

export default {
  components: {
    CalendarEvent,
  },

  data() {
    return {
      isDialogVisible: false,
      isProcessing: false,
      events: [],
    };
  },

  computed: {
    calendarDate() {
      return this.$store.getters.getDate;
    },

    calendarDateFormatted() {
      return this.calendarDate.toISOString().substr(0, 10);
    },

    selectedPlaceId() {
      return this.$store.getters.getPlace;
    },
  },

  watch: {
    calendarDate() {
      this.getEvents();
    },

    selectedPlaceId() {
      this.getEvents();
    },
  },

  created() {
    this.getEvents();
  },

  mounted() {
    eventBus.$on('update-events', () => {
      this.getEvents();
    });
  },

  methods: {
    getEvents() {
      this.isProcessing = true;

      const parameters = {
        date_range: [moment(this.$store.getters.getDate).format('DD-MM-YYYY')],
        from_contact: false,
      };

      if (this.selectedPlaceId) parameters.place_id = this.selectedPlaceId;

      eventsApi
        .index(parameters)
        .then(resp => {
          this.events = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    changeStatus(event) {
      eventsApi
        .update(event.id, event)
        .then(() => {
          this.getEvents();
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-callendar-room {
  max-width: 820px;
  margin: 0 auto;
  padding: 0px 12px 24px;
  @media (min-width: 960px) {
    padding-top: 24px;
  }

  &__raport {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    text-transform: uppercase;
  }
}
.list-enter-active {
  transition: all 0.3s;
}
.list-leave-active {
  transition: all 0.1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
