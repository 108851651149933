import axios from '@/api/axios';

const OFFICES_URL = '/offices';

export default {
  index() {
    return axios.get(OFFICES_URL);
  },
  show(id) {
    return axios.get(`${OFFICES_URL}/${id}`);
  },
  create(data) {
    return axios.post(OFFICES_URL, data);
  },
  update(id, data) {
    return axios.patch(`${OFFICES_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${OFFICES_URL}/${id}`);
  },
};
