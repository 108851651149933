var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-index-active"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('FiltersBar',{attrs:{"filters-list-params":_vm.searchParams,"all-filters":_vm.allFilters},on:{"search":_vm.getClients}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.clientsHeaders,"items":_vm.clients,"options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"multi-sort":false,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          }},on:{"update:options":function($event){_vm.pagination=$event},"update:page":_vm.debounceGetClients,"update:items-per-page":_vm.debounceGetClients,"update:sort-by":_vm.getClients,"update:sort-desc":_vm.getClients},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item.created_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("displayDate")(value,'YYYY-MM-DD'))+" ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'client-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.origin_office",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.origin_office)+" ")]}},{key:"item.project_count",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'client-projects', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.project_count)+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }