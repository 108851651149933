<template>
  <div class="page-project">
    <v-container fluid>
      <v-row class="page-project__row">
        <v-col>
          <TagGroup
            title="Countries"
            :tags-data="countries"
            tags-type="countries"
            @refresh="getTags('countries')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Nationalities"
            :tags-data="nationalities"
            tags-type="nationalities"
            @refresh="getTags('nationalities')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Languages"
            :tags-data="languages"
            tags-type="languages"
            @refresh="getTags('languages')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Language Levels"
            :tags-data="languageLevels"
            tags-type="languageLevels"
            @refresh="getTags('languageLevels')"
            @delete="deleteTag"
          />
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import tagsApi from '@/api/v1/library/tags';

import TagGroup from '@/components/Admin/TagGroup.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    TagGroup,
    ConfirmDialog,
  },
  data() {
    return {
      countries: [],
      nationalities: [],
      languages: [],
      languageLevels: [],
    };
  },

  created() {
    this.getTags('countries');
    this.getTags('nationalities');
    this.getTags('languages');
    this.getTags('languageLevels');
  },

  methods: {
    getTags(type) {
      tagsApi[type].index().then(response => {
        this[type] = response.data;
      });
    },

    deleteTag(payload) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            tagsApi[payload.type].delete(payload.id).then(() => {
              this.getTags(payload.type);
            });
          }
        });
    },
  },
};
</script>
