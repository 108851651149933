import qs from 'qs';
import axios from '@/api/axios';

const CONTACTS_URL = '/contacts';

export default {
  index(params) {
    return axios.get(CONTACTS_URL, {
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
  show(id) {
    return axios.get(`${CONTACTS_URL}/${id}`);
  },
  create(data) {
    return axios.post(CONTACTS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${CONTACTS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${CONTACTS_URL}/${id}`);
  },
  getAttachment(contactId, attachmentId) {
    return axios.get(`${CONTACTS_URL}/${contactId}/attachments/${attachmentId}`);
  },
  createAttachment(contactId, formData) {
    return axios.post(`${CONTACTS_URL}/${contactId}/attachments`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  deleteAttachment(contactId, attachmentId) {
    return axios.delete(`${CONTACTS_URL}/${contactId}/attachments/${attachmentId}`);
  },
  updateAttachment(contactId, attachmentId, data) {
    return axios.patch(`${CONTACTS_URL}/${contactId}/attachments/${attachmentId}`, data);
  },
  archiveUser(contactId, data) {
    return axios.patch(`${CONTACTS_URL}/${contactId}`, data);
  },
};
