<template>
  <div class="app-form employee-form">
    <v-row>
      <v-col cols="12">
        <h2 clas="app-form__title title">
          <template v-if="isEditForm">
            Edit employee
          </template>
          <template v-else>
            Add employee
          </template>
        </h2>
      </v-col>
    </v-row>

    <v-form ref="employeeForm" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">Name</label>
          <v-text-field
            v-model="employee.last_name"
            rel="employeeNameField"
            :rules="[rules.required]"
            height="36"
            solo
          />
        </v-col>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">First name</label>
          <v-text-field v-model="employee.first_name" :rules="[rules.required]" height="36" solo />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">Login</label>
          <v-text-field v-model="employee.login" :rules="[rules.required]" height="36" solo />
        </v-col>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">Password</label>
          <v-text-field
            v-model="employee.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="isEditForm ? [] : [rules.required]"
            height="36"
            solo
            @click:append="showPassword = !showPassword"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-switch v-model="employee.active" label="Is active" color="secondary"></v-switch>
        </v-col>
        <v-col>
          <v-switch v-model="employee.library_access" label="Is admin" color="secondary"></v-switch>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12 text-right">
          <div
            class="employee-form__actions"
            :class="{ 'employee-form__actions--double': isEditForm }"
          >
            <v-btn
              v-if="isEditForm"
              v-shortkey="['ctrl', 'shift', 'd']"
              color="error"
              :loading="isProcessing"
              elevation="0"
              @shortkey="deleteEmployee"
              @click="deleteEmployee"
            >
              Delete employee
            </v-btn>

            <v-btn
              v-shortkey="['ctrl', 'shift', 's']"
              color="secondary"
              :loading="isProcessing"
              elevation="0"
              @shortkey="submitForm"
              @click="submitForm"
            >
              <template v-if="isEditForm">
                Save employee
              </template>
              <template v-else>
                Add employee
              </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import usersLibraryApi from '@/api/v1/library/users';
import externalRules from '@/helpers/validators';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },

  props: {
    employeeData: {
      type: Object,
      required: false,
      default: () => {},
    },
    formContext: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFormValid: true,
      isProcessing: false,
      rules: externalRules,
      employee: {
        last_name: '',
        firstName: '',
        login: '',
        password: '',
        library_access: false,
        active: false,
      },
      showPassword: false,
    };
  },

  computed: {
    isEditForm() {
      return this.formContext === 'edit';
    },
  },

  watch: {
    employeeData: {
      handler() {
        if (this.$refs.employeeForm) this.$refs.employeeForm.reset();
        if (this.formContext === 'edit') {
          this.employee = Object.assign({}, this.employeeData);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    submitForm() {
      this.$refs.employeeForm.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.isEditForm) {
          usersLibraryApi
            .update(this.employeeData.id, this.employee)
            .then(() => {
              this.$refs.employeeForm.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          usersLibraryApi
            .create(this.employee)
            .then(() => {
              this.$refs.employeeForm.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    deleteEmployee() {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            usersLibraryApi
              .delete(this.employeeData.id)
              .then(() => {
                this.$refs.employeeForm.reset();
                this.$emit('success');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              })
              .finally(() => {
                this.isProcessing = false;
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-form {
  max-width: 450px;

  &__actions {
    display: flex;
    justify-content: flex-end;

    &--double {
      justify-content: space-between;
    }
  }
}
</style>
