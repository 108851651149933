var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-projects"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"custom-table elevation-1",attrs:{"headers":_vm.contactProjectsHeaders,"items":_vm.contactProjects,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          },"disable-filtering":""},on:{"update:options":function($event){_vm.pagination=$event},"update:page":_vm.debounceGetProjects,"update:items-per-page":_vm.debounceGetProjects,"update:sort-by":_vm.getContactProjects,"update:sort-desc":_vm.getContactProjects},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFromDatetime(item.created_at))+" ")]}},{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'project-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'project-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.target_remark_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsTargetRemarks,"item-value":"id","item-text":"name","label":"Select"},on:{"change":function($event){return _vm.updateCandidate(item)}},model:{value:(item.target_remark_id),callback:function ($$v) {_vm.$set(item, "target_remark_id", $$v)},expression:"item.target_remark_id"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}},{key:"item.interest_level_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsLevelOfInterest,"item-value":"id","item-text":"name","label":"Select"},on:{"change":function($event){return _vm.updateCandidate(item)}},model:{value:(item.interest_level_id),callback:function ($$v) {_vm.$set(item, "interest_level_id", $$v)},expression:"item.interest_level_id"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.deleteCandidate(item.target_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }