<template>
  <v-row class="app-form-submit">
    <v-col class="text-right">
      <div class="app-form-submit__container">
        <slot></slot>
        <v-btn
          v-shortkey="['ctrl', 'shift', 's']"
          color="secondary"
          :loading="processing"
          min-width="112"
          elevation="0"
          class="app-form__submit"
          @shortkey="submit"
          @click="submit"
        >
          Save
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    processing: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-form-submit {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
