<template>
  <div class="client-summary">
    <v-container fluid>
      <FormClient
        v-if="dataLoaded"
        ref="formClient"
        :client-data="clientData"
        @success="submitSuccess"
      />
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import clientsApi from '@/api/v1/recruiter/clients';
import FormClient from '@/components/Recruiter/Clients/FormClient.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    FormClient,
    ConfirmDialog,
  },

  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      clientData: {},
    };
  },

  created() {
    this.getClient();
  },

  beforeRouteLeave(to, from, next) {
    this.$refs.formClient.isClientModified().then(resp => {
      if (resp) {
        this.$refs.confirmDialog
          .open({
            message: 'Are you sure you want to leave? There are unsaved changes.',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          })
          .then(confirm => {
            if (confirm) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    });
  },

  methods: {
    getClient() {
      this.dataLoaded = false;

      clientsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.clientData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoaded = true;
        });
    },

    submitSuccess() {
      this.$store.dispatch('showSuccess', ['Client updated']);
      this.getClient();
    },
  },
};
</script>

<style lang="scss" scoped>
.client-summary {
  background: inherit;
}
</style>
