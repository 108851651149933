export default {
  state: {
    filters: {},
  },
  mutations: {
    SET_FILTERS(state, payload) {
      state.filters = payload;
    },
  },
  actions: {
    setFilters(context, payload) {
      context.commit('SET_FILTERS', payload);
    },
  },
  getters: {
    getFilterItems: state => type => {
      return state.filters[type] || [];
    },
  },
};
