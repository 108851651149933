import utils from '@/helpers/utils';

export default {
  state: {
    totals: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
    search: {
      search_tags: [],
      search_text: '',
      start_date: '',
      end_date: '',
    },

    contacts: {
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      search: {
        search_tags: [],
        search_text: '',
        start_date: '',
        end_date: '',
      },
    },
  },

  mutations: {
    CHANGE_COMPANIES_SEARCH_TAGS(state, payload) {
      state.search.search_tags = payload;
    },
    CHANGE_COMPANIES_TOTALS(state, payload) {
      state.totals = payload;
    },
    CHANGE_COMPANIES_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    CHANGE_COMPANY_CONTACTS_SEARCH_TAGS(state, payload) {
      state.contacts.search.search_tags = payload;
    },
    CHANGE_COMPANY_CONTACTS_TOTALS(state, payload) {
      state.contacts.totals = payload;
    },
    CHANGE_COMPANY_CONTACTS_PAGINATION(state, payload) {
      state.contacts.pagination = payload;
    },
    CHANGE_COMPANY_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.search[key] = payload[key];
      });
    },
    CHANGE_COMPANY_CONTACTS_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.contacts.search[key] = payload[key];
      });
    },
  },

  actions: {
    setCompaniesSearchTags(context, payload) {
      context.commit('CHANGE_COMPANIES_SEARCH_TAGS', payload);
    },
    setCompaniesTotals(context, payload) {
      context.commit('CHANGE_COMPANIES_TOTALS', payload);
    },
    setCompaniesPagination(context, payload) {
      context.commit('CHANGE_COMPANIES_PAGINATION', payload);
    },

    setCompanyContactsSearchTags(context, payload) {
      context.commit('CHANGE_COMPANY_CONTACTS_SEARCH_TAGS', payload);
    },
    setCompanyContactsTotals(context, payload) {
      context.commit('CHANGE_COMPANY_CONTACTS_TOTALS', payload);
    },
    setCompanyContactsPagination(context, payload) {
      context.commit('CHANGE_COMPANY_CONTACTS_PAGINATION', payload);
    },
    setCompanyFilters(context, payload = {}) {
      context.commit('CHANGE_COMPANY_FILTERS', payload);
    },
    setCompanyContactsFilters(context, payload = {}) {
      context.commit('CHANGE_COMPANY_CONTACTS_FILTERS', payload);
    },
    setCompanyPage(context, payload) {
      context.commit('CHANGE_COMPANIES_PAGINATION', {
        ...context.state.pagination,
        page: payload,
      });
    },
    setCompanyContactsPage(context, payload) {
      context.commit('CHANGE_COMPANY_CONTACTS_PAGINATION', {
        ...context.state.contacts.pagination,
        page: payload,
      });
    },
  },

  getters: {
    getCompaniesSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.search),
        ...state.pagination,
      };
      if (state.pagination.sortBy) {
        params.order = state.pagination.sortBy[0];
        params.direction = state.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },

    getCompanyContactsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.contacts.search),
        ...state.contacts.pagination,
      };
      if (state.contacts.pagination.sortBy) {
        params.order = state.contacts.pagination.sortBy[0];
        params.direction = state.contacts.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },
  },
};
