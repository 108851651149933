<template>
  <div class="contact-career">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="careerHistoryHeaders"
            :items="careerHistory"
            :loading="isLoading"
            disable-filtering
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template #item.company="{ item }">
              <router-link
                v-if="item.company"
                :to="{ name: 'company-summary', params: { id: item.company_id } }"
                class="table-cell-link"
              >
                {{ item.company }}
              </router-link>
            </template>
            <template #item.action="{ item }">
              <v-btn color="primary" icon @click="editCareerEntry(item.id)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="primary" icon @click="deleteCareerEntry(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <app-dialog :show="isCareerEntryFormVisible" @close="closeDialog">
      <FormCareerEntry
        v-if="isCareerEntryFormVisible"
        :career-entry-id="selectedCareerEntry"
        @success="careerEntryEdited"
      />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import careerApi from '@/api/v1/recruiter/career';
import consts from '@/api/consts';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormCareerEntry from '@/components/Recruiter/Contacts/FormCareerEntry.vue';

const careerHistoryHeaders = consts.contacts.careerHistoryHeaders;

export default {
  components: {
    ConfirmDialog,
    AppDialog,
    FormCareerEntry,
  },

  data() {
    return {
      isLoading: false,
      isCareerEntryFormVisible: false,
      careerHistory: [],
      careerHistoryHeaders,
      selectedCareerEntry: null,
    };
  },

  created() {
    this.getCareerHistory();
  },

  methods: {
    getCareerHistory() {
      this.isLoading = true;
      careerApi
        .index({ contact_id: this.$route.params.id })
        .then(resp => {
          this.careerHistory = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    editCareerEntry(id) {
      this.selectedCareerEntry = id;
      this.isCareerEntryFormVisible = true;
    },

    deleteCareerEntry(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            careerApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Deleted']);
                this.getCareerHistory();
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    closeDialog() {
      this.isCareerEntryFormVisible = false;
      this.selectedEvent = null;
    },

    careerEntryEdited() {
      this.$store.dispatch('showSuccess', ['Event updated']);
      this.closeDialog();
      this.getCareerHistory();
    },
  },
};
</script>
