import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import instance from '@/api/axios';
import vueShortkey from 'vue-shortkey';
import filters from './filters';
import App from './App.vue';
import router from './router';
import store from './store/index';
import './registerServiceWorker';
import 'normalize.css';

Vue.config.productionTip = false;

Vue.use(vueShortkey);
Vue.use(filters);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

instance.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
    store.dispatch('logout');
    router.push('/login');
  } else {
    throw error;
  }
});
