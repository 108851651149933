import { cloneDeep } from 'lodash';

const utils = {
  trimObj(obj) {
    const trimmedObject = cloneDeep(obj);

    for (var propName in trimmedObject) {
      if (propName === 'phone_numbers') {
        trimmedObject[propName] = trimmedObject[propName].filter(o => o.phone_number !== '');
      }
      if (propName === 'emails') {
        trimmedObject[propName] = trimmedObject[propName].filter(o => o.email !== '');
      }
      if (propName === 'languages') {
        trimmedObject[propName] = trimmedObject[propName].filter(
          o => o.language_id !== '' && o.language_level_id !== '',
        );
      }
      if (
        trimmedObject[propName] === null ||
        trimmedObject[propName] === undefined ||
        trimmedObject[propName] === ''
      ) {
        delete trimmedObject[propName];
      }
    }
    return trimmedObject;
  },

  mapTagsArrayToSearchObject(arr) {
    let searchTagsObject = {};
    if (arr) {
      arr.forEach(item => {
        if (!searchTagsObject[item.key]) {
          searchTagsObject[item.key] = item.dateRange ? {} : new Array();
        }
        if (item.dateRange) {
          searchTagsObject[item.key] = item.value;
          return;
        }
        if (item.multiselect) {
          item.value.forEach(val => {
            searchTagsObject[item.key].push(val.id);
          });
          return;
        }
        if (item.select || item.autocomplete) {
          searchTagsObject[item.key].push(item.value.id);
        } else {
          searchTagsObject[item.key].push(item.value);
        }
      });
    }
    return searchTagsObject;
  },
  getSearchParams(params) {
    let filteredParams = cloneDeep(params);
    Object.keys(filteredParams).forEach(key => {
      if (!filteredParams[key]) {
        delete filteredParams[key];
      }
      if (key === 'search_tags') {
        filteredParams[key] = this.mapTagsArrayToSearchObject(filteredParams[key]);
      }
    });
    return filteredParams;
  },
};

export default utils;
