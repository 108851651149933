<template>
  <div class="contacts-index">
    <header-index-page title="Contacts">
      <template #action>
        <download-button class="mr-8" :download-params="reportParams">
          REPORT
        </download-button>

        <v-btn
          v-shortkey="['ctrl', 'shift', 'a']"
          color="secondary"
          elevation="0"
          min-width="112"
          @click="showDialog"
          @shortkey="showDialog"
        >
          Add new contact
        </v-btn>
      </template>
    </header-index-page>

    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getContacts"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="contactsHeaders"
            :items="contacts"
            item-key="id"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :expanded.sync="expanded"
            :show-expand="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="custom-table elevation-1"
            @update:page="debounceGetContacts"
            @update:items-per-page="debounceGetContacts"
            @update:sort-by="getContacts"
            @update:sort-desc="getContacts"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.updated_at | displayDate('YYYY-MM-DD') }}
                </td>
                <td>
                  <router-link
                    :to="{ name: 'contact-summary', params: { id: item.id } }"
                    class="table-cell-link"
                  >
                    {{ item.last_name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'contact-summary', params: { id: item.id } }"
                    class="table-cell-link"
                  >
                    {{ item.first_name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    v-if="item.company_name"
                    :to="{ name: 'company-summary', params: { id: item.company_id } }"
                    class="table-cell-link"
                  >
                    {{ item.company_name }}
                  </router-link>
                </td>
                <td>{{ item.function }}</td>
                <td>{{ item.fc_code }}</td>
                <td class="custom-table__action-col">
                  <v-icon
                    v-if="true"
                    class="custom-table__expand-toggler"
                    @click="toggleExtend(item)"
                  >
                    mdi-chevron-down
                  </v-icon>
                </td>
              </tr>
            </template>

            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <v-data-table
                  :headers="contactDetailsHeaders"
                  :items="item.targets"
                  hide-default-footer
                  class="custom-table__expanded-table elevation-0"
                >
                  <template #item.target_remark_id="{ item }">
                    <div class="custom-table__select">
                      <v-select
                        v-model="item.target_remark_id"
                        :items="optionsTargetRemarks"
                        item-value="id"
                        item-text="name"
                        label="Select"
                        v-bind="componentsProps.simpleSelect"
                        @change="updateTarget(item)"
                      ></v-select>
                    </div>
                  </template>

                  <template #item.interest_level_id="{ item }">
                    <div class="custom-table__select">
                      <v-select
                        v-model="item.interest_level_id"
                        :items="optionsLevelOfInterest"
                        item-value="id"
                        item-text="name"
                        label="Select"
                        v-bind="componentsProps.simpleSelect"
                        @change="updateTarget(item)"
                      ></v-select>
                    </div>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormContact v-if="isDialogVisible" @success="getContacts" />
    </app-dialog>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import consts from '@/api/consts';
import customComponentsProps from '@/helpers/customComponentsProps';
import tagsApi from '@/api/v1/library/tags';
import contactsApi from '@/api/v1/recruiter/contacts';
import projectTargetsApi from '@/api/v1/recruiter/projectTargets';
import HeaderIndexPage from '@/components/Common/HeaderIndexPage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import FormContact from '@/components/Recruiter/Contacts/FormContact.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const componentsProps = customComponentsProps;
const contactsHeaders = consts.contacts.contactsHeaders;
const contactDetailsHeaders = consts.contacts.contactDetailsHeaders;

export default {
  components: {
    HeaderIndexPage,
    AppDialog,
    FiltersBar,
    FormContact,
    DownloadButton,
    TableFooterCurrentPage,
  },

  data() {
    return {
      componentsProps,
      contactsHeaders,
      contactDetailsHeaders,
      isDialogVisible: false,
      isLoading: false,
      optionsTargetRemarks: [],
      optionsLevelOfInterest: [],
      expanded: [],
      contacts: [],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.contacts.pagination;
      },
      set(val) {
        this.$store.dispatch('setContactsPagination', val);
      },
    },

    totals() {
      return this.$store.state.contacts.totals;
    },

    allFilters() {
      return this.$store.state.contacts.search || {};
    },

    reportParams() {
      const params = {
        contacts: true,
        search_tags: this.$store.getters.getContactsSearchParams.search_tags,
      };
      return params;
    },
    searchParams() {
      return {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        phone_number: {
          key: 'phone_number',
          displayName: 'Phone number',
          hidden: false,
        },
        email: {
          key: 'email',
          displayName: 'Email',
          hidden: false,
        },
        job_title: {
          key: 'job_title',
          displayName: 'Job title',
          hidden: false,
        },
        company_name: {
          key: 'company_name',
          displayName: 'Company name',
          hidden: false,
        },
        fc_code: {
          key: 'fc_code',
          displayName: 'FC code',
          hidden: false,
        },
        location: {
          key: 'location',
          displayName: 'Location',
          hidden: false,
        },
        nationality_id: {
          key: 'nationality_id',
          displayName: 'Nationality',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('nationalities'),
        },
        language_ids: {
          key: 'language_ids',
          displayName: 'Language',
          autocomplete: true,
          data: this.$store.getters.getFilterItems('languages'),
        },
        gdpr_status_id: {
          key: 'gdpr_status_id',
          displayName: 'Rodo',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('gdpr_statuses'),
        },
        placed_by: {
          key: 'placed_by_ids',
          displayName: 'Placed by',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        presented_by: {
          key: 'presented_by_ids',
          displayName: 'Presented by',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        business_dt_by: {
          key: 'business_dt_by_ids',
          displayName: 'Business by',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        interviewed_by: {
          key: 'interviewed_by_ids',
          displayName: 'Interviewed by',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        source_of: {
          key: 'source_of_ids',
          displayName: 'Source of',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        client_of: {
          key: 'client_of_ids',
          displayName: 'Client of',
          multiselect: true,
          data: this.$store.getters.getFilterItems('users'),
        },
        contact_dates: {
          key: 'contact_dates',
          displayName: 'Contact dates',
          hidden: false,
          dateRange: true,
        },
      };
    },
  },

  created() {
    this.isDialogVisible = false;
    this.getTargetRemarks();
    this.getLevelsOfInterest();
  },
  mounted() {
    this.getContacts();
  },

  methods: {
    debounceGetContacts: debounce(function() {
      this.getContacts();
    }, 500),
    getTargetRemarks() {
      this.isProcessing = true;
      tagsApi.targetRemarks
        .index()
        .then(resp => {
          this.optionsTargetRemarks = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getLevelsOfInterest() {
      this.isProcessing = true;
      tagsApi.interestLevels
        .index()
        .then(resp => {
          this.optionsLevelOfInterest = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getContacts(filters) {
      if (filters) this.$store.dispatch('setContactsFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setContactsPage', 1);

      this.isDialogVisible = false;
      this.isLoading = true;

      contactsApi
        .index(this.$store.getters.getContactsSearchParams)
        .then(resp => {
          this.contacts = resp.data.collection;
          this.$store.dispatch('setContactsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToContact(contact) {
      this.$router.push({ name: 'contact-summary', params: { id: contact.id } });
    },

    toggleExtend(contact) {
      const contactPosition = this.expanded.findIndex(el => el.id === contact.id);

      if (contactPosition < 0) {
        this.expanded.push(contact);
      } else {
        this.expanded.splice(contactPosition, 1);
      }
    },

    showDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },

    updateTarget(target) {
      this.isLoading = true;

      projectTargetsApi
        .update(target.id, target)
        .then(() => {
          this.getTargets;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
