<template>
  <div>
    <v-text-field
      v-model="query"
      :label="label"
      hide-details
      prepend-inner-icon="mdi-magnify"
      single-line
      dense
    />
    <div class="items">
      <v-checkbox
        v-for="item in filteredItems"
        :key="item.id"
        :input-value="checkIfSelected(item)"
        :label="item.name"
        :ripple="false"
        @change="changeItem($event, item)"
      >
        <span>{{ item.name }}</span>
      </v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    selectedItemsProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      query: '',
      selectedItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (this.query.length)
        return this.items.filter(item =>
          item.name.toLowerCase().includes(this.query.toLowerCase()),
        );
      else return this.items;
    },
  },
  created() {
    this.selectedItems = this.selectedItemsProp || [];
  },
  methods: {
    changeItem(event, item) {
      if (event) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
      }
      this.$emit('input', this.selectedItems);
    },
    checkIfSelected(item) {
      return this.selectedItems.some(selectedItem => {
        return selectedItem.id === item.id;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  list-style-type: none;
  height: 250px !important;
  overflow-y: auto;
  padding: 12px 0;
  &::-webkit-scrollbar {
    display: none;
  }
}
::v-deep .v-input__slot > .v-label {
  font-size: 16px;
  font-weight: 700;
  color: $black;
}
</style>
