<template>
  <div class="wizard-add-candidate">
    <v-stepper v-model="currentStep" alt-labels editable class="wizard-add-candidate__stepper">
      <v-stepper-header class="wizard-add-candidate__stepper-header">
        <v-stepper-step :complete="currentStep > 1" step="1">
          Company
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2">
          Contact
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          Candidate
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <StepCompany v-if="currentStep == 1" @success="nextStep(2)" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <StepContact
            v-if="currentStep == 2"
            :selected-company="selectedCompany"
            @success="nextStep(3)"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <StepSubmit v-if="currentStep == 3" @success="wizardSuccess" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import StepContact from '@/components/Common/WizardAddCandidate/StepContact.vue';
import StepCompany from '@/components/Common/WizardAddCandidate/StepCompany.vue';
import StepSubmit from '@/components/Common/WizardAddCandidate/StepSubmit.vue';

export default {
  components: {
    StepContact,
    StepCompany,
    StepSubmit,
  },

  props: {
    startStep: {
      required: false,
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      currentStep: this.startStep || 1,
      selectedCompany: null,
    };
  },

  created() {},

  methods: {
    nextStep(num) {
      this.currentStep = num;
    },
    wizardSuccess() {
      this.$emit('success');
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-add-candidate {
  padding: 8px;
  margin-top: -20px;

  &__stepper {
    box-shadow: unset;
    background: unset !important;
  }

  &__stepper-header {
    box-shadow: unset;
  }
}
</style>
