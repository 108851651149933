import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import calendar from './modules/calendar';
import clients from './modules/clients';
import companies from './modules/companies';
import contacts from './modules/contacts';
import projects from './modules/projects';
import snackbar from './modules/snackbar';
import targets from './modules/targets';
import filters from './modules/filters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    calendar,
    clients,
    companies,
    contacts,
    projects,
    snackbar,
    targets,
    user,
    filters,
  },
});
