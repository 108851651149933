<template>
  <v-dialog
    v-model="dialogState"
    content-class="v-full-dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card class="pa-sm-5" color="#f4f8f9">
      <v-toolbar height="48" class="elevation-0" color="#f4f8f9">
        <v-btn v-shortkey="['ctrl', 'esc']" icon @click="closeDialog" @shortkey="closeDialog">
          <v-icon size="30" color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="px-sm-10 py-0 pb-10" fluid>
        <slot></slot>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    dialogState: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('close');
      },
    },
  },

  methods: {
    closeDialog() {
      this.$emit('close', this.state);
    },
  },
};
</script>
