<template>
  <div class="d-flex align-center" :style="isFiltersList ? { flexDirection: 'column' } : {}">
    <span class="picker-text mr-2">Date</span>
    <DatePickerInput
      label="Set a date"
      date-type="start_date"
      :date-string="startDate"
      @input="setDate"
    />
    <span class="picker-text mx-2">to</span>
    <DatePickerInput
      label="End date"
      date-type="end_date"
      :date-string="endDate"
      @input="setDate"
    />
  </div>
</template>

<script>
import DatePickerInput from './DatePickerInput.vue';

export default {
  name: 'DatePickerRangeGroup',
  components: {
    DatePickerInput,
  },
  props: {
    isFiltersList: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filters: {
        from: '',
        to: '',
      },
    };
  },
  methods: {
    setDate(label, value) {
      if (this.isFiltersList) {
        const datesEnum = {
          start_date: 'from',
          end_date: 'to',
        };
        this.$set(this.filters, datesEnum[label], value);
        this.$emit('input', this.filters);
        return;
      }
      this.$emit('input', label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-text {
  font-size: 16px;
  font-weight: 400;
  color: #6c7b93;
}
</style>
