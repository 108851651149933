<template>
  <div class="app-form form-company">
    <v-form ref="formCompany" v-model="isFormValid" @submit.prevent="submitForm">
      <AppFormSubmit :processing="isProcessing" @submit="submitForm" />

      <v-row>
        <v-col cols="12">
          <div class="app-form__group">
            <label class="app-form__label">Company name *</label>
            <v-text-field
              v-model="company.name"
              :rules="[rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Address</label>
            <v-text-field v-model="company.address" v-bind="componentsProps.simpleTextField" />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">City *</label>
            <v-text-field
              v-model="company.city"
              :rules="[rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Country *</label>
            <v-select
              v-model="company.country_id"
              :items="countries"
              :rules="[rules.required]"
              item-value="id"
              item-text="name"
              v-bind="componentsProps.select"
              class="app-form__select"
            />
          </div>

          <div class="app-form__group app-form__group--mw-200">
            <label class="app-form__label">Phone number</label>
            <v-text-field v-model="company.phone_number" v-bind="componentsProps.simpleTextField" />
          </div>

          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col cols="4">
                <label class="app-form__label">Company group *</label>
                <v-select
                  v-model="company.company_group_id"
                  :items="companyGroups"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                  class="app-form__select"
                ></v-select>
              </v-col>

              <v-col cols="4 py-0">
                <label class="app-form__label">From</label>
                <v-menu
                  ref="menuDateFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="company.group_start_date"
                      v-bind="componentsProps.simpleTextField"
                      class="app-form__menu"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="company.group_start_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateFrom.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4 py-0">
                <label class="app-form__label">To</label>
                <v-menu
                  ref="menuDateTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="company.group_end_date"
                      v-bind="componentsProps.simpleTextField"
                      class="app-form__menu"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="company.group_end_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateTo.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">
              Description
              <v-icon
                v-if="company.description"
                class="app-form__hints ml-4"
                @click="showFullTextDialog(company.description)"
              >
                mdi-arrow-expand
              </v-icon>
            </label>
            <v-textarea v-model="company.description" solo />
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="isFullTextDialogVisible"
      :max-width="720"
      content-class="confirm-dialog"
      scrollable
      @keydown.esc="isFullTextDialogVisible = false"
      @click:outside="selectedFullText = ''"
    >
      <v-card>
        <v-card-text>
          <v-textarea v-model="selectedFullText" flat auto-grow readonly no-resize solo />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import tagsApi from '@/api/v1/library/tags';
import companiesApi from '@/api/v1/recruiter/companies';
import externalRules from '@/helpers/validators';
import AppFormSubmit from '@/components/Common/AppForm/AppFormSubmit.vue';

const componentsProps = customComponentsProps;
const company = models.company;
const companyBaseState = models.company;

export default {
  components: {
    AppFormSubmit,
  },

  props: {
    companyData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      company,
      companyBaseState,
      componentsProps,
      isFormValid: true,
      isProcessing: false,
      isFullTextDialogVisible: false,
      selectedFullText: '',
      rules: externalRules,
      companyGroups: [],
      countries: [],
    };
  },

  created() {
    this.getCompanyGroups();
    this.getCountries();
    if (this.companyData) {
      this.company = cloneDeep(this.companyData);
      this.companyBaseState = cloneDeep(this.companyData);
    } else {
      this.company = cloneDeep(models.company);
    }
  },

  beforeUpdate() {},

  methods: {
    getCompanyGroups() {
      this.isProcessing = true;

      tagsApi.companyGroups
        .index()
        .then(resp => {
          this.companyGroups = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getCountries() {
      this.isProcessing = true;

      tagsApi.countries
        .index()
        .then(resp => {
          this.countries = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formCompany.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.companyData) {
          companiesApi
            .update(this.$route.params.id, this.company)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          companiesApi
            .create(this.company)
            .then(() => {
              this.$refs.formCompany.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    showFullTextDialog(text) {
      this.selectedFullText = text;
      this.isFullTextDialogVisible = true;
    },

    isCompanyModified() {
      return new Promise(resolve => {
        resolve(!isEqual(this.company, this.companyBaseState));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-company {
  margin-top: -20px;
  max-width: 520px;
}
</style>
