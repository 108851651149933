<template>
  <div class="project-summary">
    <v-container fluid>
      <FormProject
        v-if="dataLoaded"
        ref="formProject"
        :project-data="projectData"
        @success="submitSuccess"
      />
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import projectsApi from '@/api/v1/recruiter/projects';
import FormProject from '@/components/Recruiter/Projects/FormProject.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    FormProject,
    ConfirmDialog,
  },

  data() {
    return {
      isLoading: false,
      isChanged: false,
      dataLoaded: false,
      projectData: {},
    };
  },

  created() {
    this.getProject();
  },

  beforeRouteLeave(to, from, next) {
    this.$refs.formProject.isProjectModified().then(resp => {
      if (resp) {
        this.$refs.confirmDialog
          .open({
            message: 'Are you sure you want to leave? There are unsaved changes.',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          })
          .then(confirm => {
            if (confirm) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    });
  },

  methods: {
    getProject() {
      this.dataLoaded = false;

      projectsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.dataLoaded = true;
          this.projectData = resp.data;
        })
        .catch(err => {
          this.dataLoaded = true;
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    submitSuccess() {
      this.$store.dispatch('showSuccess', ['Project updated']);
      this.getProject();
    },
  },
};
</script>

<style lang="scss" scoped>
.company-summary {
  background: inherit;
}
</style>
