export default {
  state: {
    content: [],
    color: '',
    timeout: 3000,
  },

  mutations: {
    SET_NOTIFICATION(state, payload) {
      state.content = payload.content;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
  },

  actions: {
    showError({ commit }, payload) {
      const notification = {
        content: payload,
        color: 'error',
        timeout: 3000,
      };
      commit('SET_NOTIFICATION', notification);
    },

    showSuccess({ commit }, payload) {
      const notification = {
        content: payload,
        color: 'green',
        timeout: 1000,
      };
      commit('SET_NOTIFICATION', notification);
    },
  },
};
