export default {
  state: {
    calendarDate: new Date(),
    eventsMonth: new Date().toISOString().substr(0, 7),
    userId: localStorage.getItem('Sig-Current-User') || null,
    placeId: null,
    isEventChanged: false,
  },

  mutations: {
    CHANGE_DATE(state, payload) {
      state.calendarDate = new Date(payload);
    },
    CHANGE_EVENTSS_MONTH(state, payload) {
      state.eventsMonth = payload;
    },
    SET_CALENDAR_USER(state, payload) {
      state.userId = payload;
    },
    SET_CALENDAR_PLACE(state, payload) {
      state.placeId = payload;
    },
    SET_EVENT_EDIT_STATUS(state, status) {
      state.isEventChanged = status;
    },
  },

  actions: {
    increaseDecreaseDate({ commit, state }, payload) {
      let newDate = state.calendarDate.setDate(state.calendarDate.getDate() + payload);
      commit('CHANGE_DATE', newDate);
    },
    setExactDate(context, payload) {
      context.commit('CHANGE_DATE', payload);
    },
    setEventsMonth(context, payload) {
      context.commit('CHANGE_EVENTSS_MONTH', payload);
    },
    selectUser(context, payload) {
      context.commit('SET_CALENDAR_USER', payload);
    },
    selectPlace(context, payload) {
      context.commit('SET_CALENDAR_PLACE', payload);
    },
  },

  getters: {
    getDate(state) {
      return state.calendarDate;
    },
    getEventsMonth(state) {
      return state.eventsMonth;
    },
    getUser(state) {
      return state.userId;
    },
    getPlace(state) {
      return state.placeId;
    },
  },
};
