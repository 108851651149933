<template>
  <div class="app-form form-assign-contact">
    <v-form ref="formAssignContact" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="5 text-right">
          <v-btn
            color="secondary"
            :loading="isProcessing"
            min-width="112"
            elevation="0"
            outlined
            class="app-form__submit mr-4"
            @click="addNewContact"
          >
            Create new contact
          </v-btn>
          <v-btn
            color="secondary"
            :loading="isProcessing"
            min-width="112"
            elevation="0"
            class="app-form__submit"
            @click="submitForm"
          >
            Add contact
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="py-0">
          <h2 class="app-form__title">Find contact</h2>
          <div id="contact-search" class="app-form__group app-form__group--step-autocomplete">
            <v-autocomplete
              v-model="contact.contact_id"
              :items="contacts"
              :loading="isLoading"
              :search-input.sync="search"
              :rules="[rules.required]"
              no-filter
              attach="#contact-search"
              item-value="id"
              :item-text="visibleContactName"
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              @clear="getContacts"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="pt-0">
          <h2 class="app-form__title title">Career</h2>
          <div class="app-form__group">
            <label class="app-form__label">Company</label>
            <v-select
              v-model="contact.company_id"
              :items="companies"
              :rules="[rules.required]"
              disabled
              item-value="id"
              item-text="name"
              label="Select company"
              v-bind="componentsProps.select"
              append-icon=""
            ></v-select>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Job title</label>
            <v-text-field
              v-model="contact.job_title"
              :rules="[rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Function</label>
            <v-select
              v-model="contact.job_description_id"
              :items="fcCodes"
              item-value="id"
              item-text="function"
              label="Selected function"
              v-bind="componentsProps.select"
              append-icon
              disabled
            ></v-select>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">FC Code</label>
            <v-select
              v-model="contact.job_description_id"
              :items="fcCodes"
              :rules="[rules.required]"
              item-value="id"
              item-text="code"
              label="Select code"
              v-bind="componentsProps.select"
            ></v-select>
          </div>

          <div class="app-form__group">
            <v-row>
              <v-col>
                <label class="app-form__label">From</label>
                <v-menu
                  ref="menuDateFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="contact.from"
                      :rules="[rules.required]"
                      v-bind="componentsProps.simpleTextField"
                      readonly
                      class="app-form__date"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contact.from"
                    no-title
                    scrollable
                    @input="$refs.menuDateFrom.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import careerApi from '@/api/v1/recruiter/career';
import companiesApi from '@/api/v1/recruiter/companies';
import contactsApi from '@/api/v1/recruiter/contacts';
import externalRules from '@/helpers/validators';

const componentsProps = customComponentsProps;

export default {
  props: {
    contactData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      contact: {
        contact_id: null,
        company_id: null,
        job_title: '',
        job_description_id: null,
        from: null,
      },
      componentsProps,
      isLoading: false,
      isFormValid: true,
      isProcessing: false,
      rules: externalRules,
      search: null,
      contacts: [],
      companies: [],
      fcCodes: [],
    };
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 3)
      )
        return;

      this.debouncedSearch();
    },
  },

  created() {
    this.getCompany();
    this.getFcCodes();
    this.getContacts();
  },

  methods: {
    getContacts(params) {
      this.isProcessing = true;
      contactsApi
        .index(params)
        .then(resp => {
          this.contacts = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getCompany() {
      this.isProcessing = true;
      const companyId = this.$route.params.id;
      companiesApi
        .show(companyId)
        .then(resp => {
          this.companies.push(resp.data);
          this.contact.company_id = resp.data.id;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getFcCodes() {
      this.isProcessing = true;
      jobDescriptionsApi
        .index()
        .then(resp => {
          this.fcCodes = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formAssignContact.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        careerApi
          .create(this.contact)
          .then(() => {
            this.$emit('success');
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getContacts({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),

    addNewContact() {
      this.$emit('openAddForm');
    },

    visibleContactName(item) {
      // return `${item.last_name} ${item.first_name} ${item.company_name} ${item.function}`;
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-assign-contact {
  padding: 8px;
  margin-top: -20px;
}
</style>
