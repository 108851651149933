<template>
  <div class="step-contact">
    <v-row>
      <v-col cols="5" class="py-0">
        <h3 class="pb-3 mt-2">Find contact</h3>
        <div id="step-contact-search" class="app-form__group app-form__group--step-autocomplete">
          <v-autocomplete
            v-model="selected_contact"
            :items="contacts"
            :loading="isLoading"
            :search-input.sync="search"
            item-value="id"
            :item-text="visibleContactName"
            no-filter
            attach="#step-contact-search"
            label="Search contact"
            v-bind="componentsProps.autocomplete"
            clearable
            return-object
            @change="getContact"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="secondary"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0">
        <h3 class="pb-3">or add new Contact</h3>

        <v-form ref="formContact" v-model="isFormValid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12 py-0">
              <h2 class="app-form__title">Personal information</h2>
              <div class="app-form__group">
                <v-row class="app-form__multiple-row">
                  <v-col>
                    <label class="app-form__label">
                      Last name
                      <template v-if="!isFormDisabled">
                        *
                      </template>
                    </label>
                    <v-text-field
                      v-model="contact.last_name"
                      :rules="isFormDisabled ? [] : [rules.required]"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.simpleTextField"
                    />
                  </v-col>
                  <v-col>
                    <label class="app-form__label">
                      First name
                      <template v-if="!isFormDisabled">
                        *
                      </template>
                    </label>
                    <v-text-field
                      v-model="contact.first_name"
                      :rules="isFormDisabled ? [] : [rules.required]"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.simpleTextField"
                    />
                  </v-col>
                  <v-col>
                    <label class="app-form__label">
                      Job title
                      <template v-if="!isFormDisabled">
                        *
                      </template>
                    </label>
                    <v-text-field
                      v-model="contact.job_title"
                      :rules="isFormDisabled ? [] : [rules.required]"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.simpleTextField"
                    />
                  </v-col>
                </v-row>
                <v-row class="app-form__multiple-row">
                  <v-col>
                    <label class="app-form__label">
                      City
                      <template v-if="!isFormDisabled">
                        *
                      </template>
                    </label>
                    <v-text-field
                      v-model="contact.city"
                      :rules="isFormDisabled ? [] : [rules.required]"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.simpleTextField"
                    />
                  </v-col>
                  <v-col>
                    <label class="app-form__label">
                      Nationality
                      <template v-if="!isFormDisabled">
                        *
                      </template>
                    </label>
                    <v-autocomplete
                      v-model="contact.nationality_id"
                      :items="nationalities"
                      item-value="id"
                      item-text="name"
                      :rules="isFormDisabled ? [] : [rules.required]"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.autocomplete"
                    />
                  </v-col>
                  <v-col>
                    <label class="app-form__label">RODO</label>
                    <v-select
                      v-model="contact.gdpr_status_id"
                      :items="gdprStatuses"
                      item-value="id"
                      item-text="name"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.select"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <div class="app-form__group">
                <v-row class="app-form__multiple-row">
                  <v-col
                    v-for="(phoneNumber, index) in contact.phone_numbers"
                    :key="index"
                    class="col-lg5-custom"
                  >
                    <label class="app-form__label">Phone number {{ index + 1 }}</label>
                    <v-text-field
                      v-model="contact.phone_numbers[index].phone_number"
                      v-bind="componentsProps.simpleTextField"
                      :append-icon="
                        index !== 0 || contact.phone_numbers.length > 1 ? 'mdi-close' : ''
                      "
                      :disabled="isFormDisabled"
                      @click:append="deletePhoneNumber(index)"
                    />
                  </v-col>
                  <v-col class="app-form__add-col col-lg5-custom">
                    <v-btn
                      v-if="!isFormDisabled"
                      class="app-form__add-btn"
                      color="#fff"
                      depressed
                      @click="addPhoneNumber"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div class="app-form__group">
                <v-row class="app-form__multiple-row mb-3">
                  <v-col
                    v-for="(email, index) in contact.emails"
                    :key="index"
                    class="col-lg5-custom"
                  >
                    <label class="app-form__label">Email {{ index + 1 }}</label>
                    <v-text-field
                      v-model="contact.emails[index].email"
                      v-bind="componentsProps.simpleTextField"
                      :append-icon="index !== 0 || contact.emails.length > 1 ? 'mdi-close' : ''"
                      :disabled="isFormDisabled"
                      @click:append="deleteEmail(index)"
                    />
                  </v-col>
                  <v-col class="app-form__add-col col-lg5-custom">
                    <v-btn
                      v-if="!isFormDisabled"
                      class="app-form__add-btn"
                      color="#fff"
                      depressed
                      @click="addEmail"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col cols="5" class="py-0">
              <div class="app-form__group">
                <v-row
                  v-for="(language, index) in contact.languages"
                  :key="index"
                  class="app-form__multiple-row mb-4"
                >
                  <v-col cols="5">
                    <label class="app-form__label">Language {{ index + 1 }}</label>
                    <v-select
                      v-model="contact.languages[index].language_id"
                      :items="languages"
                      :prepend-icon="contact.languages.length > 1 ? 'mdi-close' : ''"
                      item-value="id"
                      item-text="name"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.select"
                      @click:prepend="deleteLanguage(index)"
                    ></v-select>
                  </v-col>
                  <v-col cols="5">
                    <label class="app-form__label">Level</label>
                    <v-select
                      v-model="contact.languages[index].language_level_id"
                      :items="languageLevels"
                      item-value="id"
                      item-text="name"
                      :disabled="isFormDisabled"
                      v-bind="componentsProps.select"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="index + 1 === contact.languages.length"
                    cols="1"
                    class="app-form__add-col"
                  >
                    <v-btn
                      v-if="!isFormDisabled"
                      class="app-form__add-btn"
                      color="#fff"
                      depressed
                      @click="addLanguage"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row class="app-form__multiple-row">
            <v-col cols="5">
              <h2 class="app-form__title title">Career</h2>
              <div class="app-form__group">
                <label class="app-form__label">Company</label>
                <v-text-field
                  v-model="selected_company.name"
                  disabled
                  v-bind="componentsProps.simpleTextField"
                />
              </div>
              <div class="app-form__group">
                <label class="app-form__label">Job title</label>
                <v-text-field
                  v-model="contact.company_info.job_title"
                  :disabled="isFormDisabled"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                />
              </div>
              <div class="app-form__group">
                <label class="app-form__label">Function</label>
                <v-select
                  v-model="contact.company_info.job_description_id"
                  :items="fcCodes"
                  item-value="id"
                  item-text="function"
                  label="Selected function"
                  v-bind="componentsProps.select"
                  append-icon
                  disabled
                />
              </div>
              <div class="app-form__group">
                <label class="app-form__label">FC Code</label>
                <v-autocomplete
                  v-model="contact.company_info.job_description_id"
                  :items="fcCodes"
                  :rules="[rules.required]"
                  item-value="id"
                  :item-text="visibleFcCode"
                  :disabled="isFormDisabled"
                  label="Select code"
                  v-bind="componentsProps.select"
                />
              </div>

              <div class="app-form__group">
                <v-row>
                  <v-col>
                    <label class="app-form__label">From</label>
                    <v-menu
                      ref="menuDateFrom"
                      :close-on-content-click="false"
                      :disabled="isFormDisabled"
                      transition="scale-transition"
                      full-width
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="contact.company_info.from"
                          :disabled="isFormDisabled"
                          v-bind="componentsProps.simpleTextField"
                          readonly
                          class="app-form__date"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="contact.company_info.from"
                        no-title
                        scrollable
                        @input="$refs.menuDateFrom.save(true)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col v-if="contact.company_info.to">
                    <label class="app-form__label">To</label>
                    <v-menu
                      ref="menuDateTo"
                      :close-on-content-click="false"
                      :disabled="isFormDisabled"
                      transition="scale-transition"
                      full-width
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="contact.company_info.to"
                          :disabled="isFormDisabled"
                          v-bind="componentsProps.simpleTextField"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="contact.company_info.to"
                        no-title
                        scrollable
                        @input="$refs.menuDateTo.save(true)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="5" offset="1">
              <h2 class="app-form__title title">Contact hints</h2>
              <v-textarea v-model="contact.notes" :disabled="isFormDisabled" solo />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0">
              <h2 class="app-form__title">Contact status</h2>
              <div class="app-form__group">
                <v-row>
                  <v-col>
                    <label class="app-form__label">Placed by</label>
                    <v-select
                      v-model="contact.placed_by_users_ids"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      label="Select users"
                      v-bind="[componentsProps.multipleSelect]"
                      :disabled="isFormDisabled"
                      hide-details
                      class="app-form__multiselect"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <label class="app-form__label">Presented by</label>
                    <v-select
                      v-model="contact.presented_by_users_ids"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      label="Select users"
                      v-bind="[componentsProps.multipleSelect]"
                      :disabled="isFormDisabled"
                      hide-details
                      class="app-form__multiselect"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <label class="app-form__label">Business D.T.</label>
                    <v-select
                      v-model="contact.business_dt_users_ids"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      label="Select users"
                      v-bind="[componentsProps.multipleSelect]"
                      :disabled="isFormDisabled"
                      hide-details
                      class="app-form__multiselect"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <div class="app-form__group">
                <v-row>
                  <v-col>
                    <label class="app-form__label">Interviewed by</label>
                    <v-select
                      v-model="contact.interviewed_by_users_ids"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      label="Select users"
                      v-bind="[componentsProps.multipleSelect]"
                      :disabled="isFormDisabled"
                      hide-details
                      class="app-form__multiselect"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <label class="app-form__label">Source of</label>
                    <v-select
                      v-model="contact.source_of_user_id"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      v-bind="componentsProps.select"
                      :disabled="isFormDisabled"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col>
                    <label class="app-form__label">Client of</label>
                    <v-select
                      v-model="contact.client_of_user_id"
                      :items="users"
                      item-value="id"
                      item-text="login"
                      v-bind="componentsProps.select"
                      :disabled="isFormDisabled"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12 text-right mb-6">
              <v-btn
                color="secondary"
                :loading="isProcessing"
                min-width="112"
                elevation="0"
                class="app-form__submit"
                @click="submitForm"
              >
                Next step
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import utils from '@/helpers/utils';
import externalRules from '@/helpers/validators';
import tagsApi from '@/api/v1/library/tags';
import usersApi from '@/api/v1/library/users';
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import contactsApi from '@/api/v1/recruiter/contacts';

const componentsProps = customComponentsProps;

export default {
  data() {
    return {
      isFormValid: true,
      isLoading: false,
      isProcessing: false,
      rules: externalRules,
      componentsProps,
      company_id: null,
      search: null,
      contact: {},
      contacts: [],
      selected_contact: null,
      companies: [],
      users: [],
      gdprStatuses: [],
      languages: [],
      languageLevels: [],
      fcCodes: [],
      nationalities: [],
    };
  },

  computed: {
    isFormDisabled() {
      return !!this.selected_contact;
    },
    selected_company() {
      return this.$store.getters.getCandidateCompany;
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 2)
      )
        return;

      this.debouncedSearch();
    },

    selected_contact(val) {
      if (!val) this.contact = cloneDeep(models.contact);
    },

    'contact.job_title'(val) {
      if (!this.isEditForm) {
        this.contact.company_info.job_title = val;
      }
    },
  },

  created() {
    this.getContacts({});
    this.getUsers();
    this.getFcCodes();
    this.getTags('nationalities');
    this.getTags('languages');
    this.getTags('languageLevels');
    this.getTags('gdprStatuses');

    this.contact = cloneDeep(models.contact);
  },

  methods: {
    getUsers() {
      this.isProcessing = true;
      usersApi
        .index({ active: true })
        .then(resp => {
          this.users = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getFcCodes() {
      this.isProcessing = true;
      jobDescriptionsApi
        .index()
        .then(resp => {
          this.fcCodes = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getContacts(params) {
      this.isLoading = false;

      params.company_id = this.$store.state.projects.candidate.company.id;

      contactsApi
        .index(params)
        .then(resp => {
          this.contacts = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getContact(contact) {
      if (contact) {
        this.isProcessing = true;

        contactsApi
          .show(contact.id)
          .then(resp => {
            this.contact = resp.data;
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },

    getTags(type) {
      this.isProcessing = true;
      tagsApi[type]
        .index()
        .then(response => {
          this[type] = response.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formContact.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.selected_contact) {
          const candidateContact = {
            id: this.contact.id,
            name: `${this.contact.first_name} ${this.contact.last_name}`,
          };
          this.$store.dispatch('setCandidateContact', candidateContact);
          this.$emit('success');
        } else {
          const newContact = utils.trimObj(this.contact);

          newContact.company_info.company = this.$store.getters.getCandidateCompany;

          contactsApi
            .create(newContact)
            .then(resp => {
              const candidateContact = {
                id: resp.data.id,
                name: `${resp.data.first_name} ${this.contact.last_name}`,
              };
              this.$store.dispatch('setCandidateContact', candidateContact);
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    addPhoneNumber() {
      this.contact.phone_numbers.push({ phone_number: '' });
    },
    deletePhoneNumber(index) {
      this.contact.phone_numbers.splice(index, 1);
    },

    addEmail() {
      this.contact.emails.push({ email: '' });
    },
    deleteEmail(index) {
      this.contact.emails.splice(index, 1);
    },

    addLanguage() {
      this.contact.languages.push({
        language_id: '',
        language_level_id: '',
      });
    },
    deleteLanguage(index) {
      this.contact.languages.splice(index, 1);
    },

    visibleContactName(item) {
      // return `${item.last_name} ${item.first_name} ${item.company_name} ${item.function}`;
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },

    visibleFcCode(item) {
      return `${item.code} ${item.function}`;
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getContacts({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.form-contact {
  padding: 8px;
  margin-top: -20px;
}
</style>
