const models = {
  event: {
    touch_type_id: null,
    place_id: null,
    contact_id: null,
    executive_id: null,
    owner_id: null,
    project_id: null,
    from_contact: false,
    member_ids: [],
    start_datetime: '',
    end_datetime: '',
    touch_title: '',
    comment: '',
    contact: {
      company_name: '',
      phone_number: '',
    },
    executive: {
      phone_number: '',
    },
    project: {
      client_name: '',
    },
  },

  project: {
    attachments: [],
    created_at: '',
    created_by: {
      login: '',
    },
    contact: {
      company_info: {
        job_title: '',
        company: {
          name: '',
        },
      },
    },
    name: '',
    project_status_id: null,
    client_id: null,
    contact_id: null,
    contact_job: '',
    contact_company: '',
    project_type_id: null,
    priority_level_id: null,
    origin_office_id: null,
    handling_office_id: null,
    origin_user_id: null,
    handling_user_id: null,
    researching_user_id: null,
    confirmation_date: null,
    start_date: null,
    end_date: null,
    notes: '',
  },

  contact: {
    attachments: [],
    created_at: '',
    created_by: {
      login: '',
    },
    updated_by: {
      login: '',
    },
    first_name: '',
    last_name: '',
    city: '',
    nationality_id: '',
    job_title: '',
    createdDate: '',
    createdBy: null,
    lastUpdatedBy: null,
    gdpr_status_id: null,
    business_dt_users_ids: [],
    client_of_user_id: null,
    interviewed_by_users_ids: [],
    placed_by_users_ids: [],
    presented_by_users_ids: [],
    source_of_user_id: null,
    phone_numbers: [
      {
        phone_number: '',
      },
    ],
    company_info: {
      company: {
        id: '',
        name: '',
      },
      job_title: '',
      job_description_id: null,
      from: null,
      to: null,
    },
    emails: [
      {
        email: '',
      },
    ],
    languages: [
      {
        language_id: '',
        language_level_id: '',
      },
    ],
    notes: '',
  },

  company: {
    name: '',
    address: '',
    city: '',
    country: '',
    phone_number: '',
    company_group_id: null,
    group_start_date: null,
    group_end_date: null,
    description: '',
  },

  client: {
    attachments: [],
    name: '',
    address: '',
    city: '',
    phone_number: '',
    group_start_date: null,
    group_end_date: null,
    description: '',
    origin_office_id: null,
    user_id: null,
    company_group_id: null,
    contact_id: null,
  },
};

export default models;
