<template>
  <div class="app-form form-assign-executive">
    <v-form ref="formSubmit" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="5" class="py-0">
          <h2 class="app-form__title title">Assign as Client's Executive</h2>

          <div id="client-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Client *</label>
            <v-autocomplete
              v-model="client_id"
              :items="clientsList"
              :loading="isClientLoading"
              :search-input.sync="searchClient"
              :rules="[rules.required]"
              no-filter
              attach="#client-search"
              item-value="id"
              item-text="name"
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              :clearable="!executiveData"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <v-switch
            v-model="executive.hidden"
            label="Hidden on contacts list"
            color="secondary"
            class="mt-2"
          ></v-switch>

          <div class="app-form__group">
            <v-btn
              color="secondary"
              :loading="isProcessing"
              min-width="112"
              elevation="0"
              class="app-form__submit"
              @click="submitForm"
            >
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import externalRules from '@/helpers/validators';
import clientsApi from '@/api/v1/recruiter/clients';
import customComponentsProps from '@/helpers/customComponentsProps';

const componentsProps = customComponentsProps;
const rules = externalRules;

export default {
  props: {
    executiveData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      rules,
      componentsProps,
      isFormValid: false,
      isProcessing: false,
      isClientLoading: false,
      isHiddenExecutive: false,
      searchClient: null,
      clientsList: [],
      client_id: null,
      executive: {
        contact_id: this.$route.params.id,
        hidden: false,
      },
    };
  },

  watch: {
    searchClient() {
      if (
        !this.searchClient ||
        this.searchClient === null ||
        this.searchClient === '' ||
        (this.searchClient && this.searchClient.length < 2)
      )
        return;

      this.debouncedClientSearch();
    },
  },

  created() {
    if (this.executiveData.client_id) {
      this.clientsList.push({
        id: this.executiveData.client_id,
        name: this.executiveData.client_name,
      });
      this.client_id = this.executiveData.client_id;
      this.executive.hidden = this.executiveData.hidden;
    } else {
      this.getClients();
    }
  },

  methods: {
    debouncedClientSearch: debounce(function() {
      this.isClientLoading = true;

      if (this.searchClient) {
        this.getClients({ 'search_tags[autocomplete]': this.searchClient });
      } else {
        this.isClientLoading = false;
      }
    }, 500),

    getClients(params) {
      this.isProcessing = true;
      clientsApi
        .index(params)
        .then(resp => {
          this.clientsList = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isClientLoading = false;
        });
    },

    submitForm() {
      this.$refs.formSubmit.validate();

      if (this.isFormValid) {
        this.isProcessing = true;
      }

      clientsApi
        .assignExecutive(this.client_id, this.executive)
        .then(() => {
          this.$emit('success');
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-assign-executive {
  margin-top: -20px;
}
</style>
