<template>
  <div class="activity-list">
    <template v-if="activityData.length">
      <div
        v-for="activityDay in activityData"
        :key="activityDay.id"
        class="activity-list__single-day"
      >
        <v-row>
          <v-col class="font-weight-bold">{{ activityDay.date }}</v-col>
        </v-row>

        <v-row v-for="(activity, activityIndex) in activityDay.data" :key="activityIndex">
          <v-col>
            <div
              class="activity-list__single-activity"
              :class="{
                'activity-list__single-activity--no-ref':
                  activity.reference_type && activity.reference_type === 'event',
              }"
              @click="goToNotificationTarget(activity)"
              v-html="activity.message"
            ></div>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-else>
      <h4 class="mt-5 text-center cursor-default">
        There is no events in that time period
      </h4>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    activityData: {
      type: Array,
      required: true,
    },
  },

  methods: {
    goToNotificationTarget(notification) {
      if (notification.reference_type && notification.reference_type !== 'event') {
        this.$router.push({
          name: `${notification.reference_type}-summary`,
          params: { id: notification.reference_id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-list {
  &__single-day {
    margin-bottom: 24px;
  }

  &__single-activity {
    border-bottom: 1px solid $gray--light;
    padding-bottom: 24px;
    color: $gray;
    cursor: pointer;

    &--no-ref {
      cursor: default;
    }
  }
}
</style>
