<template>
  <div class="client-index-active">
    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getClients"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="clientsHeaders"
            :items="clients"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="elevation-1"
            @update:page="debounceGetClients"
            @update:items-per-page="debounceGetClients"
            @update:sort-by="getClients"
            @update:sort-desc="getClients"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item.created_at="{ value }">
              {{ value | displayDate('YYYY-MM-DD') }}
            </template>

            <template #item.name="{ item }">
              <router-link
                :to="{ name: 'client-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.name }}
              </router-link>
            </template>

            <template #item.origin_office="{ item }">
              {{ item.origin_office }}
            </template>

            <template #item.project_count="{ item }">
              <router-link
                :to="{ name: 'client-projects', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.project_count }}
              </router-link>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import eventBus from '@/eventBus';
import consts from '@/api/consts';
import clientsApi from '@/api/v1/recruiter/clients';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const clientsHeaders = consts.clients.clientsHeaders;

export default {
  components: {
    FiltersBar,
    TableFooterCurrentPage,
  },

  props: {
    statusId: {
      type: [String, Number],
      default: '0',
    },
  },

  data() {
    return {
      isLoading: false,
      clientsHeaders,
      clients: [],
      searchParams: [],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.clients.pagination;
      },
      set(val) {
        this.$store.dispatch('setClientsPagination', val);
      },
    },

    totals() {
      return this.$store.state.clients.totals;
    },

    allFilters() {
      return this.$store.state.clients.search || {};
    },

    baseSearchParams() {
      return {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        address: {
          key: 'address',
          displayName: 'Address',
          hidden: false,
        },
        city: {
          key: 'city',
          displayName: 'City',
          hidden: false,
        },
        company_group_ids: {
          key: 'company_group_ids',
          displayName: 'Company',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('company_groups'),
        },
      };
    },
  },

  watch: {
    statusId() {
      this.searchParams = JSON.parse(JSON.stringify(this.baseSearchParams));
      this.getClients();
    },
  },

  created() {
    this.searchParams = JSON.parse(JSON.stringify(this.baseSearchParams));
  },

  mounted() {
    this.getClients();
    eventBus.$on('update-clients', () => {
      this.getClients();
    });
  },

  methods: {
    debounceGetClients: debounce(function() {
      this.getClients();
    }, 500),
    getClients(filters) {
      if (filters) this.$store.dispatch('setClientFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setClientsPage', 1);

      this.isDialogVisible = false;
      this.isLoading = true;

      let params = this.$store.getters.getClientsSearchParams;
      params.client_status_id = this.statusId;

      clientsApi
        .index(params)
        .then(resp => {
          this.clients = resp.data.collection;
          this.$store.dispatch('setClientsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToClient(client) {
      this.$router.push({ name: 'client-summary', params: { id: client.id } });
    },
  },
};
</script>
