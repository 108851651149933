import axios from '@/api/axios';
import authApi from '@/api/v1/auth';

export default {
  state: {
    token: localStorage.getItem('Sig-Auth-Token') || '',
    isAdmin: localStorage.getItem('Is-Admin') === 'true',
    userId: localStorage.getItem('Sig-Current-User') || '',
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ADMIN(state, status) {
      state.isAdmin = status;
    },
    SET_USER(state, id) {
      state.userId = id;
    },
  },

  actions: {
    login({ commit }, creds) {
      return new Promise((resolve, reject) => {
        authApi
          .authenticate(creds)
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem('Sig-Auth-Token', token);
            localStorage.setItem('Is-Admin', user.library_access);
            localStorage.setItem('Sig-Current-User', user.id);
            axios.defaults.headers.common.Authorization = token;

            commit('SET_TOKEN', token);
            commit('SET_ADMIN', resp.data.user.library_access);
            commit('SET_USER', user.id);
            commit('SET_CALENDAR_USER', user.id);
            resolve();
          })
          .catch(err => {
            localStorage.removeItem('Sig-Auth-Token');
            localStorage.removeItem('Is-Admin');
            localStorage.removeItem('Sig-Current-User');
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise(resolve => {
        localStorage.removeItem('Sig-Auth-Token');
        localStorage.removeItem('Is-Admin');
        localStorage.removeItem('Sig-Current-User');
        delete axios.defaults.headers.common.Authorization;
        commit('SET_TOKEN', '');
        commit('SET_ADMIN', false);
        resolve();
      });
    },
  },

  getters: {
    getLoggedUser(state) {
      return state.userId;
    },
    isLoggedIn(state) {
      return !!state.token;
    },
  },
};
