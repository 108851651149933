import axios from '@/api/axios';

const PROJECT_PLACES_URL = '/places';
const PROJECT_PRIORITY_URL = '/priority_levels';
const PROJECT_STATUSES_URL = '/project_statuses';
const PROJECT_TYPES_URL = '/project_types';
const PROJECT_TOUCH_TYPES_URL = '/touch_types';

const GROUP_CLIENT_STATUSES_URL = '/client_statuses';
const GROUP_COMPANY_GROUPS_URL = '/company_groups';
const GROUP_GDPR_STATUSES_URL = '/gdpr_statuses';
const GROUP_INTEREST_LEVELS_URL = '/interest_levels';
const GROUP_TARGET_REMARKS_URL = '/target_remarks';

const NATIONALITY_URL = '/nationalities';

const LANGUAGES_URL = '/languages';
const LANGUAGES_OPTIONS_URL = '/language_levels';

const COUNTRIES_URL = '/countries';

const ALL_FILTERS_URL = '/filters';

export default {
  places: {
    index() {
      return axios.get(PROJECT_PLACES_URL);
    },
    show(id) {
      return axios.get(`${PROJECT_PLACES_URL}/${id}`);
    },
    create(data) {
      return axios.post(PROJECT_PLACES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${PROJECT_PLACES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${PROJECT_PLACES_URL}/${id}`);
    },
  },
  priorityLevels: {
    index() {
      return axios.get(PROJECT_PRIORITY_URL);
    },
    show(id) {
      return axios.get(`${PROJECT_PRIORITY_URL}/${id}`);
    },
    create(data) {
      return axios.post(PROJECT_PRIORITY_URL, data);
    },
    update(id, data) {
      return axios.patch(`${PROJECT_PRIORITY_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${PROJECT_PRIORITY_URL}/${id}`);
    },
  },
  projectStatuses: {
    index(params) {
      return axios.get(PROJECT_STATUSES_URL, { params: params });
    },
    show(id) {
      return axios.get(`${PROJECT_STATUSES_URL}/${id}`);
    },
    create(data) {
      return axios.post(PROJECT_STATUSES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${PROJECT_STATUSES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${PROJECT_STATUSES_URL}/${id}`);
    },
  },
  projectTypes: {
    index() {
      return axios.get(PROJECT_TYPES_URL);
    },
    show(id) {
      return axios.get(`${PROJECT_TYPES_URL}/${id}`);
    },
    create(data) {
      return axios.post(PROJECT_TYPES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${PROJECT_TYPES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${PROJECT_TYPES_URL}/${id}`);
    },
  },
  touchTypes: {
    index() {
      return axios.get(PROJECT_TOUCH_TYPES_URL);
    },
    show(id) {
      return axios.get(`${PROJECT_TOUCH_TYPES_URL}/${id}`);
    },
    create(data) {
      return axios.post(PROJECT_TOUCH_TYPES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${PROJECT_TOUCH_TYPES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${PROJECT_TOUCH_TYPES_URL}/${id}`);
    },
  },
  clientStatuses: {
    index(params) {
      return axios.get(GROUP_CLIENT_STATUSES_URL, { params: params });
    },
    show(id) {
      return axios.get(`${GROUP_CLIENT_STATUSES_URL}/${id}`);
    },
    create(data) {
      return axios.post(GROUP_CLIENT_STATUSES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${GROUP_CLIENT_STATUSES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${GROUP_CLIENT_STATUSES_URL}/${id}`);
    },
  },
  companyGroups: {
    index() {
      return axios.get(GROUP_COMPANY_GROUPS_URL);
    },
    show(id) {
      return axios.get(`${GROUP_COMPANY_GROUPS_URL}/${id}`);
    },
    create(data) {
      return axios.post(GROUP_COMPANY_GROUPS_URL, data);
    },
    update(id, data) {
      return axios.patch(`${GROUP_COMPANY_GROUPS_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${GROUP_COMPANY_GROUPS_URL}/${id}`);
    },
  },
  gdprStatuses: {
    index() {
      return axios.get(GROUP_GDPR_STATUSES_URL);
    },
    show(id) {
      return axios.get(`${GROUP_GDPR_STATUSES_URL}/${id}`);
    },
    create(data) {
      return axios.post(GROUP_GDPR_STATUSES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${GROUP_GDPR_STATUSES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${GROUP_GDPR_STATUSES_URL}/${id}`);
    },
  },
  interestLevels: {
    index() {
      return axios.get(GROUP_INTEREST_LEVELS_URL);
    },
    show(id) {
      return axios.get(`${GROUP_INTEREST_LEVELS_URL}/${id}`);
    },
    create(data) {
      return axios.post(GROUP_INTEREST_LEVELS_URL, data);
    },
    update(id, data) {
      return axios.patch(`${GROUP_INTEREST_LEVELS_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${GROUP_INTEREST_LEVELS_URL}/${id}`);
    },
  },
  targetRemarks: {
    index() {
      return axios.get(GROUP_TARGET_REMARKS_URL);
    },
    show(id) {
      return axios.get(`${GROUP_TARGET_REMARKS_URL}/${id}`);
    },
    create(data) {
      return axios.post(GROUP_TARGET_REMARKS_URL, data);
    },
    update(id, data) {
      return axios.patch(`${GROUP_TARGET_REMARKS_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${GROUP_TARGET_REMARKS_URL}/${id}`);
    },
  },
  nationalities: {
    index() {
      return axios.get(NATIONALITY_URL);
    },
    show(id) {
      return axios.get(`${NATIONALITY_URL}/${id}`);
    },
    create(data) {
      return axios.post(NATIONALITY_URL, data);
    },
    update(id, data) {
      return axios.patch(`${NATIONALITY_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${NATIONALITY_URL}/${id}`);
    },
  },
  languages: {
    index() {
      return axios.get(LANGUAGES_URL);
    },
    show(id) {
      return axios.get(`${LANGUAGES_URL}/${id}`);
    },
    create(data) {
      return axios.post(LANGUAGES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${LANGUAGES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${LANGUAGES_URL}/${id}`);
    },
  },
  languageLevels: {
    index() {
      return axios.get(LANGUAGES_OPTIONS_URL);
    },
    show(id) {
      return axios.get(`${LANGUAGES_OPTIONS_URL}/${id}`);
    },
    create(data) {
      return axios.post(LANGUAGES_OPTIONS_URL, data);
    },
    update(id, data) {
      return axios.patch(`${LANGUAGES_OPTIONS_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${LANGUAGES_OPTIONS_URL}/${id}`);
    },
  },
  countries: {
    index() {
      return axios.get(COUNTRIES_URL);
    },
    show(id) {
      return axios.get(`${COUNTRIES_URL}/${id}`);
    },
    create(data) {
      return axios.post(COUNTRIES_URL, data);
    },
    update(id, data) {
      return axios.patch(`${COUNTRIES_URL}/${id}`, data);
    },
    delete(id) {
      return axios.delete(`${COUNTRIES_URL}/${id}`);
    },
  },
  filters: {
    index() {
      return axios.get(ALL_FILTERS_URL);
    },
  },
};
