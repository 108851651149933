import axios from '@/api/axios';

const JOB_DESCRIPTIONS_URL = '/job_descriptions';

export default {
  index(params) {
    return axios.get(JOB_DESCRIPTIONS_URL, { params: params });
  },
  show(id) {
    return axios.get(`${JOB_DESCRIPTIONS_URL}/${id}`);
  },
  create(data) {
    return axios.post(JOB_DESCRIPTIONS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${JOB_DESCRIPTIONS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${JOB_DESCRIPTIONS_URL}/${id}`);
  },
};
