import axios from '@/api/axios';

const USERS_URL = '/users';

export default {
  index(params) {
    return axios.get(USERS_URL, { params: params });
  },
  show(id) {
    return axios.get(`${USERS_URL}/${id}`);
  },
  create(data) {
    return axios.post(USERS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${USERS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${USERS_URL}/${id}`);
  },
};
