<template>
  <div class="page-calendar-activity">
    <v-container fluid>
      <v-row>
        <v-col class="text-left">
          <v-select
            v-model="selectedUser"
            class="page-calendar-activity__select"
            :items="users"
            item-value="id"
            item-text="login"
            label="Select user"
            width="128px"
            flat
            clearable
            v-bind="[componentsProps.select]"
          ></v-select>
        </v-col>

        <v-col class="text-right">
          <DaterangePicker v-model="dateRange" />
        </v-col>
      </v-row>

      <ActivityList :activity-data="activityData" />
    </v-container>
  </div>
</template>

<script>
import notificationsApi from '@/api/v1/recruiter/notifications';
import usersApi from '@/api/v1/library/users';
import componentsProps from '@/helpers/customComponentsProps';
import ActivityList from '@/components/Common/ActivityList.vue';
import DaterangePicker from '@/components/Recruiter/Calendar/Daterange.vue';

export default {
  components: {
    ActivityList,
    DaterangePicker,
  },

  data() {
    return {
      componentsProps,
      dateRange: [new Date().toISOString().substr(0, 10)],
      activityData: [],
      users: [],
      selectedUser: null,
    };
  },

  watch: {
    dateRange() {
      this.getActivities();
    },
    selectedUser() {
      this.getActivities();
    },
  },

  created() {
    this.getUsers();
    this.getActivities();
  },

  methods: {
    getUsers() {
      this.isProcessing = true;

      usersApi
        .index({ active: true })
        .then(resp => {
          this.users = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getActivities() {
      this.isProcessing = true;

      const parameters = {
        date_range: this.dateRange,
        modifying_user_id: this.selectedUser,
      };

      notificationsApi
        .index(parameters)
        .then(resp => {
          this.activityData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-calendar-activity {
  max-width: 820px;
  margin: 0 auto;

  @media (min-width: 960px) {
    margin-top: 48px;
  }

  &__select {
    max-width: 240px;
    border-radius: 8px;
  }
}
</style>
