<template>
  <div class="page-jobs">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="page-jobs__header">
            <h1 class="page-jobs__title">Job description</h1>
            <v-btn color="secondary" elevation="0" @click="addJobDescription">
              Add new FC
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="page-jobs__content">
            <v-data-table
              :loading="dataLoading"
              :headers="jobDescriptionsHeaders"
              :items="jobDescriptions"
              :options.sync="pagination"
              :server-items-length="totals"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
              }"
              class="elevation-1"
              @click:row="editJobDescription"
            ></v-data-table>
          </div>
        </v-col>
      </v-row>

      <app-dialog :show="isDialogVisible" @close="closeDialog">
        <JobDescriptionForm
          :job-description-data="chosenJobDescription"
          :form-context="formContext"
          @success="getJobDescriptions"
        />
      </app-dialog>
    </v-container>
  </div>
</template>

<script>
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import consts from '@/api/consts';
import AppDialog from '@/components/Common/Dialog.vue';
import JobDescriptionForm from '@/components/Admin/Jobs/JobDescriptionForm.vue';

const jobDescriptionsHeaders = consts.jobs.jobDescriptionsHeaders;

export default {
  components: {
    AppDialog,
    JobDescriptionForm,
  },

  data() {
    return {
      dataLoading: false,
      isDialogVisible: false,
      jobDescriptionsHeaders,
      jobDescriptions: [],
      chosenJobDescription: {},
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      formContext: 'add',
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getJobDescriptions();
      },
      deep: true,
    },
  },

  created() {
    this.getJobDescriptions();
  },

  methods: {
    closeDialog() {
      this.isDialogVisible = false;
    },

    getJobDescriptions() {
      this.isDialogVisible = false;
      this.dataLoading = true;

      const params = { ...this.pagination };
      params.library = true;

      jobDescriptionsApi
        .index(params)
        .then(resp => {
          this.jobDescriptions = resp.data.collection;
          this.totals = resp.data.pagination.itemsLength;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },

    addJobDescription() {
      this.chosenJobDescription = {
        code: '',
        description: '',
      };
      this.formContext = 'add';
      this.isDialogVisible = true;
    },

    editJobDescription(jobDescription) {
      this.chosenJobDescription = jobDescription;
      this.formContext = 'edit';
      this.isDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-jobs {
  max-width: 640px;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 12px 0;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
}
</style>
