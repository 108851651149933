<template>
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex align-center">
      <span class="title mr-2">Filters:</span>
      <FiltersList
        :params="filtersListParams"
        :selected-params="allFilters.search_tags"
        @input="updateFilters"
      />
    </div>
    <div class="d-flex align-center">
      <DatePickerRangeGroup
        :start-date="allFilters.start_date"
        :end-date="allFilters.end_date"
        @input="updateFilters"
      />
      <v-divider vertical class="mx-5"></v-divider>
      <SearchBar v-model="filters.search_text" />
    </div>
  </div>
</template>

<script>
import FiltersList from './FiltersList.vue';
import SearchBar from './SearchBar.vue';
import DatePickerRangeGroup from './DatePickerRangeGroup.vue';

export default {
  name: 'Filters',
  components: {
    FiltersList,
    SearchBar,
    DatePickerRangeGroup,
  },
  props: {
    filtersListParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    allFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      filters: {
        search_tags: [],
        search_text: '',
        start_date: '',
        end_date: '',
      },
    };
  },
  watch: {
    filters: {
      handler(val) {
        this.$emit('search', val);
      },
      deep: true,
    },
  },
  created() {
    this.filters.search_text = this.allFilters.search_text || '';
  },
  methods: {
    updateFilters(label, value) {
      this.$set(this.filters, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: 400;
  color: #6c7b93;
}
</style>
