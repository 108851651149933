<template>
  <div class="calendar-navbar" elevation="2">
    <v-tabs
      height="54"
      background-color="background"
      color="primary"
      slider-color="secondary"
      slider-size="4"
      elevation="2"
    >
      <v-tab :to="{ name: 'calendar-current' }">Current</v-tab>
      <v-tab :to="{ name: 'calendar-place' }">Place</v-tab>
      <v-tab :to="{ name: 'calendar-activity' }">Activity</v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.calendar-navbar {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: $background;
  padding: 0 12px;
  z-index: 99;
  position: fixed;
  width: 100%;

  .v-tab {
    text-transform: unset;
    font-size: 16px;
  }
}
</style>
