<template>
  <div class="calendar-event">
    <div
      :class="{ 'calendar-event__checkbox--checked': eventData.completed }"
      class="calendar-event__checkbox"
      @click="setCompleted(eventData.id)"
    >
      <v-icon color="white">mdi-check</v-icon>
    </div>

    <div v-if="eventData.start_datetime" class="calendar-event__hour">
      {{ getTimeFromDatetime(eventData.start_datetime) }}
    </div>

    <div class="calendar-event__details" @click="showTooltip">
      <h4 class="calendar-event__details-title">{{ eventData.touch_type }}</h4>
      <p class="calendar-event__details-line">{{ eventData.touch_title }}</p>
      <p class="calendar-event__details-line">{{ eventData.contact_name }}</p>
      <p class="calendar-event__details-line">{{ eventData.company_name }}</p>
      <p v-if="eventData.member_names.length" class="calendar-event__details-line">
        Members:
        <span v-for="(member, index) in eventData.member_names" :key="index">
          {{ member }}
        </span>
      </p>
    </div>

    <div
      v-show="isTooltipVisible"
      ref="calendarTooltip"
      class="calendar-event__tooltip"
      :class="{ 'calendar-event__tooltip--top': displayDialogTop }"
    >
      <div class="calendar-event__tooltip-header">
        <v-icon
          class="calendar-event__tooltip-ico pt-1"
          color="primary"
          size="18"
          @click="editEvent(eventData.id)"
        >
          mdi-border-color
        </v-icon>
        <v-icon
          class="calendar-event__tooltip-ico"
          color="primary"
          size="18"
          @click="deleteEvent(eventData.id)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          class="calendar-event__tooltip-ico"
          color="primary"
          size="18"
          @click="isTooltipVisible = false"
        >
          mdi-close
        </v-icon>
      </div>

      <div class="calendar-event__tooltip-content">
        <div class="calendar-event__tooltip-line">Touch Type: {{ eventData.touch_type }}</div>
        <div class="calendar-event__tooltip-line d-flex justify-space-between">
          <span>Start: {{ getTimeFromDatetime(eventData.start_datetime) }}</span>
          <span v-if="eventData.end_datetime">
            End: {{ getTimeFromDatetime(eventData.end_datetime) }}
          </span>
        </div>

        <div v-if="eventData.place_name" class="calendar-event__tooltip-line">
          Place: {{ eventData.place_name }}
        </div>

        <div class="calendar-event__tooltip-line">Owner: {{ eventData.owner_name }}</div>

        <div v-if="eventData.member_names.length" class="calendar-event__tooltip-line">
          Members:
          <p v-for="(member, index) in eventData.member_names" :key="index" class="mb-0">
            {{ member }}
          </p>
        </div>

        <div v-if="eventData.contact_id" class="calendar-event__tooltip-line">
          Contact:&nbsp;
          <router-link
            v-if="eventData.contact_name !== 'User Unspecified'"
            :to="{ name: 'contact-summary', params: { id: eventData.contact_id } }"
          >
            {{ eventData.contact_name }}
          </router-link>
          <template v-else>{{ eventData.contact_name }}</template>
        </div>

        <div v-if="eventData.touch_title" class="calendar-event__tooltip-line">
          Touch title: {{ eventData.touch_title }}
        </div>

        <div v-if="eventData.company_id" class="calendar-event__tooltip-line">
          Company:&nbsp;
          <router-link
            v-if="eventData.company_name !== 'Unspecified'"
            :to="{ name: 'company-summary', params: { id: eventData.company_id } }"
          >
            {{ eventData.company_name }}
          </router-link>
          <template v-else>{{ eventData.company_name }}</template>
        </div>

        <div v-if="eventData.client_id" class="calendar-event__tooltip-line">
          Client:&nbsp;
          <router-link
            v-if="eventData.client_name !== 'Unspecified'"
            :to="{ name: 'client-summary', params: { id: eventData.client_id } }"
          >
            {{ eventData.client_name }}
          </router-link>
          <template v-else>{{ eventData.client_name }}</template>
        </div>

        <div v-if="eventData.project_id" class="calendar-event__tooltip-line">
          Project:&nbsp;
          <router-link
            v-if="eventData.project_name !== 'Unspecified'"
            :to="{ name: 'project-summary', params: { id: eventData.project_id } }"
          >
            {{ eventData.project_name }}
          </router-link>
          <template v-else>{{ eventData.project_name }}</template>
        </div>

        <div v-if="eventData.executive_id" class="calendar-event__tooltip-line">
          Executive:&nbsp;
          <router-link
            v-if="eventData.executive_name !== 'Unspecified'"
            :to="{ name: 'contact-summary', params: { id: eventData.executive_id } }"
          >
            {{ eventData.executive_name }}
          </router-link>
          <template v-else>{{ eventData.executive_name }}</template>
        </div>

        <div v-if="eventData.comment" class="calendar-event__tooltip-line">
          Comment: {{ getExcerpt(eventData.comment, 300) }}
        </div>
      </div>
    </div>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormEvent
        v-if="isDialogVisible"
        :event-id="eventData.id"
        :form-context="formContext"
        @success="eventUpdated"
      />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import moment from 'moment';
import eventsApi from '@/api/v1/recruiter/events';
import AppDialog from '@/components/Common/Dialog.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import FormEvent from '@/components/Recruiter/Calendar/FormEvent.vue';

export default {
  components: {
    AppDialog,
    ConfirmDialog,
    FormEvent,
  },

  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isTooltipVisible: false,
      isDialogVisible: false,
      displayDialogTop: false,
      formContext: {
        edit: true,
        calendarDate: false,
      },
    };
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    setCompleted(id) {
      eventsApi
        .complete(id, { completed: !this.eventData.completed })
        .then(() => {
          this.$store.dispatch('showSuccess', ['Event updated']);
          this.eventData.completed = !this.eventData.completed;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    elementInViewport(el) {
      var elemenetOffsetFromTop = el.offsetTop;
      var elementHeight = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        elemenetOffsetFromTop += el.offsetTop;
      }

      return elemenetOffsetFromTop + elementHeight < window.pageYOffset + window.innerHeight;
    },

    showTooltip() {
      this.isTooltipVisible = !this.isTooltipVisible;

      if (this.isTooltipVisible) {
        setTimeout(() => {
          if (
            !this.elementInViewport(this.$refs.calendarTooltip) &&
            this.$refs.calendarTooltip.offsetHeight < window.innerHeight - 178
          ) {
            this.displayDialogTop = true;
          }
          this.isListTransparent = false;
        });
      }
    },

    editEvent() {
      this.isTooltipVisible = false;
      this.isDialogVisible = true;
    },

    deleteEvent(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            this.isTooltipVisible = false;

            eventsApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Event deleted']);
                this.$emit('deleted');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    closeDialog() {
      this.isDialogVisible = false;
    },

    getTimeFromDatetime(datetime) {
      return moment(datetime).format('HH:mm');
    },

    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isTooltipVisible = false;
      }
    },

    getExcerpt(str, limit) {
      if (!str) return;
      const fullText = str;
      let shortText = fullText.substr(0, limit);
      if (fullText.length > limit) {
        shortText += '...';
      }
      return shortText;
    },

    eventUpdated() {
      this.isDialogVisible = false;
      this.$emit('updated');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-event {
  height: 118px;
  max-width: 764px;
  box-shadow: 0 6px 12px rgba(152, 152, 152, 0.16);
  border-radius: 4px;
  background-color: $white;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  position: relative;

  &__checkbox {
    width: 40px;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    background-color: $white;
    transition: all 0.3s ease;
    @media (min-width: 960px) {
      width: 50px;
    }

    &--checked {
      background-color: #4bbbc1;
    }
  }
  &__hour {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    color: $primary;
    @media (min-width: 960px) {
      width: 124px;
    }
  }
  &__details {
    background: $white;
    flex: 1;
    cursor: pointer;
  }
  &__details-title {
    font-size: 16px;
    font-weight: 400;
    color: $primary;
    margin-bottom: 6px;
    max-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__details-line {
    font-size: 12px;
    font-weight: 400;
    color: $gray;
    margin-bottom: 0;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip {
    position: absolute;
    width: 316px;
    max-height: 324px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    background-color: $white;
    right: -16px;
    top: 10px;
    z-index: 10;
    margin-bottom: 10px;

    &:before {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      position: absolute;
      content: ' ';
      left: -10px;
      top: 24px;
    }

    &--top {
      top: unset;
      bottom: 10px;

      &:before {
        top: unset;
        bottom: 24px;
      }
    }
  }
  &__tooltip-header {
    text-align: right;
    padding: 8px;
    background: $white;
    position: relative;
    z-index: 11;
    border-radius: 4px 4px 0 0;
  }
  &__tooltip-ico {
    cursor: pointer;
    padding: 0 4px;
    transition: all 0.3s ease;

    &:hover {
      color: $secondary !important;
    }
  }
  &__tooltip-content {
    padding: 0px 24px 8px;
    overflow: auto;
    max-height: 280px;
    background: $white;
    position: relative;
    z-index: 11;
  }
  &__tooltip-line {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}
</style>
