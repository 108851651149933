<template>
  <div class="d-flex align-center">
    <span class="mr-6">Current page:</span>
    <v-text-field
      v-model.number="pagination.page"
      class="current-page-input"
      type="number"
      :rules="[v => v <= Math.ceil(totals / pagination.itemsPerPage)]"
      hide-details
      dense
      min="1"
      :max="Math.ceil(totals / pagination.itemsPerPage)"
      flat
    ></v-text-field>
    <span>of {{ Math.ceil(totals / pagination.itemsPerPage) || 1 }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableFooterCurrentPage',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    totals: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.client-executives {
  background: inherit;
}
.current-page-input {
  width: 45px;
  margin: 0 10px;
}
::v-deep .v-text-field__slot {
  padding-left: 5px;
  padding-bottom: 6px;
  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
