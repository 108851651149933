<template>
  <div class="app-form form-assign-contact">
    <v-form ref="formAssignContact" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="5" class="py-0">
          <h2 class="app-form__title title">Assign Contact as Client's Executive</h2>
          <div id="contact-search" class="app-form__group app-form__group--step-autocomplete">
            <label class="app-form__label">Contact *</label>
            <v-autocomplete
              v-model="contact.contact_id"
              :items="contacts"
              :loading="isLoading"
              :search-input.sync="search"
              :rules="[rules.required]"
              no-filter
              attach="#contact-search"
              item-value="id"
              :item-text="visibleContactName"
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              @clear="getContacts"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <v-switch
            v-model="contact.hidden"
            label="Hidden on contacts list"
            color="secondary"
            class="mt-2"
          ></v-switch>

          <div class="app-form__group">
            <v-btn
              color="secondary"
              :loading="isProcessing"
              min-width="112"
              elevation="0"
              class="app-form__submit"
              @click="submitForm"
            >
              Assign
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import clientsApi from '@/api/v1/recruiter/clients';
import contactsApi from '@/api/v1/recruiter/contacts';
import externalRules from '@/helpers/validators';

const componentsProps = customComponentsProps;

export default {
  props: {
    contactData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      contact: {
        contact_id: null,
        hidden: false,
      },
      componentsProps,
      isLoading: false,
      isFormValid: true,
      isProcessing: false,
      rules: externalRules,
      search: null,
      contacts: [],
    };
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 3)
      )
        return;

      this.debouncedSearch();
    },
  },

  created() {
    this.getContacts();
  },

  methods: {
    getContacts(params) {
      this.isProcessing = true;
      contactsApi
        .index(params)
        .then(resp => {
          this.contacts = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    submitForm() {
      this.$refs.formAssignContact.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        clientsApi
          .assignExecutive(this.$route.params.id, this.contact)
          .then(() => {
            this.$emit('success');
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getContacts({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),

    visibleContactName(item) {
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-assign-contact {
  padding: 8px;
  margin-top: -20px;
}
</style>
