<template>
  <div class="companies-index">
    <header-index-page title="Companies">
      <template #action>
        <download-button class="mr-8" :download-params="reportParams">
          REPORT
        </download-button>
        <v-btn
          v-shortkey="['ctrl', 'shift', 'a']"
          color="secondary"
          elevation="0"
          min-width="112"
          @click="showDialog"
          @shortkey="showDialog"
        >
          Add new company
        </v-btn>
      </template>
    </header-index-page>

    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getCompanies"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="companiesHeaders"
            :items="companies"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="elevation-1"
            @update:page="debounceGetCompanies"
            @update:items-per-page="debounceGetCompanies"
            @update:sort-by="getCompanies"
            @update:sort-desc="getCompanies"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.updated_at | displayDate('YYYY-MM-DD') }}
                </td>
                <td>
                  <router-link
                    :to="{ name: 'company-summary', params: { id: item.id } }"
                    class="table-cell-link"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.city }}
                </td>
                <td>
                  <template v-if="item.country && item.country.name">
                    {{ item.country.name }}
                  </template>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'company-contacts', params: { id: item.id } }"
                    class="table-cell-link"
                  >
                    {{ item.contact_count || 0 }}
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormCompany v-if="isDialogVisible" @success="getCompanies" />
    </app-dialog>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import consts from '@/api/consts';
import companiesApi from '@/api/v1/recruiter/companies';

import HeaderIndexPage from '@/components/Common/HeaderIndexPage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import FormCompany from '@/components/Recruiter/Companies/FormCompany.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const companiesHeaders = consts.companies.companiesHeaders;

export default {
  components: {
    HeaderIndexPage,
    AppDialog,
    FiltersBar,
    FormCompany,
    DownloadButton,
    TableFooterCurrentPage,
  },

  data() {
    return {
      isDialogVisible: false,
      isLoading: false,
      isProcessing: false,
      companies: [],
      companiesHeaders,
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.companies.pagination;
      },
      set(val) {
        this.$store.dispatch('setCompaniesPagination', val);
      },
    },

    totals() {
      return this.$store.state.companies.totals;
    },

    allFilters() {
      return this.$store.state.companies.search || {};
    },

    reportParams() {
      const params = {
        companies: true,
        search_tags: this.$store.getters.getProjectsSearchParams.search_tags,
      };
      return params;
    },
    searchParams() {
      return {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        address: {
          key: 'address',
          displayName: 'Address',
          hidden: false,
        },
        city: {
          key: 'city',
          displayName: 'City',
          hidden: false,
        },
        country: {
          key: 'country',
          displayName: 'Country',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('countries'),
        },
        company_group_ids: {
          key: 'company_group_ids',
          displayName: 'Company group',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('company_groups'),
        },
      };
    },
  },

  created() {
    this.isDialogVisible = false;
  },

  mounted() {
    this.getCompanies();
  },

  methods: {
    showDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },

    debounceGetCompanies: debounce(function() {
      this.getCompanies();
    }, 500),

    getCompanies(filters) {
      if (filters) this.$store.dispatch('setCompanyFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setCompanyPage', 1);

      this.isDialogVisible = false;
      this.isLoading = true;

      companiesApi
        .index(this.$store.getters.getCompaniesSearchParams)
        .then(resp => {
          this.companies = resp.data.collection;
          this.$store.dispatch('setCompaniesTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToCompany(company) {
      this.$router.push({ name: 'company-summary', params: { id: company.id } });
    },
    goToCompanyContacts(company) {
      this.$router.push({ name: 'company-contacts', params: { id: company.id } });
    },
  },
};
</script>
