<template>
  <div class="datetime-picker">
    <label v-if="label" class="app-form__label">{{ label }}</label>
    <v-row class="app-form__multiple-row">
      <v-col cols="8">
        <v-menu
          ref="menuDate"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          offset-y
          class="datetime-picker__menu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="date"
              :rules="rules"
              height="36"
              dense
              solo
              readonly
              class="app-form__menu"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            @input="$refs.menuDate.save(true)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="4">
        <v-menu
          ref="menuTime"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          class="datetime-picker__menu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="time"
              :rules="rules"
              height="36"
              dense
              solo
              readonly
              class="app-form__menu"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="time"
            full-width
            format="24hr"
            value="hh:mm:ss"
            @click:minute="$refs.menuTime.save(time)"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    label: {
      required: false,
      type: String,
      default: '',
    },
    value: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: '',
    },
    initialDate: {
      required: false,
      type: Boolean,
      default: false,
    },
    initialTime: {
      required: false,
      type: String,
      default: '',
    },
    type: {
      required: false,
      type: String,
      default: 'start',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isDialogVisible: false,
      date: '',
      time: '',
    };
  },

  computed: {
    datetime() {
      return moment(this.date + 'T' + this.time).format();
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.date = moment(this.value).format('YYYY-MM-DD');
          this.time = moment(this.value).format('HH:mm');
        }
      },
      immediate: true,
    },

    date() {
      if (this.date && this.time) this.$emit('input', this.datetime);
    },

    time() {
      if (this.date && this.time) this.$emit('input', this.datetime);
    },

    initialTime() {
      if (this.initialTime)
        this.time = moment(this.initialTime)
          .add(90, 'minutes')
          .format('HH:mm');
    },
  },

  created() {
    if (this.initialDate) {
      this.date = moment(this.$store.getters.getDate).format('YYYY-MM-DD');
    } else if (!this.value) {
      this.date = moment(new Date()).format('YYYY-MM-DD');
    }

    if (this.type === 'start') {
      this.time = moment(new Date().setHours(0, 0)).format('HH:mm');
    }
  },
};
</script>

<style lang="scss" scoped>
.datetime-picker {
  display: block;

  &__menu {
    cursor: pointer;
  }
}
</style>
