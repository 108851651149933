var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts-index"},[_c('header-index-page',{attrs:{"title":"Contacts"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('download-button',{staticClass:"mr-8",attrs:{"download-params":_vm.reportParams}},[_vm._v(" REPORT ")]),_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'shift', 'a']),expression:"['ctrl', 'shift', 'a']"}],attrs:{"color":"secondary","elevation":"0","min-width":"112"},on:{"click":_vm.showDialog,"shortkey":_vm.showDialog}},[_vm._v(" Add new contact ")])]},proxy:true}])}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('FiltersBar',{attrs:{"filters-list-params":_vm.searchParams,"all-filters":_vm.allFilters},on:{"search":_vm.getContacts}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"custom-table elevation-1",attrs:{"headers":_vm.contactsHeaders,"items":_vm.contacts,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"multi-sort":false,"expanded":_vm.expanded,"show-expand":false,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.debounceGetContacts,"update:items-per-page":_vm.debounceGetContacts,"update:sort-by":_vm.getContacts,"update:sort-desc":_vm.getContacts},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm._f("displayDate")(item.updated_at,'YYYY-MM-DD'))+" ")]),_c('td',[_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])],1),_c('td',[_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])],1),_c('td',[(item.company_name)?_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'company-summary', params: { id: item.company_id } }}},[_vm._v(" "+_vm._s(item.company_name)+" ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.function))]),_c('td',[_vm._v(_vm._s(item.fc_code))]),_c('td',{staticClass:"custom-table__action-col"},[(true)?_c('v-icon',{staticClass:"custom-table__expand-toggler",on:{"click":function($event){return _vm.toggleExtend(item)}}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1)])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"custom-table__expanded-table elevation-0",attrs:{"headers":_vm.contactDetailsHeaders,"items":item.targets,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.target_remark_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsTargetRemarks,"item-value":"id","item-text":"name","label":"Select"},on:{"change":function($event){return _vm.updateTarget(item)}},model:{value:(item.target_remark_id),callback:function ($$v) {_vm.$set(item, "target_remark_id", $$v)},expression:"item.target_remark_id"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}},{key:"item.interest_level_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsLevelOfInterest,"item-value":"id","item-text":"name","label":"Select"},on:{"change":function($event){return _vm.updateTarget(item)}},model:{value:(item.interest_level_id),callback:function ($$v) {_vm.$set(item, "interest_level_id", $$v)},expression:"item.interest_level_id"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}}],null,true)})],1)]}}])})],1)],1)],1),_c('app-dialog',{attrs:{"show":_vm.isDialogVisible},on:{"close":_vm.closeDialog}},[(_vm.isDialogVisible)?_c('FormContact',{on:{"success":_vm.getContacts}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }