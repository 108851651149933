<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :placeholder="label"
        prepend-inner-icon="mdi-calendar-blank"
        solo
        flat
        dense
        hide-details
        readonly
        v-bind="attrs"
        clearable
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable @click:date="$refs.menu.save(date)">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    dateType: {
      type: String,
      default: 'date',
    },
    dateString: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    menu: false,
    date: null,
  }),
  watch: {
    date(val) {
      this.$emit('input', this.dateType, val);
    },
  },
  created() {
    this.date = this.dateString;
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  width: 150px;
  border: 1px solid #e0e0e0 !important;
}

::v-deep .v-icon {
  color: $black !important;
}

::v-deep .v-label {
  color: #6c7b93 !important;
  font-weight: 400 !important;
}
::v-deep .v-icon {
  font-size: 18px !important;
}

.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 !important;
}

::v-deep .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 0 !important;
  margin-bottom: 10px !important;
}

::v-deep .v-input__slot {
  padding: 0 5px !important;
}

::v-deep .v-text-field__slot {
  margin-bottom: 3px !important;
}
::v-deep .v-input__append-inner {
  padding: 0 !important;
}
</style>
