<template>
  <div class="projects-index">
    <header-single-page title="Projects">
      <template #action>
        <template>
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
          <v-btn
            v-shortkey="['ctrl', 'shift', 'a']"
            color="secondary"
            elevation="0"
            min-width="112"
            @click="showDialog"
            @shortkey="showDialog"
          >
            Add new project
          </v-btn>
        </template>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab
            v-for="projectStatus in projectStatuses"
            :key="projectStatus.id"
            :to="{ name: 'project-status', params: { statusId: projectStatus.id } }"
          >
            {{ projectStatus.name }} ({{ projectStatus.project_count }})
          </v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormProject v-if="isDialogVisible" @success="projectAdded" />
    </app-dialog>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import tagsApi from '@/api/v1/library/tags';
import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormProject from '@/components/Recruiter/Projects/FormProject.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    FormProject,
    DownloadButton,
  },

  data() {
    return {
      isDialogVisible: false,
      projectStatuses: [],
    };
  },

  computed: {
    dateRange() {
      return this.$store.getters.getDateRange;
    },
    reportParams() {
      const params = {
        project_status_id: this.$route.params.statusId || 0,
        projects: true,
        search_tags: this.$store.getters.getProjectsSearchParams.search_tags,
      };
      return params;
    },
  },

  created() {
    this.getTags('projectStatuses');
  },

  methods: {
    getTags(type) {
      tagsApi[type].index({ project_index: true }).then(response => {
        this[type] = response.data;
      });
    },

    projectAdded() {
      this.closeDialog();
      this.getTags('projectStatuses');
      eventBus.$emit('update-projects');
    },

    showDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },

    convertToString(val) {
      return toString(val);
    },

    changeRangeDate(dateRange) {
      this.$store.dispatch('setProjectsDateRange', dateRange);
    },
  },
};
</script>

<style lang="scss" scoped></style>
