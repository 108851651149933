<template>
  <div class="notification-snackbar">
    <v-snackbar v-model="show" :color="color" :timeout="timeout">
      <div v-for="(message, index) in content" :key="index">
        {{ message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      color: 'error',
      timeout: 3000,
      content: [],
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_NOTIFICATION') {
        this.content = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss">
.notification-snackbar {
  .v-snack__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
