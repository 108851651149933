<template>
  <div class="client-projects">
    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getProjects"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="clientProjectsHeaders"
            :items="projects"
            :items-per-page="10"
            :loading="isLoading"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="elevation-1"
            @click:row="goToProject"
            @update:page="debounceGetClientsProjects"
            @update:items-per-page="debounceGetClientsProjects"
            @update:sort-by="getProjects"
            @update:sort-desc="getProjects"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import eventBus from '@/eventBus';
import consts from '@/api/consts';
import projectsApi from '@/api/v1/recruiter/projects';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const clientProjectsHeaders = consts.clients.clientProjectsHeaders;

export default {
  components: {
    FiltersBar,
    TableFooterCurrentPage,
  },

  data() {
    return {
      isLoading: false,
      isDialogVisible: false,
      projects: [],
      clientProjectsHeaders,

      searchParams: {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        client_name: {
          key: 'client_name',
          displayName: 'Client name',
          hidden: false,
        },
        origin_user_name: {
          key: 'origin_user_name',
          displayName: 'Origin user',
          hidden: false,
        },
        researching_user_name: {
          key: 'researching_user_name',
          displayName: 'Reasearcher',
          hidden: false,
        },
      },
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.clients.projects.pagination;
      },
      set(val) {
        this.$store.dispatch('setClientProjectsPagination', val);
      },
    },

    totals() {
      return this.$store.state.clients.projects.totals;
    },

    allFilters() {
      return this.$store.state.clients.projects.search || {};
    },
  },

  mounted() {
    this.getProjects();
    eventBus.$on('update-client-projects', () => {
      this.getProjects();
    });
  },

  methods: {
    debounceGetClientsProjects: debounce(function() {
      this.getProjects();
    }, 500),
    getProjects(filters) {
      if (filters) this.$store.dispatch('setClientProjectsFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setClientsProjectsPage', 1);

      this.isLoading = true;

      const params = this.$store.getters.getClientProjectsSearchParams;
      params.client_id = this.$route.params.id;

      projectsApi
        .index(params)
        .then(resp => {
          this.dataLoaded = true;
          this.projects = resp.data.collection;
          this.$store.dispatch('setClientProjectsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.dataLoaded = true;
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToProject(project) {
      this.$router.push({ name: 'project-summary', params: { id: project.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-projects {
  background: inherit;
}
</style>
