<template>
  <div class="app-form form-event">
    <v-form ref="formEvent" v-model="isFormValid" @submit.prevent="submitForm">
      <app-form-submit :processing="isProcessing" @submit="submitForm">
        <v-switch
          v-if="!!contactId"
          :input-value="isCalendarEvent"
          label="Is calendar event"
          color="secondary"
          class="mr-7 mt-2"
          @change="event.from_contact = !event.from_contact"
        ></v-switch>
      </app-form-submit>

      <v-row class="app-form__multiple-row">
        <v-col cols="12" sm="3">
          <label class="app-form__label">Touch type *</label>
          <v-select
            v-model="event.touch_type_id"
            :items="touchTypes"
            item-value="id"
            item-text="name"
            :rules="[rules.required]"
            v-bind="componentsProps.select"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="3">
          <label class="app-form__label">
            Place<template v-if="!isFromContact || !event.from_contact">
              &nbsp;*
            </template>
          </label>
          <v-select
            v-model="event.place_id"
            :items="places"
            item-value="id"
            item-text="name"
            :rules="!isFromContact || !event.from_contact ? [rules.required] : []"
            v-bind="componentsProps.select"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="2">
          <label class="app-form__label">Owner *</label>
          <v-select
            v-model="event.owner_id"
            :items="users"
            item-value="id"
            item-text="login"
            :rules="[rules.required]"
            v-bind="componentsProps.select"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <label class="app-form__label">Members</label>
          <v-select
            v-model="event.member_ids"
            :items="users"
            item-value="id"
            item-text="login"
            label="Select members"
            v-bind="[componentsProps.multipleSelect]"
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="app-form__multiple-row mt-5 mt-sm-0">
        <v-col cols="12" sm="3">
          <datetime-picker
            v-model="event.start_datetime"
            label="Start date and time *"
            :rules="[rules.required]"
            :initial-date="formContext.calendarDate ? true : false"
            type="start"
          ></datetime-picker>
        </v-col>

        <v-col cols="12" sm="3">
          <datetime-picker
            v-model="event.end_datetime"
            label="End date and time"
            :initial-date="formContext.calendarDate ? true : false"
            :initial-time="formContext.calendarDate ? event.start_datetime : ''"
            type="end"
          ></datetime-picker>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="app-form__group">
            <label class="app-form__label">Touch title *</label>
            <v-text-field
              v-model="event.touch_title"
              :rules="[rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="!isFromContact" cols="12" sm="6">
          <h2 class="app-form__title app-form__title--low">Contact</h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col cols="12" sm="8">
                <div id="contact-search" class="app-form__group app-form__group--autocomplete">
                  <label class="app-form__label">Find contact</label>
                  <v-autocomplete
                    v-model="event.contact"
                    :items="contacts"
                    :loading="isContactLoading"
                    :search-input.sync="searchContact"
                    item-value="id"
                    :item-text="visibleContactName"
                    no-filter
                    attach="#contact-search"
                    hide-no-data
                    placeholder="Start typing to Search"
                    v-bind="componentsProps.autocomplete"
                    return-object
                    clearable
                    @input="selectContact"
                    @click:clear="clearContact"
                  >
                    <template v-slot:progress>
                      <v-progress-linear
                        absolute
                        color="secondary"
                        height="4"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>

              <v-col cols="12" sm="4">
                <label class="app-form__label app-form__label--mobile">Phone number</label>
                <v-text-field
                  :value="event.contact ? event.contact.phone_number : ''"
                  v-bind="componentsProps.simpleTextField"
                  disabled
                />
              </v-col>
            </v-row>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Company</label>
            <v-text-field
              :value="event.contact ? event.contact.company_name : ''"
              v-bind="componentsProps.simpleTextField"
              disabled
            />
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <h2 class="app-form__title app-form__title--low">Client</h2>

          <div id="client-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Client</label>
            <v-autocomplete
              v-model="event.client"
              :items="clients"
              :loading="isClientLoading"
              :search-input.sync="searchClient"
              item-value="id"
              :item-text="visibleClientName"
              no-filter
              attach="#client-search"
              hide-no-data
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              return-object
              clearable
              @input="selectClient"
              @click:clear="clearClient"
              @change="clearClientsDependencies"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <div id="project-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Project</label>
            <v-autocomplete
              v-model="event.project"
              :disabled="!event.client"
              :items="projects"
              :loading="isProjectLoading"
              :search-input.sync="searchProject"
              item-value="id"
              :item-text="visibleProjectName"
              no-filter
              attach="#project-search"
              hide-no-data
              :placeholder="event.client ? 'Start typing to Search' : ''"
              v-bind="componentsProps.autocomplete"
              return-object
              clearable
              @input="selectProject"
              @click:clear="clearProject"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col cols="12" sm="8">
                <div id="executive-search" class="app-form__group app-form__group--autocomplete">
                  <label class="app-form__label">Client's executive</label>
                  <v-autocomplete
                    v-model="event.executive"
                    :disabled="!event.client"
                    :items="executives"
                    :loading="isExecutiveLoading"
                    :search-input.sync="searchExecutive"
                    item-value="id"
                    :item-text="visibleExecutivetName"
                    no-filter
                    attach="#executive-search"
                    hide-no-data
                    :placeholder="event.client ? 'Start typing to Search' : ''"
                    v-bind="componentsProps.autocomplete"
                    return-object
                    clearable
                    @input="selectExecutive"
                    @click:clear="clearExecutive"
                  >
                    <template v-slot:progress>
                      <v-progress-linear
                        absolute
                        color="secondary"
                        height="4"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>

              <v-col cols="12" sm="4">
                <label class="app-form__label app-form__label--mobile">Phone number</label>
                <v-text-field
                  :value="event.executive ? event.executive.phone_number : ''"
                  v-bind="componentsProps.simpleTextField"
                  disabled
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="app-form__group">
            <label class="app-form__label">Comment</label>
            <v-textarea v-model="event.comment" solo />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import externalRules from '@/helpers/validators';
import usersApi from '@/api/v1/library/users';
import tagsApi from '@/api/v1/library/tags';
import contactsApi from '@/api/v1/recruiter/contacts';
import clientsApi from '@/api/v1/recruiter/clients';
import projectsApi from '@/api/v1/recruiter/projects';
import eventsApi from '@/api/v1/recruiter/events';
import DatetimePicker from '@/components/Common/DatetimePicker.vue';
import AppFormSubmit from '@/components/Common/AppForm/AppFormSubmit.vue';

const componentsProps = customComponentsProps;
const rules = externalRules;

export default {
  components: {
    DatetimePicker,
    AppFormSubmit,
  },

  props: {
    eventId: {
      type: Number,
      required: false,
      default: () => {},
    },
    contactId: {
      type: [String, Number],
      required: false,
      default: () => {},
    },
    formContext: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      componentsProps,
      rules,
      isFormValid: true,
      isLoading: false,
      isContactLoading: false,
      isClientLoading: false,
      isExecutiveLoading: false,
      isProjectLoading: false,
      isProcessing: false,
      users: [],
      places: [],
      touchTypes: [],
      contacts: [],
      clients: [],
      executives: [],
      projects: [],
      searchContact: null,
      searchClient: null,
      searchExecutive: null,
      searchProject: null,
      event: cloneDeep(models.event),
      eventBaseState: {},
      selectedContact: {},
      selectedClient: {},
      selectedExecutive: {},
      selectedProject: {},
    };
  },

  computed: {
    isFromContact() {
      return this.$route.name === 'contact-contact-history';
    },
    isCalendarEvent() {
      return !this.event.from_contact;
    },
  },

  watch: {
    searchContact() {
      if (
        !this.searchContact ||
        this.searchContact === null ||
        this.searchContact === '' ||
        (this.searchContact && this.searchContact.length < 2)
      )
        return;

      this.debouncedContactSearch();
    },

    searchClient() {
      if (
        !this.searchClient ||
        this.searchClient === null ||
        this.searchClient === '' ||
        (this.searchClient && this.searchClient.length < 2)
      )
        return;

      this.debouncedClientSearch();
    },

    searchExecutive() {
      if (
        !this.searchExecutive ||
        this.searchExecutive === null ||
        this.searchExecutive === '' ||
        (this.searchExecutive && this.searchExecutive.length < 2)
      )
        return;

      this.debouncedExecutiveSearch();
    },

    searchProject() {
      if (
        !this.searchProject ||
        this.searchProject === null ||
        this.searchProject === '' ||
        (this.searchProject && this.searchProject.length < 2)
      )
        return;

      this.debouncedProjectSearch();
    },
  },

  created() {
    this.getUsers();
    this.getTags('touchTypes');
    this.getTags('places');

    if (this.eventId) {
      this.getEvent(this.eventId);
    } else {
      this.getContacts();
      this.getClients();
    }

    if (this.isFromContact) this.event.from_contact = false;

    if (this.contactId) this.event.contact.id = this.contactId;

    if (this.formContext) {
      this.event.owner_id = this.formContext.owner_id;
      this.event.place_id = this.formContext.place_id;
    }
  },

  methods: {
    getEvent(id) {
      eventsApi
        .show(id)
        .then(resp => {
          if (resp.data.client) {
            this.clients.push(resp.data.client);
          } else {
            this.getClients();
          }
          if (resp.data.contact) this.contacts.push(resp.data.contact);
          if (resp.data.project) this.projects.push(resp.data.project);
          if (resp.data.executive) this.executives.push(resp.data.executive);

          if (!resp.data.owner_active)
            this.users.push({ id: resp.data.owner_id, login: resp.data.owner_name });

          this.event = resp.data;
          this.eventBaseState = cloneDeep(resp.data);
          if (this.event.project === null) this.event.project = undefined;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getTags(type) {
      tagsApi[type].index().then(response => {
        this[type] = response.data;
      });
    },

    getContacts(params) {
      this.isProcessing = true;
      contactsApi
        .index(params)
        .then(resp => {
          this.contacts = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isContactLoading = false;
        });
    },

    getClients(params) {
      this.isProcessing = true;
      clientsApi
        .index(params)
        .then(resp => {
          this.clients = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isClientLoading = false;
        });
    },

    getExecutives(params) {
      this.isProcessing = true;
      let queryParams = {
        ...params,
      };
      if (this.event.client.id) {
        queryParams.client_id = this.event.client.id;
        queryParams.show_hidden = true;
      }

      contactsApi
        .index(queryParams)
        .then(resp => {
          this.executives = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isExecutiveLoading = false;
        });
    },

    getProjects(params) {
      this.isProcessing = true;
      let queryParams = {
        ...params,
      };

      if (this.event.client.id) queryParams.client_id = this.event.client.id;

      projectsApi
        .index(queryParams)
        .then(resp => {
          this.projects = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isProjectLoading = false;
        });
    },

    getContact(id) {
      contactsApi
        .show(id)
        .then(resp => {
          this.selectedContact = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getClient(id) {
      clientsApi
        .show(id)
        .then(resp => {
          this.selectedClient = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getExecutive(id) {
      contactsApi
        .show(id)
        .then(resp => {
          this.selectedExecutive = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getProject(id) {
      projectsApi
        .show(id)
        .then(resp => {
          this.selectedProject = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getUsers() {
      this.isProcessing = true;
      usersApi
        .index({ active: true })
        .then(resp => {
          this.users = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formEvent.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        const newEvent = cloneDeep(this.event);

        newEvent.client_id =
          this.event.client && this.event.client.id ? this.event.client.id : null;

        newEvent.contact_id =
          this.event.contact && this.event.contact.id ? this.event.contact.id : null;

        newEvent.executive_id =
          this.event.executive && this.event.executive.id ? this.event.executive.id : null;

        newEvent.project_id =
          this.event.project && this.event.project.id ? this.event.project.id : null;

        if (this.eventId) {
          eventsApi
            .update(this.eventId, newEvent)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          eventsApi
            .create(newEvent)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    debouncedContactSearch: debounce(function() {
      this.isContactLoading = true;

      if (this.searchContact) {
        this.getContacts({ 'search_tags[autocomplete]': this.searchContact });
      } else {
        this.isContactLoading = false;
      }
    }, 500),

    debouncedClientSearch: debounce(function() {
      this.isClientLoading = true;

      if (this.searchClient) {
        this.getClients({ 'search_tags[autocomplete]': this.searchClient });
      } else {
        this.isClientLoading = false;
      }
    }, 500),

    debouncedExecutiveSearch: debounce(function() {
      this.isExecutiveLoading = true;

      if (this.searchExecutive) {
        this.getExecutives({ 'search_tags[autocomplete]': this.searchExecutive });
      } else {
        this.isExecutiveLoading = false;
      }
    }, 500),

    debouncedProjectSearch: debounce(function() {
      this.isProjectLoading = true;

      if (this.searchProject) {
        this.getProjects({ 'search_tags[autocomplete]': this.searchProject });
      } else {
        this.isProjectLoading = false;
      }
    }, 500),

    selectContact(val) {
      if (val) this.event.contact = val;
    },

    selectClient(val) {
      if (val) {
        this.event.client = val;
      }
    },

    selectExecutive(val) {
      if (val) this.event.executive = val;
    },

    selectProject(val) {
      if (val) this.event.project = val;
    },

    clearContact() {
      this.event.contact_id = '';
      this.event.contact = {};
      this.selectedContact = {};
      this.getContacts();
    },

    clearClient() {
      this.event.client_id = '';
      this.selectedClient = {};
      this.event.client = {};
      this.getClients();
      this.clearClientsDependencies();
    },

    clearProject() {
      this.event.project_id = '';
      this.event.project = {};
      this.selectedProject = {};
      this.getProjects();
    },

    clearExecutive() {
      this.event.executive_id = '';
      this.event.executive = {};
      this.selectedExecutive = {};
      this.getExecutives();
    },

    clearClientsDependencies() {
      this.clearExecutive();
      this.clearProject();
    },

    visibleContactName(item) {
      if (item.last_name === 'Unspecified') return item.last_name;
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },

    visibleExecutivetName(item) {
      if (item.last_name === 'Unspecified') return item.last_name;
      return `${item.last_name} ${item.first_name}`;
    },

    visibleClientName(item) {
      return `${item.name}`;
    },

    visibleProjectName(item) {
      return `${item.name}`;
    },

    isEventModified() {
      return new Promise(resolve => {
        resolve(!isEqual(this.event, this.eventBaseState));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-event {
  margin-top: -60px;
  @media (min-width: 960px) {
    margin-top: -20px;
  }
}
.datetime-picker::v-deep .app-form__label {
  margin-bottom: 16px !important;
}
</style>
