<template>
  <div class="page-project">
    <v-container fluid>
      <v-row class="page-project__row">
        <v-col>
          <TagGroup
            title="Project status"
            :tags-data="projectStatuses"
            tags-type="projectStatuses"
            @refresh="getTags('projectStatuses')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Project type"
            :tags-data="projectTypes"
            tags-type="projectTypes"
            @refresh="getTags('projectTypes')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Priority levels"
            :tags-data="priorityLevels"
            tags-type="priorityLevels"
            @refresh="getTags('priorityLevels')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Touch type"
            :tags-data="touchTypes"
            tags-type="touchTypes"
            @refresh="getTags('touchTypes')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Place"
            :tags-data="places"
            tags-type="places"
            @refresh="getTags('places')"
            @delete="deleteTag"
          />
        </v-col>
      </v-row>
    </v-container>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import tagsApi from '@/api/v1/library/tags';

import TagGroup from '@/components/Admin/TagGroup.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    TagGroup,
    ConfirmDialog,
  },
  data() {
    return {
      projectStatuses: [],
      projectTypes: [],
      priorityLevels: [],
      touchTypes: [],
      places: [],
    };
  },

  created() {
    this.getTags('projectStatuses');
    this.getTags('projectTypes');
    this.getTags('priorityLevels');
    this.getTags('touchTypes');
    this.getTags('places');
  },

  methods: {
    getTags(type) {
      tagsApi[type].index().then(response => {
        this[type] = response.data;
      });
    },

    deleteTag(payload) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            tagsApi[payload.type].delete(payload.id).then(() => {
              this.getTags(payload.type);
            });
          }
        });
    },
  },
};
</script>
