import axios from '@/api/axios';

const CAREER_URL = '/career_entries';

export default {
  index(params) {
    return axios.get(CAREER_URL, { params: params });
  },
  show(id) {
    return axios.get(`${CAREER_URL}/${id}`);
  },
  create(data) {
    return axios.post(CAREER_URL, data);
  },
  update(id, data) {
    return axios.patch(`${CAREER_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${CAREER_URL}/${id}`);
  },
};
