<template>
  <div class="auth-page">
    <v-main>
      <v-container fluid>
        <v-row justify="center">
          <v-col>
            <v-form ref="formLogin" class="auth-page__form" @submit.prevent="login">
              <div class="text-center">
                <img src="@/assets/logo-start.png" alt="logo" />
              </div>
              <h2 class="auth-page__subtitle display-1">Log in</h2>

              <v-card elevation="0">
                <v-card-text>
                  <v-text-field
                    ref="loginInput"
                    v-model.trim="user.login"
                    :rules="[rules.required]"
                    label="Login"
                    name="login"
                    type="text"
                    height="38"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    v-model.trim="user.password"
                    :rules="[rules.required]"
                    label="Password"
                    name="password"
                    type="password"
                    height="38"
                  ></v-text-field>
                  <v-checkbox
                    v-model="user.remember"
                    color="secondary"
                    label="Remember me"
                  ></v-checkbox>
                </v-card-text>
                <v-card-actions class="mx-auto px-12">
                  <v-btn
                    :loading="isProcessing"
                    color="secondary"
                    block
                    rounded
                    elevation="0"
                    type="submit"
                    @keyup.enter="login"
                  >
                    Log in
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import externalRules from '@/helpers/validators';

export default {
  data() {
    return {
      isProcessing: false,
      user: {
        login: '',
        password: '',
        remember: false,
      },
      rules: externalRules,
    };
  },

  created() {
    if (this.$refs.loginInput) this.$nextTick(this.$refs.loginInput.focus);
  },

  methods: {
    login() {
      this.isProcessing = true;

      this.$store
        .dispatch('login', this.user)
        .then(() => {
          if (this.$store.state.user.isAdmin) {
            this.$router.push({ name: 'library-project' });
          } else {
            this.$router.push({ name: 'calendar-index' });
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-page {
  background: #fff;
  min-height: 100vh;

  &__form {
    max-width: 400px;
    margin: 0 auto;
  }

  &__title {
    color: $primary;
    text-align: center;
    font-weight: 700 !important;
  }

  &__subtitle {
    color: $gray--light;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
  }
}
.v-input .v-label {
  padding-left: 8px;
}
</style>
