<template>
  <div class="contact-projects">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="contactProjectsHeaders"
            :items="contactProjects"
            item-key="id"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            disable-filtering
            class="custom-table elevation-1"
            @update:page="debounceGetProjects"
            @update:items-per-page="debounceGetProjects"
            @update:sort-by="getContactProjects"
            @update:sort-desc="getContactProjects"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item.created_at="{ item }">
              {{ getDateFromDatetime(item.created_at) }}
            </template>

            <template #item.id="{ item }">
              <router-link
                :to="{ name: 'project-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.id }}
              </router-link>
            </template>

            <template #item.name="{ item }">
              <router-link
                :to="{ name: 'project-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.name }}
              </router-link>
            </template>

            <template #item.target_remark_id="{ item }">
              <div class="custom-table__select">
                <v-select
                  v-model="item.target_remark_id"
                  :items="optionsTargetRemarks"
                  item-value="id"
                  item-text="name"
                  label="Select"
                  v-bind="componentsProps.simpleSelect"
                  @change="updateCandidate(item)"
                ></v-select>
              </div>
            </template>

            <template #item.interest_level_id="{ item }">
              <div class="custom-table__select">
                <v-select
                  v-model="item.interest_level_id"
                  :items="optionsLevelOfInterest"
                  item-value="id"
                  item-text="name"
                  label="Select"
                  v-bind="componentsProps.simpleSelect"
                  @change="updateCandidate(item)"
                ></v-select>
              </div>
            </template>

            <template #item.action="{ item }">
              <v-btn color="primary" icon @click="deleteCandidate(item.target_id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment';
import customComponentsProps from '@/helpers/customComponentsProps';
import tagsApi from '@/api/v1/library/tags';
import projectsApi from '@/api/v1/recruiter/projects';
import projectTargetsApi from '@/api/v1/recruiter/projectTargets';
import consts from '@/api/consts';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const componentsProps = customComponentsProps;
const contactProjectsHeaders = consts.contacts.contactProjectsHeaders;

export default {
  components: {
    ConfirmDialog,
    TableFooterCurrentPage,
  },

  data() {
    return {
      componentsProps,
      isLoading: false,
      formContext: {
        calendarDate: false,
      },
      contactProjects: [],
      contactProjectsHeaders,
      optionsTargetRemarks: [],
      optionsLevelOfInterest: [],
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
    };
  },

  created() {
    this.getTargetRemarks();
    this.getLevelsOfInterest();
    this.getContactProjects();
  },

  methods: {
    debounceGetProjects: debounce(function() {
      this.getContactProjects();
    }, 500),
    getTargetRemarks() {
      tagsApi.targetRemarks
        .index()
        .then(resp => {
          this.optionsTargetRemarks = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getLevelsOfInterest() {
      tagsApi.interestLevels
        .index()
        .then(resp => {
          this.optionsLevelOfInterest = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getContactProjects() {
      this.isLoading = true;

      const params = { ...this.pagination };
      params.contact_id = this.$route.params.id;

      projectsApi
        .index(params)
        .then(resp => {
          this.contactProjects = resp.data.collection;
          this.totals = resp.data.pagination.itemsLength;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getDateFromDatetime(datetime) {
      return moment(datetime).format('YYYY-MM-DD');
    },

    goToProject(project) {
      this.$router.push({ name: 'project-summary', params: { id: project.id } });
    },

    updateCandidate(item) {
      this.isLoading = true;

      const data = {
        target_id: item.target_id,
        target_remark_id: item.target_remark_id,
        interest_level_id: item.interest_level_id,
        project_id: item.id,
        company_id: item.company_id,
        contact_id: item.contact_id,
      };

      projectTargetsApi
        .update(item.target_id, data)
        .then(() => {
          this.getContactProjects();
          this.$store.dispatch('showSuccess', ['Candidate updated']);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    deleteCandidate(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            projectTargetsApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Deleted']);
                this.getContactProjects();
              })
              .catch(err => {
                this.$store.dispatch('showSuccess', err.response.data.errors);
              });
          }
        });
    },
  },
};
</script>
