<template>
  <div class="company-summary">
    <v-container fluid>
      <FormCompany
        v-if="dataLoaded"
        ref="formCompany"
        :company-data="companyData"
        @success="submitSuccess"
      />
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import companiesApi from '@/api/v1/recruiter/companies';
import FormCompany from '@/components/Recruiter/Companies/FormCompany.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    FormCompany,
    ConfirmDialog,
  },

  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      companyData: {},
    };
  },

  created() {
    this.getCompany();
  },

  beforeRouteLeave(to, from, next) {
    this.$refs.formCompany.isCompanyModified().then(resp => {
      if (resp) {
        this.$refs.confirmDialog
          .open({
            message: 'Are you sure you want to leave? There are unsaved changes.',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          })
          .then(confirm => {
            if (confirm) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    });
  },

  methods: {
    getCompany() {
      this.dataLoaded = false;

      companiesApi
        .show(this.$route.params.id)
        .then(resp => {
          this.companyData = resp.data;
          this.$emit('update-title', this.companyData.name);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoaded = true;
        });
    },

    submitSuccess() {
      this.$store.dispatch('showSuccess', ['Company updated']);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-summary {
  background: inherit;
}
</style>
