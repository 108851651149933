var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-contacs"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.contactsHistoryHeaders,"items":_vm.contactsHistory,"loading":_vm.isLoading,"disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.start_datetime)?[_vm._v(" "+_vm._s(_vm.getDateFromDatetime(item.start_datetime))+" ")]:_vm._e()]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [(item.start_datetime)?[_vm._v(" "+_vm._s(_vm.getTimeFromDatetime(item.start_datetime))+" ")]:_vm._e()]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [(item.project_name)?_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'project-summary', params: { id: item.project_id } }}},[_vm._v(" "+_vm._s(item.project_name)+" ")]):_vm._e()]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [(item.client_name)?_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'client-summary', params: { id: item.client_id } }}},[_vm._v(" "+_vm._s(item.client_name)+" ")]):_vm._e()]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2 cursor-pointer caption",staticStyle:{"min-width":"300px"},on:{"click":function($event){return _vm.showFullTextDialog(item.comment)}}},[_vm._v(" "+_vm._s(_vm.getExcerpt(item.comment, 150))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editEvent(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.deleteEvent(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1),_c('app-dialog',{attrs:{"show":_vm.isEventFormVisible},on:{"close":_vm.closeDialog}},[(_vm.isEventFormVisible)?_c('FormEvent',{attrs:{"contact-id":_vm.$route.params.id,"event-id":_vm.selectedEvent,"form-context":_vm.formContext},on:{"success":_vm.eventEdited}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":720,"content-class":"confirm-dialog","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeFullTextDialog($event)},"click:outside":function($event){_vm.selectedFullText = ''}},model:{value:(_vm.isFullTextDialogVisible),callback:function ($$v) {_vm.isFullTextDialogVisible=$$v},expression:"isFullTextDialogVisible"}},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"flat":"","auto-grow":"","readonly":"","no-resize":"","solo":""},model:{value:(_vm.selectedFullText),callback:function ($$v) {_vm.selectedFullText=$$v},expression:"selectedFullText"}})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }