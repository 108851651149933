<template>
  <div class="company-contacts-index">
    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getContacts"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="companyContactsHeaders"
            :items="companyContacts"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="elevation-1"
            @update:page="debounceGetCompaniesContacts"
            @update:items-per-page="debounceGetCompaniesContacts"
            @update:sort-by="getContacts"
            @update:sort-desc="getContacts"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item.updated_at="{ value }">
              {{ value | displayDate('YYYY/MM/DD') }}
            </template>

            <template #item.first_name="{ item }">
              <router-link
                :to="{ name: 'contact-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.first_name }}
              </router-link>
            </template>

            <template #item.last_name="{ item }">
              <router-link
                :to="{ name: 'contact-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.last_name }}
              </router-link>
            </template>

            <template #item.action="{ item }">
              <v-btn color="primary" icon @click="deleteCareerEntry(item.career_entry_id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import eventBus from '@/eventBus';
import careerApi from '@/api/v1/recruiter/career';
import contactsApi from '@/api/v1/recruiter/contacts';
import consts from '@/api/consts';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const companyContactsHeaders = consts.contacts.contactsHeaders;

export default {
  components: {
    FiltersBar,
    ConfirmDialog,
    TableFooterCurrentPage,
  },

  data() {
    return {
      isDialogVisible: false,
      isLoading: false,
      companyContacts: [],
      companyContactsHeaders,

      searchParams: {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        job_title: {
          key: 'job_title',
          displayName: 'Job title',
          hidden: false,
        },
        fc_code: {
          key: 'fc_code',
          displayName: 'FC code',
          hidden: false,
        },
      },
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.companies.contacts.pagination;
      },
      set(val) {
        this.$store.dispatch('setCompanyContactsPagination', val);
      },
    },

    totals() {
      return this.$store.state.companies.contacts.totals;
    },

    allFilters() {
      return this.$store.state.companies.contacts.search || {};
    },
  },

  mounted() {
    this.getContacts();
    eventBus.$on('update-contacts', () => {
      this.getContacts();
    });
  },

  methods: {
    debounceGetCompaniesContacts: debounce(function() {
      this.getContacts();
    }, 500),
    showDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },

    getContacts(filters) {
      if (filters) this.$store.dispatch('setCompanyContactsFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setCompanyContactsPage', 1);

      this.isDialogVisible = false;
      this.isLoading = true;

      const params = this.$store.getters.getCompanyContactsSearchParams;
      params.company_id = this.$route.params.id;

      contactsApi
        .index(params)
        .then(resp => {
          this.companyContacts = resp.data.collection;
          this.$store.dispatch('setCompanyContactsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToComapnyContact(contact) {
      this.$router.push({ name: 'contact-summary', params: { id: contact.id } });
    },

    deleteCareerEntry(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            careerApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Deleted']);
                this.getContacts();
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },
  },
};
</script>
