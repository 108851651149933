var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-executives"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.clientExecutivesHeaders,"items":_vm.executives,"options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"multi-sort":false,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          }},on:{"update:options":function($event){_vm.pagination=$event},"update:page":_vm.debounceGetClientsExecutives,"update:items-per-page":_vm.debounceGetClientsExecutives,"update:sort-by":_vm.getExecutives,"update:sort-desc":_vm.getExecutives},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item.updated_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("displayDate")(value,'YYYY/MM/DD'))+" ")]}},{key:"item.last_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.first_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.unassignExecutive(item.id)}}},[_c('v-icon',[_vm._v("mdi-briefcase-remove-outline")])],1)]}}])})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }