<template>
  <div class="step-submit">
    <v-form ref="formSubmit" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="6" offset="3" class="py-0">
          <h3 class="pb-3 mt-2">Submit candidate</h3>

          <div class="app-form__group">
            <label class="app-form__label">Company</label>
            <v-text-field
              v-model="candidate.company.name"
              disabled
              v-bind="componentsProps.simpleTextField"
            />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Contact</label>
            <v-text-field
              v-model="candidate.contact.name"
              disabled
              v-bind="componentsProps.simpleTextField"
            />
          </div>

          <div
            v-if="isContactView"
            id="project-search"
            class="app-form__group app-form__group--autocomplete"
          >
            <label class="app-form__label">Project *</label>
            <v-autocomplete
              :value="candidate.project_id"
              :items="projects"
              :loading="isLoading"
              :search-input.sync="search"
              :rules="[rules.required]"
              no-filter
              attach="#project-search"
              item-value="id"
              :item-text="visibleProjectName"
              label="Search project"
              v-bind="componentsProps.autocomplete"
              clearable
              @change="changeProject"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Target remark *</label>
            <v-select
              :value="candidate.target_remark_id"
              :items="targetRemarks"
              :rules="[rules.required]"
              item-value="id"
              item-text="name"
              v-bind="componentsProps.select"
              @change="changeTargetRemark"
            ></v-select>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Interest level *</label>
            <v-select
              :value="candidate.interest_level_id"
              :items="interestLevels"
              :rules="[rules.required]"
              item-value="id"
              item-text="name"
              v-bind="componentsProps.select"
              @change="changeInterestLevel"
            ></v-select>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12 text-right">
          <v-btn
            color="secondary"
            :loading="isProcessing"
            min-width="112"
            elevation="0"
            class="app-form__submit"
            @click="submitForm"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import externalRules from '@/helpers/validators';
import tagsApi from '@/api/v1/library/tags';
import candidatesApi from '@/api/v1/recruiter/projectTargets';
import projectsApi from '@/api/v1/recruiter/projects';

const componentsProps = customComponentsProps;
const rules = externalRules;

export default {
  data() {
    return {
      componentsProps,
      rules,
      isFormValid: false,
      isProcessing: false,
      isLoading: false,
      search: null,
      projects: [],
      selected_project_id: null,
      interestLevels: [],
      targetRemarks: [],
      interest_level_id: null,
      target_remark_id: null,
    };
  },

  computed: {
    candidate() {
      return this.$store.getters.getCandidate;
    },

    isContactView() {
      return this.$route.name === 'contact-summary' || this.$route.name === 'contact-projects';
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 3)
      )
        return;

      this.debouncedSearch();
    },
  },

  created() {
    this.getTags('interestLevels');
    this.getTags('targetRemarks');

    if (this.isContactView) this.getProjects();
  },

  methods: {
    getTags(type) {
      tagsApi[type].index().then(response => {
        this[type] = response.data;
      });
    },

    getProjects(params) {
      this.isProcessing = true;
      projectsApi
        .index(params)
        .then(resp => {
          this.projects = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    changeProject(val) {
      this.$store.dispatch('setCandidateProjectId', val);
    },

    changeInterestLevel(val) {
      this.$store.dispatch('setInterestLevel', val);
    },

    changeTargetRemark(val) {
      this.$store.dispatch('setTargetRemark', val);
    },

    submitForm() {
      this.$refs.formSubmit.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        const candidate = {
          project_id: this.candidate.project_id,
          company_id: this.candidate.company.id,
          contact_id: this.candidate.contact.id,
          target_remark_id: this.candidate.target_remark_id,
          interest_level_id: this.candidate.interest_level_id,
        };

        candidatesApi
          .create(candidate)
          .then(() => {
            this.$emit('success');
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },

    visibleProjectName(item) {
      return `${item.name} ${item.client_name}`;
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getProjects({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped></style>
