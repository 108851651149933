import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v1/`,
  headers: {
    common: {
      Authorization: localStorage.getItem('Sig-Auth-Token'),
      'Access-Control-Allow-Origin': '*',
    },
  },
});

export default instance;
