import qs from 'qs';
import axios from '@/api/axios';

const PROJECTS_URL = '/projects';

export default {
  index(params) {
    return axios.get(PROJECTS_URL, {
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
  show(id) {
    return axios.get(`${PROJECTS_URL}/${id}`);
  },
  create(data) {
    return axios.post(PROJECTS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${PROJECTS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${PROJECTS_URL}/${id}`);
  },
  getAttachment(projectId, attachmentId) {
    return axios.get(`${PROJECTS_URL}/${projectId}/attachments/${attachmentId}`);
  },
  createAttachment(projectId, formData) {
    return axios.post(`${PROJECTS_URL}/${projectId}/attachments`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  deleteAttachment(projectId, attachmentId) {
    return axios.delete(`${PROJECTS_URL}/${projectId}/attachments/${attachmentId}`);
  },
  updateAttachment(projectId, attachmentId, data) {
    return axios.patch(`${PROJECTS_URL}/${projectId}/attachments/${attachmentId}`, data);
  },
};
