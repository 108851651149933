<template>
  <div class="page-notifications">
    <v-container fluid>
      <v-row class="mb-4">
        <v-col>
          <v-btn
            color="secondary"
            :loading="isProcessing"
            min-width="112"
            elevation="0"
            @click="setAsReaded"
          >
            Mark all as read
          </v-btn>
        </v-col>
        <v-col class="text-md-right">
          <DaterangePicker v-model="dateRange" />
        </v-col>
      </v-row>

      <ActivityList :activity-data="notificationsData" />
    </v-container>
  </div>
</template>

<script>
import notificationsApi from '@/api/v1/recruiter/notifications';
import DaterangePicker from '@/components/Recruiter/Calendar/Daterange.vue';
import ActivityList from '@/components/Common/ActivityList.vue';

export default {
  components: {
    DaterangePicker,
    ActivityList,
  },

  data() {
    return {
      isProcessing: false,
      notificationsData: [],
      dateRange: [new Date().toISOString().substr(0, 10)],
    };
  },

  computed: {
    currentUserId() {
      return parseInt(this.$store.getters.getUser);
    },
  },

  watch: {
    dateRange() {
      this.getNotifications();
    },
  },

  created() {
    this.getNotifications();
  },

  methods: {
    getNotifications() {
      this.isProcessing = true;

      const params = {
        user_id: this.currentUserId,
        date_range: this.dateRange,
      };

      notificationsApi
        .index(params)
        .then(resp => {
          this.notificationsData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    setAsReaded() {
      this.isProcessing = true;

      notificationsApi
        .clearAll()
        .then(() => {
          this.getNotifications();
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    goToNotificationTarget(notification) {
      if (notification.reference_type) {
        this.$router.push({
          name: `${notification.reference_type}-summary`,
          params: { id: notification.reference_id },
        });
        this.isNotificationDropdownVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-notifications {
  max-width: 820px;
  margin: 0 auto;

  @media (min-width: 960px) {
    margin-top: 24px;
  }
}

.notifications-list {
  &__element {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid gray;
  }

  &__message {
    font-weight: 600;
    cursor: pointer;

    &--viewed {
      font-weight: 400;
    }
  }

  &__data {
    font-size: 12px;
  }
}
</style>
