import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';
import Login from './views/Login.vue';
import Index from './views/Recruiter/Index.vue';
import Admin from './views/Admin/Index.vue';
import AdminProject from './views/Admin/Project/Index.vue';
import AdminGroups from './views/Admin/Groups/Index.vue';
import AdminJobs from './views/Admin/Jobs/Index.vue';
import AdminEmployees from './views/Admin/Employees/Index.vue';
import AdminInternationalization from './views/Admin/Internationalization/Index.vue';

import EmptyRouterView from './views/EmptyRouterView.vue';

import Calendar from './views/Recruiter/Calendar/Index.vue';
import CalendarCurrent from './views/Recruiter/Calendar/Current.vue';
import CalendarConferenceRoom from './views/Recruiter/Calendar/ConferenceRoom.vue';
import CalendarActivitiy from './views/Recruiter/Calendar/Activity.vue';

import Projects from './views/Recruiter/Projects/Index.vue';
import ProjectsData from './views/Recruiter/Projects/IndexData.vue';
import ProjectShow from './views/Recruiter/Projects/Show.vue';
import ProjectSummary from './views/Recruiter/Projects/Summary.vue';
import ProjectActivity from './views/Recruiter/Projects/Activity.vue';
import ProjectCandidates from './views/Recruiter/Projects/Candidates.vue';

import Contacts from './views/Recruiter/Contacts/Index.vue';
import ContactShow from './views/Recruiter/Contacts/Show.vue';
import ContactSummary from './views/Recruiter/Contacts/Summary.vue';
import ContactProjects from './views/Recruiter/Contacts/Projects.vue';
import ContactCareerHistory from './views/Recruiter/Contacts/ContactHistoryCareer.vue';
import ContactContactHistory from './views/Recruiter/Contacts/ContactHistoryContacts.vue';

import Companies from './views/Recruiter/Companies/Index.vue';
import CompanyShow from './views/Recruiter/Companies/Show.vue';
import CompanySummary from './views/Recruiter/Companies/Summary.vue';
import CompanyContacts from './views/Recruiter/Companies/Contacts.vue';

import Clients from './views/Recruiter/Clients/Index.vue';
import ClientData from './views/Recruiter/Clients/IndexData.vue';
import ClientShow from './views/Recruiter/Clients/Show.vue';
import ClientSummary from './views/Recruiter/Clients/Summary.vue';
import ClientProjects from './views/Recruiter/Clients/Projects.vue';
import ClientExecutives from './views/Recruiter/Clients/Executives.vue';

import Notifications from './views/Recruiter/Notifications.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Index,
      redirect: { name: 'calendar-current' },
      children: [
        {
          path: '/calendar',
          component: EmptyRouterView,
          redirect: { name: 'current' },
          children: [
            {
              path: '',
              name: 'calendar-index',
              redirect: 'current',
              component: Calendar,
              children: [
                {
                  path: 'current',
                  name: 'calendar-current',
                  component: CalendarCurrent,
                  meta: {
                    title: 'Current',
                  },
                },
                {
                  path: 'place',
                  name: 'calendar-place',
                  component: CalendarConferenceRoom,
                  meta: {
                    title: 'Place',
                  },
                },
                {
                  path: 'activity',
                  name: 'calendar-activity',
                  component: CalendarActivitiy,
                  meta: {
                    title: 'Activity',
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/projects',
          component: EmptyRouterView,
          children: [
            {
              path: '',
              name: 'projects-index',
              redirect: 'status/0',
              component: Projects,
              children: [
                {
                  path: 'status/:statusId',
                  name: 'project-status',
                  component: ProjectsData,
                  props: true,
                },
              ],
            },
            {
              path: ':id',
              name: 'project-show',
              component: ProjectShow,
              children: [
                {
                  path: 'summary',
                  name: 'project-summary',
                  component: ProjectSummary,
                },
                {
                  path: 'candidates',
                  name: 'project-candidates',
                  component: ProjectCandidates,
                },
                {
                  path: 'activity',
                  name: 'project-activity',
                  component: ProjectActivity,
                },
              ],
            },
          ],
        },
        {
          path: '/contacts',
          component: EmptyRouterView,
          children: [
            {
              path: '',
              name: 'contacts-index',
              component: Contacts,
            },
            {
              path: ':id',
              name: 'contact-show',
              component: ContactShow,
              children: [
                {
                  path: 'summary',
                  name: 'contact-summary',
                  component: ContactSummary,
                },
                {
                  path: 'projects',
                  name: 'contact-projects',
                  component: ContactProjects,
                },
                {
                  path: 'career-history',
                  name: 'contact-career-history',
                  component: ContactCareerHistory,
                },
                {
                  path: 'contact-history',
                  name: 'contact-contact-history',
                  component: ContactContactHistory,
                },
              ],
            },
          ],
        },
        {
          path: '/companies',
          component: EmptyRouterView,
          children: [
            {
              path: '',
              name: 'companies-index',
              component: Companies,
            },
            {
              path: ':id',
              name: 'company-show',
              component: CompanyShow,
              children: [
                {
                  path: 'summary',
                  name: 'company-summary',
                  component: CompanySummary,
                },
                {
                  path: 'contacts',
                  name: 'company-contacts',
                  component: CompanyContacts,
                },
              ],
            },
          ],
        },
        {
          path: '/clients',
          component: EmptyRouterView,
          children: [
            {
              path: '',
              name: 'clients-index',
              redirect: 'status/0',
              component: Clients,
              children: [
                {
                  path: 'status/:statusId',
                  name: 'client-status',
                  component: ClientData,
                  props: true,
                },
              ],
            },
            {
              path: ':id',
              name: 'client-show',
              component: ClientShow,
              children: [
                {
                  path: 'summary',
                  name: 'client-summary',
                  component: ClientSummary,
                },
                {
                  path: 'projects',
                  name: 'client-projects',
                  component: ClientProjects,
                },
                {
                  path: 'executives',
                  name: 'client-executives',
                  component: ClientExecutives,
                },
              ],
            },
          ],
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: Notifications,
        },
      ],
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      redirect: { name: 'library-project' },
      meta: { admin: true },
      children: [
        {
          path: '/admin/project',
          name: 'library-project',
          component: AdminProject,
          meta: { admin: true },
        },
        {
          path: '/admin/groups',
          name: 'library-groups',
          component: AdminGroups,
          meta: { admin: true },
        },
        {
          path: '/admin/job-description',
          name: 'library-job-description',
          component: AdminJobs,
          meta: { admin: true },
        },
        {
          path: '/admin/employees',
          name: 'library-employees',
          component: AdminEmployees,
          meta: { admin: true },
        },
        {
          path: '/admin/internationalization',
          name: 'library-i18n',
          component: AdminInternationalization,
          meta: { admin: true },
        },
      ],
    },
    {
      path: '*',
      name: 'not-found',
      redirect: { name: 'calendar-current' },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { public: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.public)) {
    if (!store.getters.isLoggedIn) {
      next('/login');
    } else {
      if (store.state.user.isAdmin) {
        next();
      } else {
        if (to.matched.some(record => record.meta.admin)) {
          next('/calendar');
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});

export default router;
