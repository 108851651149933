<template>
  <div class="project-show">
    <header-single-page :title="displayTitle">
      <template #action>
        <template v-if="isProjectSummary">
          <v-btn
            v-shortkey="['ctrl', 'shift', 'd']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="deleteProject"
            @click="deleteProject"
          >
            Delete project
          </v-btn>
        </template>

        <template v-else>
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
          <v-btn
            v-if="isProjectContacts"
            v-shortkey="['ctrl', 'shift', 'a']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="showCandidateWizzard"
            @click="showCandidateWizzard"
          >
            Add candidate
          </v-btn>
        </template>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab :to="{ name: 'project-summary' }">Summary</v-tab>
          <v-tab :to="{ name: 'project-candidates' }">Candidates</v-tab>
          <v-tab :to="{ name: 'project-activity' }">Activity</v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <app-dialog :show="isCandidateWizzardVisible" @close="closeCandidateWizard">
      <WizardAddCandidate v-if="isCandidateWizzardVisible" @success="candidateAdded" />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import projectsApi from '@/api/v1/recruiter/projects';

import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import WizardAddCandidate from '@/components/Common/WizardAddCandidate/Index.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    WizardAddCandidate,
    ConfirmDialog,
    DownloadButton,
  },

  data() {
    return {
      isLoading: false,
      isCandidateWizzardVisible: false,
      isAddDialogVisible: false,
      projectData: {},
    };
  },

  computed: {
    isProjectSummary() {
      return this.$route.name === 'project-summary';
    },

    isProjectContacts() {
      return this.$route.name === 'project-candidates';
    },

    displayTitle() {
      return this.projectData.name ? this.projectData.name : '';
    },

    reportParams() {
      const params = {
        project_id: this.$route.params.id,
        search_tags: this.$store.getters.getTargetsSearchParams.search_tags,
      };
      if (this.$route.name === 'project-activity') {
        params.events = true;
        params.date = this.$store.getters.getActivityDateRange;
      }
      return params;
    },
  },

  created() {
    this.getProject();
  },

  methods: {
    getProject() {
      projectsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.projectData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    deleteProject() {
      const currentProjectId = this.$route.params.id;

      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            projectsApi
              .delete(currentProjectId)
              .then(() => {
                this.$router.push('/projects');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    showCandidateWizzard() {
      this.$store.dispatch('setCandidateProjectId', this.$route.params.id);
      this.isCandidateWizzardVisible = true;
    },

    closeCandidateWizard() {
      this.isCandidateWizzardVisible = false;
      this.$store.dispatch('clearCandidate');
    },

    candidateAdded() {
      this.closeCandidateWizard();
      eventBus.$emit('update-project-contacts');
    },
  },
};
</script>

<style lang="scss" scoped>
.project-show {
  background: inherit;
}
</style>
