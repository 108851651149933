<template>
  <div class="client-show">
    <header-single-page :title="displayTitle">
      <template #action>
        <template v-if="isClientSummary">
          <v-btn
            v-shortkey="['ctrl', 'shift', 'd']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="deleteClient"
            @click="deleteClient"
          >
            Delete
          </v-btn>
        </template>

        <template v-else-if="isClientExecutives">
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
          <v-btn color="secondary" elevation="0" min-width="112" @click="showAssignDialog">
            Add Executive
          </v-btn>
        </template>

        <template v-else>
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
          <v-btn
            v-shortkey="['ctrl', 'shift', 'a']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="showDialog"
            @click="showDialog"
          >
            Add new project
          </v-btn>
        </template>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab :to="{ name: 'client-summary' }">Summary</v-tab>
          <v-tab :to="{ name: 'client-projects' }">Projects</v-tab>
          <v-tab :to="{ name: 'client-executives' }">Executives</v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormProject v-if="isDialogVisible" :client-data="clientData" @success="projectCreated" />
    </app-dialog>

    <app-dialog :show="isAssignExecutiveDialogVisible" @close="closeDialog">
      <FormAssignExecutive v-if="isAssignExecutiveDialogVisible" @success="contactAssigned" />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import clientsApi from '@/api/v1/recruiter/clients';
import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormProject from '@/components/Recruiter/Projects/FormProject.vue';
import FormAssignExecutive from '@/components/Recruiter/Clients/FormAssignExecutive.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    FormProject,
    FormAssignExecutive,
    ConfirmDialog,
    DownloadButton,
  },

  data() {
    return {
      isLoading: false,
      isDialogVisible: false,
      isAssignExecutiveDialogVisible: false,
      clientData: {},
    };
  },

  computed: {
    isClientSummary() {
      return this.$route.name === 'client-summary';
    },
    isClientExecutives() {
      return this.$route.name === 'client-executives';
    },

    displayTitle() {
      return this.clientData.name ? this.clientData.name : '';
    },

    reportParams() {
      let params = {};
      if (this.isClientExecutives) {
        params = {
          client_id: this.$route.params.id,
          executives: true,
        };
      } else {
        params = {
          client_id: this.$route.params.id,
          search_tags: this.$store.getters.getClientProjectsSearchParams.search_tags,
        };
      }

      return params;
    },
  },

  created() {
    this.getClient();
  },

  methods: {
    getClient() {
      clientsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.dataLoaded = true;
          this.clientData = resp.data;
        })
        .catch(err => {
          this.dataLoaded = true;
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    deleteClient() {
      const currentClientId = this.$router.history.current.params.id;

      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            clientsApi
              .delete(currentClientId)
              .then(() => {
                this.$router.push('/clients');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    showDialog() {
      this.isDialogVisible = true;
    },

    closeDialog() {
      this.isDialogVisible = false;
      this.isAssignExecutiveDialogVisible = false;
    },

    showAssignDialog() {
      this.isAssignExecutiveDialogVisible = true;
    },

    contactAssigned() {
      this.closeDialog();
      eventBus.$emit('update-client-executives');
    },

    projectCreated() {
      this.closeDialog();
      eventBus.$emit('update-client-projects');
    },
  },
};
</script>

<style lang="scss" scoped>
.client-show {
  background: inherit;
}
</style>
