<template>
  <v-toolbar-items>
    <v-tabs
      height="54"
      background-color="primary"
      color="white"
      slider-color="secondary"
      slider-size="4"
    >
      <v-tab
        v-shortkey="['ctrl', 'alt', '1']"
        :to="{ name: 'calendar-index' }"
        @shortkey="goTo('calendar-index')"
      >
        CALENDAR
      </v-tab>
      <v-tab
        v-shortkey="['ctrl', 'alt', '2']"
        :to="{ name: 'projects-index' }"
        @shortkey="goTo('projects-index')"
      >
        PROJECTS
      </v-tab>
      <v-tab
        v-shortkey="['ctrl', 'alt', '3']"
        :to="{ name: 'contacts-index' }"
        @shortkey="goTo('contacts-index')"
      >
        CONTACTS
      </v-tab>
      <v-tab
        v-shortkey="['ctrl', 'alt', '4']"
        :to="{ name: 'companies-index' }"
        @shortkey="goTo('companies-index')"
      >
        COMPANIES
      </v-tab>
      <v-tab
        v-shortkey="['ctrl', 'alt', '5']"
        :to="{ name: 'clients-index' }"
        @shortkey="goTo('clients-index')"
      >
        CLIENTS
      </v-tab>
      <v-tab
        v-if="$store.state.user.isAdmin"
        v-shortkey="['ctrl', 'alt', '6']"
        :to="{ name: 'admin' }"
        @shortkey="goTo('clients-index')"
      >
        ADMIN PANEL
      </v-tab>
    </v-tabs>
  </v-toolbar-items>
</template>

<script>
export default {
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 18px;
  color: $white !important;
}
</style>
