<template>
  <div class="tag-group">
    <h2 class="tag-group__title">
      {{ title }}
    </h2>
    <div class="tag-group__tags">
      <v-chip
        v-for="tag in tagsData"
        :key="tag.id"
        class="mr-6 mb-4 py-4"
        label
        close
        close-icon="mdi-close"
        @click:close="removeTag(tag.id)"
      >
        {{ tag.name }}
      </v-chip>

      <v-btn class="tag-group__add-btn" color="#fff" depressed @click="openDialog">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>

      <app-dialog ref="tagsType" :show="isDialogVisible" eager @close="closeDialog">
        <div class="add-employee mw-450">
          <v-row>
            <v-col cols="12">
              <h2 clas="title add-employee__title">{{ title }}</h2>
            </v-col>
          </v-row>

          <v-form ref="tagForm" v-model="isFormValid" @submit.prevent="addTag">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="tagNameField"
                  v-model="name"
                  :rules="[rules.required]"
                  height="36"
                  solo
                  autofocus
                />
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col cols="12 text-right">
                <v-btn
                  v-shortkey="['ctrl', 'shift', 's']"
                  :loading="isProcessing"
                  :disabled="!isFormValid"
                  color="secondary"
                  rounded
                  elevation="0"
                  @shortkey="addTag"
                  @click="addTag"
                  @keyup.enter="addTag"
                >
                  <span
                    >Add <template v-if="title">{{ title }}</template></span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </app-dialog>
    </div>
  </div>
</template>

<script>
import tagsApi from '@/api/v1/library/tags';
import externalRules from '@/helpers/validators';

import AppDialog from '@/components/Common/Dialog.vue';

export default {
  components: {
    AppDialog,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    tagsData: {
      type: Array,
      required: true,
    },
    tagsType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFormValid: true,
      rules: externalRules,
      isProcessing: false,
      isDialogVisible: false,
      name: '',
    };
  },

  methods: {
    openDialog() {
      this.isDialogVisible = true;
      if (this.$refs.tagForm) this.$refs.tagForm.reset();
      if (this.$refs.tagNameField) this.$nextTick(this.$refs.tagNameField.focus);
    },

    closeDialog() {
      this.isDialogVisible = false;
    },

    addTag() {
      if (this.isFormValid) {
        this.isProcessing = true;
        const newTag = { name: this.name };

        tagsApi[this.tagsType]
          .create(newTag)
          .then(() => {
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },

    removeTag(id) {
      this.$emit('delete', { type: this.tagsType, id: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-group {
  margin-bottom: 8px;

  &__title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 21px;
    margin-bottom: 16px;
  }
  &__add-btn {
    color: $primary;
    border-radius: 4px;
    margin-bottom: 16px;
    margin-left: 8px;
  }
}
.v-chip {
  font-size: 16px !important;
  height: 36px !important;
  background: $white !important;
  color: $primary !important;
  &:hover {
    background: #fafafa !important;
  }
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 36px !important;
}
</style>
