var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-contacts"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('FiltersBar',{attrs:{"filters-list-params":_vm.searchParams,"all-filters":_vm.allFilters},on:{"search":_vm.getCandidates}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"custom-table elevation-1",attrs:{"headers":_vm.targetHeaders,"items":_vm.targets,"item-key":"id","items-per-page":10,"options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"multi-sort":false,"expanded":_vm.expanded,"show-expand":false,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.debounceGetCandidates,"update:items-per-page":_vm.debounceGetCandidates,"update:sort-by":_vm.getCandidates,"update:sort-desc":_vm.getCandidates},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item.company_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'company-summary', params: { id: item.company_id } }}},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"item.last_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.contact_id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.first_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.contact_id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])]}},{key:"item.function",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.function))])]}},{key:"item.target_remark_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsTargetRemarks,"item-value":"id","item-text":"name","return-object":"","label":"Select"},on:{"change":function($event){return _vm.updateCandidate(item)}},model:{value:(item.target_remark),callback:function ($$v) {_vm.$set(item, "target_remark", $$v)},expression:"item.target_remark"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}},{key:"item.interest_level_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"custom-table__select"},[_c('v-select',_vm._b({attrs:{"items":_vm.optionsLevelOfInterest,"item-value":"id","item-text":"name","return-object":"","label":"Select"},on:{"change":function($event){return _vm.updateCandidate(item)}},model:{value:(item.interest_level),callback:function ($$v) {_vm.$set(item, "interest_level", $$v)},expression:"item.interest_level"}},'v-select',_vm.componentsProps.simpleSelect,false))],1)]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.deleteCandidate(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-icon',{staticClass:"custom-table__expand-toggler",on:{"click":function($event){return _vm.toggleExtend(item)}}},[_vm._v(" mdi-chevron-down ")])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"custom-table__expanded-table elevation-0",attrs:{"headers":_vm.targetDetailsHeaders,"items":item.activities,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.start_datetime",fn:function(ref){
          var value = ref.value;
return [(value)?[_vm._v(" "+_vm._s(_vm._f("displayDate")(value,'YYYY-MM-DD'))+" ")]:_vm._e()]}},{key:"item.comment",fn:function(ref){
          var item = ref.item;
return [(item.comment)?_c('div',{staticClass:"cursor-default py-2",on:{"click":function($event){return _vm.showFullTextDialog(item.comment)}}},[_vm._v(" "+_vm._s(_vm.getExcerpt(item.comment, 150))+" ")]):_vm._e()]}}],null,true)})],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":720,"content-class":"confirm-dialog","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeFullTextDialog($event)},"click:outside":function($event){_vm.selectedFullText = ''}},model:{value:(_vm.isFullTextDialogVisible),callback:function ($$v) {_vm.isFullTextDialogVisible=$$v},expression:"isFullTextDialogVisible"}},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"flat":"","auto-grow":"","readonly":"","no-resize":"","solo":""},model:{value:(_vm.selectedFullText),callback:function ($$v) {_vm.selectedFullText=$$v},expression:"selectedFullText"}})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }