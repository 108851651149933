<template>
  <div class="contact-summary">
    <v-container fluid>
      <FormContact
        v-if="dataLoaded"
        ref="formContact"
        :contact-data="contactData"
        @addCareer="openCareerForm"
        @success="submitSuccess"
      />
    </v-container>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormCareerEntry @success="submitSuccess" />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import contactsApi from '@/api/v1/recruiter/contacts';
import AppDialog from '@/components/Common/Dialog.vue';
import FormContact from '@/components/Recruiter/Contacts/FormContact.vue';
import FormCareerEntry from '@/components/Recruiter/Contacts/FormCareerEntry.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    AppDialog,
    FormContact,
    FormCareerEntry,
    ConfirmDialog,
  },

  data() {
    return {
      isDialogVisible: false,
      isLoading: false,
      dataLoaded: false,
      contactData: {},
    };
  },

  created() {
    this.getContact();
  },

  beforeRouteLeave(to, from, next) {
    this.$refs.formContact.isContactModified().then(resp => {
      if (resp) {
        this.$refs.confirmDialog
          .open({
            message: 'Are you sure you want to leave? There are unsaved changes.',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          })
          .then(confirm => {
            if (confirm) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    });
  },

  methods: {
    getContact() {
      this.dataLoaded = false;

      contactsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.contactData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoaded = true;
        });
    },

    submitSuccess() {
      this.isDialogVisible = false;
      this.$store.dispatch('showSuccess', ['Contact updated']);
      this.getContact();
    },

    openCareerForm() {
      this.$refs.formContact.isContactModified().then(resp => {
        if (resp) {
          this.$refs.confirmDialog
            .open({
              message: 'Are you sure you want to leave? There are unsaved changes.',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            })
            .then(confirm => {
              if (confirm) this.isDialogVisible = true;
            });
        } else {
          this.isDialogVisible = true;
        }
      });
    },

    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-summary {
  background: inherit;
}
</style>
