<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    content-class="confirm-dialog"
    @keydown.esc="cancel"
  >
    <v-card class="confirm-dialog__content">
      <v-toolbar height="48" class="confirm-dialog__toolbar elevation-0">
        <v-btn icon @click="cancel">
          <v-icon size="24" color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-show="!!options.message" class="confirm-dialog__message">
        {{ options.message }}
      </v-card-text>

      <v-card-actions class="confirm-dialog__actions">
        <v-btn
          class="confirm-dialog__btn confirm-dialog__btn--cancel elevation-0"
          width="82"
          @click.native="cancel"
        >
          {{ options.cancelButtonText }}
        </v-btn>
        <v-btn
          class="confirm-dialog__btn confirm-dialog__btn--cofirm elevation-0"
          width="82"
          :color="options.color"
          @click.native="agree"
        >
          {{ options.confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,

      options: {
        color: 'secondary',
        width: 480,
        zIndex: 99999,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Back',
        message: 'Are you sure?',
      },
    };
  },

  methods: {
    open(options) {
      this.dialog = true;

      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.confirm-dialog {
  border-radius: 0px;

  &__toolbar .v-toolbar__content {
    justify-content: flex-end !important;
  }
  &__content {
    border-radius: 0px;
    text-align: center;
  }
  &__message {
    font-size: 16px;
  }
  &__actions {
    justify-content: center;
    padding-bottom: 32px;
  }
  &__btn {
    min-width: 84px;
    height: 32px;
    border-radius: 4px !important;
    &--cancel {
      background: transparent !important;
      color: $primary !important;
    }
  }
}
</style>
