<template>
  <div class="page-employees">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="page-employees__header">
            <h1 class="page-employees__title">Employees list</h1>
            <v-btn color="secondary" elevation="0" @click="addEmployee">
              Add new employee
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="page-employees__content">
            <v-data-table
              :loading="dataLoading"
              :headers="employeesHeaders"
              :items="employees"
              :options.sync="pagination"
              :server-items-length="totals"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
              }"
              class="elevation-1"
              @click:row="editEmployee"
            >
              <template #item.library_access="{ value }">
                <v-icon v-if="value">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </template>

              <template #item.active="{ value }">
                <v-icon v-if="value">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>

      <app-dialog :show="isDialogVisible" @close="closeDialog">
        <EmployeeForm
          :employee-data="chosenEmployee"
          :form-context="formContext"
          @success="getEmployees"
        />
      </app-dialog>
    </v-container>
  </div>
</template>

<script>
import usersLibraryApi from '@/api/v1/library/users';
import consts from '@/api/consts';
import AppDialog from '@/components/Common/Dialog.vue';
import EmployeeForm from '@/components/Admin/Employees/EmployeeForm.vue';

const employeesHeaders = consts.employees.employeesHeaders;

export default {
  components: {
    AppDialog,
    EmployeeForm,
  },

  data() {
    return {
      dataLoading: false,
      isDialogVisible: false,
      employeesHeaders,
      employees: [],
      chosenEmployee: {},
      totals: 0,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      formContext: 'add',
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getEmployees();
      },
      deep: true,
    },
  },

  created() {
    this.getEmployees();
  },

  methods: {
    closeDialog() {
      this.isDialogVisible = false;
    },
    getEmployees() {
      this.isDialogVisible = false;
      this.dataLoading = true;

      const params = { ...this.pagination };

      usersLibraryApi
        .index(params)
        .then(resp => {
          this.employees = resp.data.collection;
          this.totals = resp.data.pagination.itemsLength;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    addEmployee() {
      this.chosenEmployee = {
        last_name: '',
        firstName: '',
        login: '',
        password: '',
        library_access: false,
      };
      this.formContext = 'add';
      this.isDialogVisible = true;
    },
    editEmployee(employee) {
      this.chosenEmployee = employee;
      this.formContext = 'edit';
      this.isDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-employees {
  max-width: 840px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 12px 0;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
}
</style>
