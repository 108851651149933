<template>
  <div class="page-header">
    <v-container fluid>
      <v-row class="align-center">
        <v-col class="headline font-weight-bold">
          {{ title }}
        </v-col>

        <v-col class="text-right">
          <slot name="action"></slot>
        </v-col>
      </v-row>

      <v-row>
        <slot name="tabs"></slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 84px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: $white;
}
</style>
