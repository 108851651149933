<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <NotificationSnackbar />
  </v-app>
</template>

<script>
import NotificationSnackbar from '@/components/Common/NotificationSnackbar.vue';

export default {
  name: 'App',
  components: {
    NotificationSnackbar,
  },
};
</script>
