<template>
  <div class="app-form client-form">
    <v-form ref="formClient" v-model="isFormValid" @submit.prevent="submitForm">
      <AppFormSubmit :processing="isProcessing" @submit="submitForm" />

      <v-row>
        <v-col cols="4" class="py-0">
          <h2 class="app-form__title app-form__title--low">Form client</h2>

          <div class="app-form__group">
            <label class="app-form__label">Client name *</label>
            <v-text-field
              v-model="client.name"
              rel="employeeNameField"
              :rules="[rules.required]"
              height="36"
              solo
            />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Address</label>
            <v-text-field v-model="client.address" height="36" solo />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">City</label>
            <v-text-field v-model="client.city" height="36" solo />
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Phone number</label>
            <v-text-field v-model="client.phone_number" height="36" solo />
          </div>

          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col cols="6">
                <label class="app-form__label">Company group</label>
                <v-select
                  v-model="client.company_group_id"
                  :items="companyGroupsList"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                  class="app-form__select"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <label class="app-form__label">Client status*</label>
                <v-select
                  v-model="client.client_status_id"
                  :items="clientStatuses"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                  :rules="[rules.required]"
                  class="app-form__select"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="app-form__multiple-row">
              <v-col cols="6">
                <label class="app-form__label">From</label>
                <v-menu
                  ref="menuDateFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="client.group_start_date"
                      eight="36"
                      readonly
                      solo
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="client.group_start_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateFrom.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <label class="app-form__label">To</label>
                <v-menu
                  ref="menuDateTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="client.group_end_date"
                      eight="36"
                      readonly
                      solo
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="client.group_end_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateTo.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="4" class="py-0">
          <h2 class="app-form__title app-form__title--low">Background information</h2>
          <v-row class="app-form__multiple-row">
            <v-col cols="6">
              <div class="app-form__group">
                <label class="app-form__label">Office: Originating *</label>
                <v-select
                  v-model="client.origin_office_id"
                  :items="officesList"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                  class="app-form__select"
                ></v-select>
              </div>
            </v-col>

            <v-col cols="6">
              <div class="app-form__group">
                <label class="app-form__label">Client of *</label>
                <v-select
                  v-model="client.user_id"
                  :items="usersList"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="login"
                  v-bind="componentsProps.select"
                  class="app-form__select"
                ></v-select>
              </div>
            </v-col>
          </v-row>

          <h2 class="app-form__title">Main Executive</h2>
          <div id="executive-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Name</label>
            <v-autocomplete
              v-model="client.contact_id"
              :items="contactsList"
              :loading="isLoading"
              :search-input.sync="search"
              item-value="id"
              :item-text="visibleContactName"
              no-filter
              attach="#executive-search"
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              clearable
              @click:clear="clearExecutive"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>

          <div class="app-form__group">
            <label class="app-form__label">Description</label>
            <v-textarea v-model="client.description" solo />
          </div>
        </v-col>

        <v-col cols="4" class="py-0">
          <h2 class="app-form__title">Attachments</h2>
          <div class="app-form__attachments">
            <div class="app-form__add-attachment">
              <label class="app-form__label" for="attachmentInput" style="cursor: pointer;">
                <v-icon>mdi-attachment</v-icon> File upload
                <input
                  id="attachmentInput"
                  name="attachmentInput"
                  type="file"
                  multiple
                  style="display: none;"
                  @input="addAttachments"
                />
              </label>

              <div class="app-form__attachments-list">
                <v-chip
                  v-for="(attachment, index) in client.attachments"
                  :key="attachment.id"
                  class="mr-6 mb-4 py-4"
                  label
                  close
                  close-icon="mdi-close"
                  @click:close="removeAttachment(attachment.id, index)"
                >
                  <span
                    class="app-form__attachment-name"
                    @click="downloadAtachment(attachment.url)"
                  >
                    {{ attachment.name }}
                  </span>
                  <v-icon
                    v-if="isEditForm"
                    class="app-form__attachment-edit ml-3"
                    small
                    @click="editAttachmentName(attachment)"
                  >
                    mdi-pencil
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="isProcessingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AttachmentNameChange
      :show="isAttachmentNameChangeDialogVisible"
      :attachment="selectedAttachment"
      @close="isAttachmentNameChangeDialogVisible = false"
      @submit="changeAttachmentName"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import clientsApi from '@/api/v1/recruiter/clients';
import usersApi from '@/api/v1/library/users';
import tagsApi from '@/api/v1/library/tags';
import officesApi from '@/api/v1/library/offices';
import contactsApi from '@/api/v1/recruiter/contacts';
import externalRules from '@/helpers/validators';
import AppFormSubmit from '@/components/Common/AppForm/AppFormSubmit.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import AttachmentNameChange from '@/components/Common/AttachmentNameChange.vue';

const componentsProps = customComponentsProps;
const client = models.client;
const clientBaseState = models.client;

export default {
  components: {
    AppFormSubmit,
    ConfirmDialog,
    AttachmentNameChange,
  },

  props: {
    clientData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      client,
      clientBaseState,
      componentsProps,
      isLoading: false,
      isFormValid: true,
      isProcessing: false,
      isProcessingOverlay: false,
      rules: externalRules,
      search: null,
      contactsList: [],
      officesList: [],
      usersList: [],
      companyGroupsList: [],
      clientStatuses: [],
      isAttachmentNameChangeDialogVisible: false,
      selectedAttachment: {},
    };
  },

  computed: {
    isEditForm() {
      return !!this.clientData;
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 3)
      )
        return;

      this.debouncedSearch();
    },
  },

  created() {
    if (this.clientData) {
      this.client = cloneDeep(this.clientData);
      this.clientBaseState = cloneDeep(this.clientData);
    } else {
      this.client = cloneDeep(models.client);
    }

    this.getOffices();
    this.getUsers();
    this.getCompanyGroups();
    this.getTags('clientStatuses');

    if (this.clientData && this.clientData.contact) {
      this.contactsList.push(this.clientData.contact);
    } else {
      this.getContacts();
    }
  },

  methods: {
    getContacts(params) {
      let reqParams = {
        ...params,
      };
      if (this.$route.params.id) {
        reqParams.client_id = this.$route.params.id;
        reqParams.show_hidden = true;
      }

      this.isProcessing = true;
      contactsApi
        .index(reqParams)
        .then(resp => {
          this.contactsList = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getOffices() {
      officesApi
        .index()
        .then(resp => {
          this.officesList = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getUsers() {
      usersApi
        .index({ active: true })
        .then(resp => {
          this.usersList = resp.data.collection;
          if (this.clientData && !this.clientData.user_active) {
            this.usersList.push({ id: this.clientData.user_id, login: this.clientData.user_name });
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getCompanyGroups() {
      tagsApi.companyGroups
        .index()
        .then(resp => {
          this.companyGroupsList = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getTags(type) {
      tagsApi[type].index({ client_index: true }).then(response => {
        this[type] = response.data;
      });
    },

    submitForm() {
      this.$refs.formClient.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.clientData) {
          if (!this.client.contact_id) this.client.contact_id = null;
          // Edit
          clientsApi
            .update(this.$route.params.id, this.client)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          // Create
          clientsApi
            .create(this.client)
            .then(resp => {
              if (this.client.attachments.length) {
                this.isProcessingOverlay = true;

                clientsApi
                  .createAttachment(resp.data.id, this.formData)
                  .then(() => {
                    this.$emit('success');
                  })
                  .catch(err => {
                    this.$store.dispatch('showError', err.response.data.errors);
                    this.$router.push({ name: 'client-summary', params: { id: resp.data.id } });
                  })
                  .finally(() => {
                    this.isProcessingOverlay = false;
                    this.isProcessing = false;
                  });
              } else {
                this.$emit('success');
              }
              // this.$refs.formClient.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getContacts({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),

    clearExecutive() {
      this.getContacts();
    },

    visibleContactName(item) {
      // return `${item.last_name} ${item.first_name} ${item.company_name} ${item.function}`;
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },

    downloadAtachment(url) {
      if (url) window.open(url, '_blank');
    },

    addAttachments(ev) {
      const fileList = ev.target.files;
      let errors = [];
      this.formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].size / 1048576 < 10) {
          this.formData.append('attachments[]', fileList[i]);
          this.client.attachments.push(fileList[i]);
        } else {
          errors.push(`File ${fileList[i].name} is to big`);
        }
      }
      if (errors.length) this.$store.dispatch('showError', errors);

      if (this.clientData) this.submitAttachments(this.formData);
    },

    submitAttachments(attachmentsFormData) {
      this.isProcessingOverlay = true;

      clientsApi
        .createAttachment(this.$route.params.id, attachmentsFormData)
        .then(resp => {
          this.client.attachments = resp.data.attachments;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    removeAttachment(id, index) {
      if (!id) {
        this.client.attachments.splice(index, 1);
      } else {
        this.$refs.confirmDialog
          .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
          .then(confirm => {
            if (confirm) {
              clientsApi
                .deleteAttachment(this.$route.params.id, id)
                .then(() => {
                  this.$emit('success');
                })
                .catch(err => {
                  this.$store.dispatch('showError', err.response.data.errors);
                })
                .finally(() => {
                  this.isProcessing = false;
                });
            }
          });
      }
    },

    editAttachmentName(attachment) {
      this.selectedAttachment = attachment;
      this.isAttachmentNameChangeDialogVisible = true;
    },

    changeAttachmentName(editedAttachment) {
      this.isAttachmentNameChangeDialogVisible = false;
      this.selectedAttachment = {};
      this.isProcessingOverlay = true;

      clientsApi
        .updateAttachment(this.$route.params.id, editedAttachment.id, {
          filename: editedAttachment.filename,
        })
        .then(() => {
          this.$emit('success');
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    isClientModified() {
      return new Promise(resolve => {
        resolve(!isEqual(this.client, this.clientBaseState));
      });
    },
  },
};
</script>

<style lang="scss">
.client-form {
  // max-width: 900px;
  margin-top: -20px;
}
</style>
