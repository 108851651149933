const tags = [
  {
    id: 0,
    name: 'Interviewed',
  },
  {
    id: 1,
    name: 'Open',
  },
  {
    id: 2,
    name: 'Placed',
  },
];

const projects = {
  projectsHeaders: [
    {
      text: 'Created',
      align: 'left',
      sortable: true,
      value: 'created_at',
      width: 164,
    },
    {
      text: 'Project id',
      align: 'left',
      sortable: true,
      value: 'id',
      width: 136,
    },
    {
      text: 'Project name',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Client',
      align: 'left',
      sortable: true,
      value: 'client_name',
    },
    {
      text: 'Candidates',
      align: 'left',
      sortable: true,
      value: 'target_count',
      width: 136,
    },
    {
      text: 'Created by',
      align: 'left',
      sortable: true,
      value: 'created_by',
    },
  ],
};

const employees = {
  employeesHeaders: [
    {
      text: 'Last name',
      align: 'left',
      sortable: false,
      value: 'last_name',
    },
    {
      text: 'First name',
      align: 'left',
      sortable: false,
      value: 'first_name',
    },
    {
      text: 'Code',
      align: 'left',
      sortable: false,
      value: 'login',
    },
    {
      text: 'Library accesss',
      align: 'left',
      sortable: false,
      value: 'library_access',
      width: 136,
    },
    {
      text: 'Active',
      align: 'left',
      sortable: false,
      value: 'active',
      width: 136,
    },
  ],
};

const jobs = {
  jobDescriptionsHeaders: [
    {
      text: 'Code',
      align: 'left',
      sortable: false,
      value: 'code',
    },
    {
      text: 'Function',
      align: 'left',
      sortable: false,
      value: 'function',
    },
  ],
};

const events = {
  eventsList: [
    {
      id: 0,
      hourStart: '09:00',
      hourEnd: '09:30',
      touchType: 'Skype conversation',
      title: 'Inteview',
      contact: 'John Smith',
      company: 'Company',
      place: 'Idea place',
      owner: 'TSZ',
      members: ['DOA', 'TSZ', 'DOA'],
      completed: true,
    },
    {
      id: 1,
      hourStart: '09:30',
      hourEnd: '10:30',
      touchType: 'Skype conversation',
      title: 'Inteview',
      contact: 'John Smith',
      company: 'Company',
      place: 'Idea place',
      owner: 'TSZ',
      members: ['DOA', 'TSZ', 'DOA'],
      completed: false,
    },
    {
      id: 2,
      hourStart: '12:00',
      hourEnd: '12:30',
      touchType: 'Skype conversation',
      title: 'Inteview',
      contact: 'John Smith',
      company: 'Company',
      place: 'Idea place',
      owner: 'TSZ',
      members: ['DOA', 'TSZ', 'DOA', 'DOA', 'TSZ', 'DOA'],
      completed: false,
    },
    {
      id: 3,
      hourStart: '15:00',
      hourEnd: '15:30',
      touchType: 'Skype conversation',
      title: 'Inteview',
      contact: 'John Smith',
      company: 'Company',
      place: 'Idea place',
      owner: 'TSZ',
      members: ['DOA', 'TSZ', 'DOA'],
      completed: false,
    },
    {
      id: 4,
      hourStart: '15:00',
      hourEnd: '15:30',
      touchType: 'Skype conversation',
      title: 'Inteview',
      contact: 'John Smith',
      company: 'Company',
      place: 'Idea place',
      owner: 'TSZ',
      members: ['DOA', 'TSZ', 'DOA'],
      completed: false,
    },
  ],
};

const contacts = {
  contactsHeaders: [
    {
      text: 'Last update',
      align: 'left',
      sortable: true,
      value: 'updated_at',
      width: 164,
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'last_name',
    },
    {
      text: 'First name',
      align: 'left',
      sortable: true,
      value: 'first_name',
    },
    {
      text: 'Company',
      align: 'left',
      sortable: true,
      value: 'company_name',
    },
    {
      text: 'Job title',
      align: 'left',
      sortable: true,
      value: 'function',
    },
    {
      text: 'Fc code',
      align: 'left',
      sortable: true,
      value: 'fc_code',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
    },
  ],
  contactProjectsHeaders: [
    {
      text: 'Created',
      align: 'left',
      sortable: true,
      value: 'created_at',
      width: 164,
    },
    {
      text: 'Project id',
      align: 'left',
      sortable: true,
      value: 'id',
      width: 136,
    },
    {
      text: 'Project name',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Client',
      align: 'left',
      sortable: true,
      value: 'client_name',
    },
    {
      text: 'Target remarks',
      align: 'left',
      sortable: true,
      value: 'target_remark_id',
      width: '15%',
    },
    {
      text: 'Level of interest',
      align: 'left',
      sortable: true,
      value: 'interest_level_id',
      width: '15%',
    },
    {
      text: 'Candidates',
      align: 'left',
      sortable: true,
      value: 'target_count',
      width: 136,
    },
    {
      text: 'Created by',
      align: 'left',
      sortable: true,
      value: 'created_by',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
    },
  ],
  contactDetailsHeaders: [
    {
      text: 'Project name',
      align: 'left',
      sortable: false,
      value: 'project_name',
    },
    {
      text: 'Client',
      align: 'left',
      sortable: false,
      value: 'client_name',
    },
    {
      text: 'Target remarks',
      align: 'left',
      sortable: false,
      value: 'target_remark_id',
      width: '20%',
    },
    {
      text: 'Level of interest',
      align: 'left',
      sortable: false,
      value: 'interest_level_id',
      width: '20%',
    },
    {
      text: 'City',
      align: 'left',
      sortable: false,
      value: 'city',
    },
  ],
  careerHistoryHeaders: [
    {
      text: 'Company',
      align: 'left',
      sortable: false,
      value: 'company',
    },
    {
      text: 'Job Title',
      align: 'left',
      sortable: false,
      value: 'job_title',
    },
    {
      text: 'Fc code',
      align: 'left',
      sortable: false,
      value: 'fc_code',
    },
    {
      text: 'From',
      align: 'left',
      sortable: false,
      value: 'from',
    },
    {
      text: 'To',
      align: 'left',
      sortable: false,
      value: 'to',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
    },
  ],
  contactsHistoryHeaders: [
    {
      text: 'Date',
      align: 'left',
      sortable: false,
      value: 'date',
      width: 124,
    },
    {
      text: 'Time',
      align: 'left',
      sortable: false,
      value: 'time',
      width: 80,
    },
    {
      text: 'Touch type',
      align: 'left',
      sortable: false,
      value: 'touch_type',
    },
    {
      text: 'By',
      align: 'left',
      sortable: false,
      value: 'owner_name',
    },
    {
      text: 'Project title',
      align: 'left',
      sortable: false,
      value: 'project_name',
    },
    {
      text: 'Client',
      align: 'left',
      sortable: false,
      value: 'client_name',
    },
    {
      text: 'Touch title',
      align: 'left',
      sortable: false,
      value: 'touch_title',
    },
    {
      text: 'Comment',
      align: 'left',
      sortable: false,
      value: 'comment',
      width: '20%',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
      width: 124,
    },
  ],
};

const companies = {
  companiesHeaders: [
    {
      text: 'Last update',
      align: 'left',
      sortable: true,
      value: 'updated_at',
      width: 164,
    },
    {
      text: 'Company',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'City',
      align: 'left',
      sortable: true,
      value: 'city',
    },
    {
      text: 'Country',
      align: 'left',
      sortable: true,
      value: 'country',
    },
    {
      text: 'Contacts',
      align: 'left',
      sortable: true,
      value: 'contact_count',
      width: 136,
    },
  ],
};

const clients = {
  clientsHeaders: [
    {
      text: 'Date',
      align: 'left',
      sortable: true,
      value: 'created_at',
      width: 164,
    },
    {
      text: 'Client',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Projects',
      align: 'left',
      sortable: true,
      value: 'project_count',
      width: 136,
    },
    {
      text: 'City',
      align: 'left',
      sortable: true,
      value: 'city',
    },
    {
      text: 'Office origin',
      align: 'left',
      sortable: true,
      value: 'origin_office',
    },
    {
      text: 'Client of',
      align: 'left',
      sortable: true,
      value: 'user',
    },
  ],

  clientProjectsHeaders: [
    {
      text: 'Project number',
      align: 'left',
      sortable: true,
      value: 'id',
    },
    {
      text: 'Project title',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Status',
      align: 'left',
      sortable: true,
      value: 'status_name',
    },
    {
      text: 'Origin. Consultant',
      align: 'left',
      sortable: true,
      value: 'origin_user_name',
    },
    {
      text: 'Researcher',
      align: 'left',
      sortable: true,
      value: 'researching_user_name',
    },
    {
      text: 'Start',
      align: 'left',
      sortable: true,
      value: 'start_date',
    },
    {
      text: 'End',
      align: 'left',
      sortable: true,
      value: 'end_date',
    },
  ],

  clientExecutivesHeaders: [
    {
      text: 'Last update',
      align: 'left',
      sortable: true,
      value: 'updated_at',
      width: 164,
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'last_name',
    },
    {
      text: 'First name',
      align: 'left',
      sortable: true,
      value: 'first_name',
    },
    {
      text: 'Company',
      align: 'left',
      sortable: true,
      value: 'company_name',
    },
    {
      text: 'Job title',
      align: 'left',
      sortable: true,
      value: 'function',
    },
    {
      text: 'Fc code',
      align: 'left',
      sortable: true,
      value: 'fc_code',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
    },
  ],
};

const targets = {
  targetHeaders: [
    {
      text: 'Company',
      align: 'left',
      sortable: true,
      value: 'company_name',
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'last_name',
    },
    {
      text: 'First name',
      align: 'left',
      sortable: true,
      value: 'first_name',
    },
    {
      text: 'Job title',
      align: 'left',
      sortable: true,
      value: 'function',
    },
    {
      text: 'RODO status',
      align: 'left',
      sortable: true,
      value: 'gdpr_status',
    },
    {
      text: 'Target remarks',
      align: 'left',
      sortable: true,
      value: 'target_remark_id',
      width: '13%',
    },
    {
      text: 'Level of interest',
      align: 'left',
      sortable: true,
      value: 'interest_level_id',
      width: '13%',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      value: 'action',
      width: '130',
    },
  ],
  targetDetailsHeaders: [
    {
      text: 'Date',
      align: 'left',
      sortable: false,
      value: 'start_datetime',
      width: '164',
    },
    {
      text: 'Touch type',
      align: 'left',
      sortable: false,
      value: 'touch_type',
    },
    {
      text: 'By',
      align: 'left',
      sortable: false,
      value: 'owner_name',
    },
    {
      text: 'Touch title',
      align: 'left',
      sortable: false,
      value: 'touch_title',
    },
    {
      text: 'Comment',
      align: 'left',
      sortable: false,
      value: 'comment',
      width: '40%',
    },
  ],
};

export default {
  tags,
  employees,
  jobs,
  events,
  projects,
  contacts,
  companies,
  clients,
  targets,
};
