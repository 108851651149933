<template>
  <div class="company-show">
    <header-single-page :title="displayTitle">
      <template #action>
        <template v-if="isCompanySummary">
          <v-btn
            v-shortkey="['ctrl', 'shift', 'd']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="deleteCompany"
            @click="deleteCompany"
          >
            Delete
          </v-btn>
        </template>

        <template v-else>
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
          <v-btn
            v-shortkey="['ctrl', 'shift', 'a']"
            color="secondary"
            elevation="0"
            min-width="112"
            @shortkey="showAssignDialog"
            @click="showAssignDialog"
          >
            Add contact
          </v-btn>
        </template>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab :to="{ name: 'company-summary' }">Summary</v-tab>
          <v-tab :to="{ name: 'company-contacts' }">Contacts</v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <app-dialog :show="isAssignDialogVisible" @close="closeAssignDialog">
      <FormAssignContact
        v-if="isAssignDialogVisible"
        @openAddForm="showAddDialog"
        @success="contactAdded"
      />
    </app-dialog>

    <app-dialog :show="isAddDialogVisible" @close="closeAddDialog">
      <FormContact v-if="isAddDialogVisible" @success="contactAdded" />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import companiesApi from '@/api/v1/recruiter/companies';

import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormContact from '@/components/Recruiter/Contacts/FormContact.vue';
import FormAssignContact from '@/components/Recruiter/Companies/FormAssignContact.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    FormContact,
    FormAssignContact,
    ConfirmDialog,
    DownloadButton,
  },

  data() {
    return {
      isLoading: false,
      isProcessing: false,
      isAssignDialogVisible: false,
      isAddDialogVisible: false,
      dialogContext: 'assign',
      companyData: {},
    };
  },

  computed: {
    isCompanySummary() {
      return this.$route.name === 'company-summary';
    },

    displayTitle() {
      return this.companyData.name ? this.companyData.name : '';
    },

    reportParams() {
      const params = {
        company_id: this.$route.params.id,
        search_tags: this.$store.getters.getCompanyContactsSearchParams.search_tags,
      };
      return params;
    },
  },

  created() {
    this.getCompany();
  },

  methods: {
    getCompany() {
      companiesApi
        .show(this.$route.params.id)
        .then(resp => {
          this.companyData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    deleteCompany() {
      const currentCompanyId = this.$router.history.current.params.id;

      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            companiesApi
              .delete(currentCompanyId)
              .then(() => {
                this.$router.push('/companies');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    showAssignDialog() {
      this.isAssignDialogVisible = true;
    },
    showAddDialog() {
      this.isAddDialogVisible = true;
    },

    closeAssignDialog() {
      this.isAssignDialogVisible = false;
    },
    closeAddDialog() {
      this.isAddDialogVisible = false;
    },

    contactAdded() {
      this.closeAssignDialog();
      this.closeAddDialog();
      eventBus.$emit('update-contacts');
    },

    goToCompanyContacts() {
      this.closeDialog();
      this.$router.push({ name: 'company-contacts', params: { id: this.$route.params.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-show {
  background: inherit;
}
</style>
