<template>
  <div class="clients-index">
    <header-single-page title="Clients">
      <template #action>
        <download-button class="mr-8" :download-params="reportParams">
          REPORT
        </download-button>
        <v-btn
          v-shortkey="['ctrl', 'shift', 'a']"
          color="secondary"
          elevation="0"
          min-width="112"
          @click="showDialog"
          @shortkey="showDialog"
        >
          Add new client
        </v-btn>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab
            v-for="clientStatus in clientStatuses"
            :key="clientStatus.id"
            :to="{ name: 'client-status', params: { statusId: clientStatus.id } }"
          >
            {{ clientStatus.name }} ({{ clientStatus.client_count }})
          </v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormClient v-if="isDialogVisible" @success="clientAdded" />
    </app-dialog>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import tagsApi from '@/api/v1/library/tags';
import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormClient from '@/components/Recruiter/Clients/FormClient.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    FormClient,
    DownloadButton,
  },

  data() {
    return {
      isDialogVisible: false,
      clientStatuses: [],
    };
  },

  created() {
    this.getTags('clientStatuses');
    this.isDialogVisible = false;
  },

  computed: {
    reportParams() {
      const params = {
        clients: true,
        search_tags: this.$store.getters.getClientsSearchParams.search_tags,
      };
      return params;
    },
  },

  methods: {
    getTags(type) {
      tagsApi[type].index({ client_index: true }).then(response => {
        this[type] = response.data;
      });
    },

    clientAdded() {
      this.closeDialog();
      this.getTags('clientStatuses');
      eventBus.$emit('update-clients');
    },

    showDialog() {
      this.isDialogVisible = true;
    },

    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
