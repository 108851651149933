<template>
  <div class="download-button" :class="{ 'download-button--processing': isProcessing }">
    <v-btn class="download-button__btn" color="primary" text @click="download('xlsx')">
      <v-icon v-if="!isProcessing">mdi-download</v-icon>
      <v-icon v-else class="download-button__loader">mdi-loading</v-icon>
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment';
import reportsApi from '@/api/v1/recruiter/reports';

export default {
  props: {
    downloadParams: {
      required: false,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isProcessing: false,
    };
  },

  methods: {
    download(fileType) {
      const params = {
        ...this.downloadParams,
        file_format: fileType,
      };

      reportsApi
        .index(params)
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `report-${moment(this.downloadParams.date).format('YYYY-MM-DD')}.${fileType}`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.download-button {
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: #4bbbc1;

    .v-icon {
      color: $secondary;
    }
  }

  .v-icon {
    color: $primary;
    font-size: 20px;
  }

  &__btn:focus {
    border: unset;
    outline: unset;
  }

  &__loader {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
