import qs from 'qs';
import axios from '@/api/axios';

const COMPANIES_URL = '/companies';

export default {
  index(params) {
    return axios.get(COMPANIES_URL, {
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
  show(id) {
    return axios.get(`${COMPANIES_URL}/${id}`);
  },
  create(data) {
    return axios.post(COMPANIES_URL, data);
  },
  update(id, data) {
    return axios.patch(`${COMPANIES_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${COMPANIES_URL}/${id}`);
  },
};
