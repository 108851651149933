import qs from 'qs';
import axios from '@/api/axios';

const CLIENTS_URL = '/clients';

export default {
  index(params) {
    return axios.get(CLIENTS_URL, {
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
  show(id) {
    return axios.get(`${CLIENTS_URL}/${id}`);
  },
  create(data) {
    return axios.post(CLIENTS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${CLIENTS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${CLIENTS_URL}/${id}`);
  },
  assignExecutive(id, data) {
    return axios.patch(`${CLIENTS_URL}/${id}/assign_executive`, data);
  },
  removeExecutive(id, data) {
    return axios.patch(`${CLIENTS_URL}/${id}/remove_executive`, data);
  },
  getAttachment(clientId, attachmentId) {
    return axios.get(`${CLIENTS_URL}/${clientId}/attachments/${attachmentId}`);
  },
  createAttachment(clientId, formData) {
    return axios.post(`${CLIENTS_URL}/${clientId}/attachments`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  deleteAttachment(clientId, attachmentId) {
    return axios.delete(`${CLIENTS_URL}/${clientId}/attachments/${attachmentId}`);
  },
  updateAttachment(clientId, attachmentId, data) {
    return axios.patch(`${CLIENTS_URL}/${clientId}/attachments/${attachmentId}`, data);
  },
};
