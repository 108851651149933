<template>
  <div>
    <v-text-field
      v-model="query"
      dense
      flat
      solo
      color="black"
      hide-details
      prepend-inner-icon="mdi-magnify"
      :append-icon="query ? 'mdi-close' : ''"
      placeholder="Search"
      label="Search"
      @blur="$emit('input', query)"
      @click:prepend-inner="$emit('input', query)"
      @click:append="[(query = ''), $emit('input', '')]"
      @keyup.enter="$emit('input', query)"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      query: '',
    };
  },
  created() {
    this.query = this.value;
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  border: 1px solid #e0e0e0;
  width: 420px;
}
::v-deep .v-icon {
  color: black !important;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control input {
  color: #6c7b93;
}
::v-deep .v-label {
  font-size: 16px;
  font-weight: 500;
  color: #6c7b93;
}
</style>
