var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-contacts-index"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('FiltersBar',{attrs:{"filters-list-params":_vm.searchParams,"all-filters":_vm.allFilters},on:{"search":_vm.getContacts}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.companyContactsHeaders,"items":_vm.companyContacts,"options":_vm.pagination,"server-items-length":_vm.totals,"loading":_vm.isLoading,"multi-sort":false,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-options': [10, 25, 50, 100],
          }},on:{"update:options":function($event){_vm.pagination=$event},"update:page":_vm.debounceGetCompaniesContacts,"update:items-per-page":_vm.debounceGetCompaniesContacts,"update:sort-by":_vm.getContacts,"update:sort-desc":_vm.getContacts},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('TableFooterCurrentPage',{attrs:{"pagination":_vm.pagination,"totals":_vm.totals}})]},proxy:true},{key:"item.updated_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("displayDate")(value,'YYYY/MM/DD'))+" ")]}},{key:"item.first_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])]}},{key:"item.last_name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"table-cell-link",attrs:{"to":{ name: 'contact-summary', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.deleteCareerEntry(item.career_entry_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }