<template>
  <v-dialog
    v-model="dialogState"
    :max-width="400"
    content-class="confirm-dialog"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="confirm-dialog__content">
      <v-toolbar height="48" class="confirm-dialog__toolbar elevation-0">
        <v-btn icon @click="closeDialog">
          <v-icon size="24" color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="confirm-dialog__message">
        <p><b>Change attachment name:</b><br />{{ attachment.name }}</p>
        <div class="px-4">
          <v-text-field v-model="newName" height="36" />
        </div>
      </v-card-text>

      <v-card-actions class="confirm-dialog__actions">
        <v-btn
          class="confirm-dialog__btn confirm-dialog__btn--cancel elevation-0"
          width="82"
          @click.native="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="confirm-dialog__btn confirm-dialog__btn--cofirm elevation-0"
          width="82"
          color="secondary"
          :disabled="!newName.length"
          @click.native="submit"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    attachment: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      newName: '',
    };
  },

  computed: {
    dialogState: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('close');
      },
    },
  },

  watch: {
    show() {
      this.newName = '';
    },
  },

  methods: {
    closeDialog() {
      this.$emit('close');
    },

    submit() {
      const editedAttachment = {
        id: this.attachment.id,
        filename: this.newName,
      };

      this.$emit('submit', editedAttachment);
    },
  },
};
</script>

<style lang="scss" scoped></style>
