import qs from 'qs';
import axios from '@/api/axios';

const REPORTS_URL = '/reports';

export default {
  index(params) {
    return axios.get(REPORTS_URL, {
      responseType: 'blob',
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
};
