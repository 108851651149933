<template>
  <div class="page-project">
    <v-container fluid>
      <v-row class="page-project__row">
        <v-col>
          <TagGroup
            title="Target remarks"
            :tags-data="targetRemarks"
            tags-type="targetRemarks"
            @refresh="getTags('targetRemarks')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Level of Interest"
            :tags-data="interestLevels"
            tags-type="interestLevels"
            @refresh="getTags('interestLevels')"
            @delete="deleteTag"
          />
          <TagGroup
            title="RODO"
            :tags-data="gdprStatuses"
            tags-type="gdprStatuses"
            @refresh="getTags('gdprStatuses')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Company group"
            :tags-data="companyGroups"
            tags-type="companyGroups"
            @refresh="getTags('companyGroups')"
            @delete="deleteTag"
          />
          <TagGroup
            title="Client state"
            :tags-data="clientStatuses"
            tags-type="clientStatuses"
            @refresh="getTags('clientStatuses')"
            @delete="deleteTag"
          />
        </v-col>
      </v-row>
    </v-container>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import tagsApi from '@/api/v1/library/tags';

import TagGroup from '@/components/Admin/TagGroup.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    TagGroup,
    ConfirmDialog,
  },
  data() {
    return {
      targetRemarks: [],
      interestLevels: [],
      gdprStatuses: [],
      companyGroups: [],
      clientStatuses: [],
    };
  },

  created() {
    this.getTags('targetRemarks');
    this.getTags('interestLevels');
    this.getTags('gdprStatuses');
    this.getTags('companyGroups');
    this.getTags('clientStatuses');
  },

  methods: {
    getTags(type) {
      tagsApi[type].index().then(response => {
        this[type] = response.data;
      });
    },

    deleteTag(payload) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            tagsApi[payload.type].delete(payload.id).then(() => {
              this.getTags(payload.type);
            });
          }
        });
    },
  },
};
</script>
