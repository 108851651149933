<template>
  <div class="app-index">
    <navbar class="mb-16"></navbar>

    <v-main>
      <div class="page-container mt-1 px-3">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </div>
    </v-main>
  </div>
</template>

<script>
import Navbar from '@/components/Admin/Navbar.vue';

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped></style>
