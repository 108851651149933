<template>
  <div class="step-company">
    <v-row>
      <v-col cols="6" offset="3" class="py-0">
        <h3 class="pb-3 mt-2">Find Company</h3>
        <div id="step-company-search" class="app-form__group app-form__group--step-autocomplete">
          <v-autocomplete
            v-model="selected_company_id"
            :items="companies"
            :loading="isLoading"
            :search-input.sync="search"
            no-filter
            attach="#step-company-search"
            item-value="id"
            item-text="name"
            label="Search company"
            v-bind="componentsProps.autocomplete"
            clearable
            @change="getCompany"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="secondary"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="3" class="py-0">
        <h3 class="pb-3">or add new Company</h3>
        <v-form ref="formCompany" v-model="isFormValid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12">
              <div class="app-form__group">
                <label class="app-form__label">Company name</label>
                <v-text-field
                  v-model="company.name"
                  :rules="[rules.required]"
                  :disabled="isFormDisabled"
                  v-bind="componentsProps.simpleTextField"
                />
              </div>

              <div class="app-form__group">
                <label class="app-form__label">Address</label>
                <v-text-field
                  v-model="company.address"
                  :disabled="isFormDisabled"
                  v-bind="componentsProps.simpleTextField"
                />
              </div>

              <div class="app-form__group">
                <label class="app-form__label">City</label>
                <v-text-field
                  v-model="company.city"
                  :rules="[rules.required]"
                  :disabled="isFormDisabled"
                  v-bind="componentsProps.simpleTextField"
                />
              </div>

              <div class="app-form__group">
                <label class="app-form__label">Country</label>
                <v-select
                  v-model="company.country_id"
                  :items="countries"
                  :rules="[rules.required]"
                  :disabled="isFormDisabled"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.simpleTextField"
                  class="app-form__select"
                />
              </div>

              <div class="app-form__group app-form__group--mw-200">
                <label class="app-form__label">Phone number</label>
                <v-text-field
                  v-model="company.phone_number"
                  :disabled="isFormDisabled"
                  v-bind="componentsProps.simpleTextField"
                />
              </div>

              <div class="app-form__group">
                <v-row class="app-form__multiple-row">
                  <v-col cols="4">
                    <label class="app-form__label">Company group</label>
                    <v-select
                      v-model="company.company_group_id"
                      :items="companyGroups"
                      :rules="[rules.required]"
                      :disabled="isFormDisabled"
                      item-value="id"
                      item-text="name"
                      v-bind="componentsProps.select"
                      class="app-form__select"
                    ></v-select>
                  </v-col>

                  <v-col cols="4 py-0">
                    <label class="app-form__label">From</label>
                    <v-menu
                      ref="menuDateFrom"
                      :close-on-content-click="false"
                      :disabled="isFormDisabled"
                      transition="scale-transition"
                      full-width
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="company.group_start_date"
                          :disabled="isFormDisabled"
                          v-bind="componentsProps.simpleTextField"
                          readonly
                          class="app-form__menu"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="company.group_start_date"
                        no-title
                        scrollable
                        @input="$refs.menuDateFrom.save(true)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="4 py-0">
                    <label class="app-form__label">To</label>
                    <v-menu
                      ref="menuDateTo"
                      :close-on-content-click="false"
                      :disabled="isFormDisabled"
                      transition="scale-transition"
                      full-width
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="company.group_end_date"
                          :disabled="isFormDisabled"
                          v-bind="componentsProps.simpleTextField"
                          readonly
                          class="app-form__menu"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="company.group_end_date"
                        no-title
                        scrollable
                        @input="$refs.menuDateTo.save(true)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

              <div class="app-form__group">
                <label class="app-form__label">Description</label>
                <v-textarea v-model="company.description" :disabled="isFormDisabled" solo />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12 text-right mb-6">
              <v-btn
                color="secondary"
                :loading="isProcessing"
                min-width="112"
                elevation="0"
                class="app-form__submit"
                @click="submitForm"
                >Next step</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import externalRules from '@/helpers/validators';
import tagsApi from '@/api/v1/library/tags';
import companiesApi from '@/api/v1/recruiter/companies';

const componentsProps = customComponentsProps;

export default {
  data() {
    return {
      isFormValid: true,
      isLoading: false,
      isProcessing: false,
      rules: externalRules,
      componentsProps,
      selected_company_id: null,
      search: null,
      company: {},
      companies: [],
      companyGroups: [],
      countries: [],
    };
  },

  computed: {
    isFormDisabled() {
      return !!this.selected_company_id;
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 2)
      )
        return;

      this.debouncedSearch();
    },

    selected_company_id(val) {
      if (!val) this.company = cloneDeep(models.company);
    },
  },

  created() {
    this.getCompanies();
    this.getCompanyGroups();
    this.getCountries();

    this.company = cloneDeep(models.company);
  },

  methods: {
    getCompanies(params) {
      this.isProcessing = true;
      companiesApi
        .index(params)
        .then(resp => {
          this.companies = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getCompany(id) {
      if (id) {
        this.isProcessing = true;
        companiesApi
          .show(id)
          .then(resp => {
            this.company = resp.data;
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },

    getCompanyGroups() {
      this.isProcessing = true;

      tagsApi.companyGroups
        .index()
        .then(resp => {
          this.companyGroups = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getCountries() {
      this.isProcessing = true;

      tagsApi.countries
        .index()
        .then(resp => {
          this.countries = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formCompany.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.selected_company_id) {
          const candidateCompany = { id: this.company.id, name: this.company.name };
          this.$store.dispatch('setCandidateCompany', candidateCompany);
          this.$emit('success');
        } else {
          companiesApi
            .create(this.company)
            .then(resp => {
              this.$refs.formCompany.reset();
              const candidateCompany = { id: resp.data.id, name: resp.data.name };
              this.$store.dispatch('setCandidateCompany', candidateCompany);
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getCompanies({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped></style>
