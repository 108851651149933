<template>
  <v-navigation-drawer :value="open" fixed temporary height="100vh" class="nav-drawer">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Calendar
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <router-link :to="{ name: 'calendar-current' }" class="nav-drawer__link">
              Current
            </router-link>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <router-link :to="{ name: 'calendar-place' }" class="nav-drawer__link">
              Place
            </router-link>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <router-link :to="{ name: 'calendar-activity' }" class="nav-drawer__link">
              Activity
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer {
  &__link {
    color: $primary;
    text-decoration: none;
    font-size: 16px;
  }
}
</style>
