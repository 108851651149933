import qs from 'qs';
import axios from '@/api/axios';

const EVENTS_URL = '/events';

export default {
  index(params) {
    return axios.get(EVENTS_URL, {
      params: params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  },
  show(id) {
    return axios.get(`${EVENTS_URL}/${id}`);
  },
  create(data) {
    return axios.post(EVENTS_URL, data);
  },
  update(id, data) {
    return axios.patch(`${EVENTS_URL}/${id}`, data);
  },
  delete(id) {
    return axios.delete(`${EVENTS_URL}/${id}`);
  },
  complete(id, data) {
    return axios.post(`${EVENTS_URL}/${id}/change_completed`, data);
  },
  checkEvents(params) {
    return axios.get(`${EVENTS_URL}/check_days`, { params: params });
  },
};
