<template>
  <div class="app-form form-career-entry">
    <v-form ref="formCareerEntry" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="5">
          <h2 class="app-form__title title">
            <template v-if="careerEntryId">
              Edit career entry
            </template>
            <template v-else>
              New career entry
            </template>
          </h2>
          <div id="company-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Company *</label>
            <v-autocomplete
              v-model="careerEntry.company_id"
              :items="companies"
              :loading="isLoading"
              :search-input.sync="search"
              :rules="[rules.required]"
              no-filter
              item-value="id"
              item-text="name"
              hide-no-data
              label="Search company"
              v-bind="componentsProps.autocomplete"
              clearable
              @input="selectCompany"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
              <template v-slot:item="{ item }">
                {{ `${item.name} (${item.city})` }}
              </template>
              <template v-slot:append-item>
                <div v-intersect="endCompanyIntersect" />
              </template>
            </v-autocomplete>
          </div>
          <div class="app-form__group">
            <label class="app-form__label">Job title *</label>
            <v-text-field
              v-model="careerEntry.job_title"
              :rules="[rules.required]"
              height="36"
              solo
            />
          </div>
          <div class="app-form__group">
            <label class="app-form__label">Function</label>
            <v-select
              v-model="careerEntry.job_description_id"
              :items="fcCodes"
              item-value="id"
              item-text="function"
              label="Selected function"
              append-icon=""
              background-color="#ffffff"
              item-color="secondary"
              color="#fff"
              dense
              solo
              disabled
            ></v-select>
          </div>
          <div class="app-form__group">
            <label class="app-form__label">FC Code *</label>
            <v-autocomplete
              v-model="careerEntry.job_description_id"
              :items="fcCodes"
              item-value="id"
              :item-text="visibleFcCode"
              label="Select code"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              background-color="#ffffff"
              item-color="secondary"
              color="#fff"
              dense
              solo
            />
          </div>

          <div class="app-form__group">
            <v-row>
              <v-col>
                <label class="app-form__label">From *</label>
                <v-menu
                  ref="menuDateFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="careerEntry.from"
                      :rules="[rules.required]"
                      eight="36"
                      readonly
                      solo
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="careerEntry.from"
                    no-title
                    scrollable
                    @input="$refs.menuDateFrom.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <label class="app-form__label">To</label>
                <v-menu
                  ref="menuDateTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="careerEntry.to"
                      eight="36"
                      readonly
                      clearable
                      solo
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="careerEntry.to"
                    no-title
                    scrollable
                    @input="$refs.menuDateTo.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div>
            <v-btn
              color="secondary"
              :loading="isProcessing"
              min-width="112"
              elevation="0"
              class="app-form__submit"
              @click="submitForm"
            >
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import careerApi from '@/api/v1/recruiter/career';
import companiesApi from '@/api/v1/recruiter/companies';
import externalRules from '@/helpers/validators';

const componentsProps = customComponentsProps;

export default {
  props: {
    careerEntryId: {
      type: Number,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      isFormValid: true,
      isProcessing: false,
      isLoading: false,
      rules: externalRules,
      componentsProps,
      careerEntry: {
        contact_id: this.$route.params.id,
        company_id: null,
        job_title: '',
        job_description_id: null,
        from: null,
        to: null,
      },
      companies: [],
      fcCodes: [],
      search: null,
      apiCompanyParams: {
        itemsPerPage: 20,
        page: 1,
      },
    };
  },

  computed: {
    apiCompanyParamsChange() {
      return Object.assign({}, this.apiCompanyParams);
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 2)
      )
        return;

      this.debouncedSearch();
    },

    apiCompanyParamsChange: {
      handler(newV, oldV) {
        this.getCompanies(this.apiCompanyParams, newV.page > oldV.page);
      },
      deep: true,
    },
  },

  created() {
    this.getFcCodes();

    if (this.careerEntryId) {
      this.getCareerEntry(this.careerEntryId);
    } else {
      this.getCompanies(this.apiCompanyParams);
    }
  },

  methods: {
    getCareerEntry(id) {
      careerApi
        .show(id)
        .then(resp => {
          this.careerEntry = resp.data;
          this.companies.push(resp.data.company);
          this.careerEntry.contact_id = this.$route.params.id;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getCompanies(params, append) {
      this.isProcessing = true;
      companiesApi
        .index(params)
        .then(resp => {
          if (append) {
            this.companies.push(...resp.data.collection);
          } else {
            this.companies = resp.data.collection;
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getFcCodes() {
      this.isProcessing = true;
      jobDescriptionsApi
        .index()
        .then(resp => {
          this.fcCodes = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    endCompanyIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.apiCompanyParams.page += 1;
      }
    },

    submitForm() {
      this.$refs.formCareerEntry.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.careerEntryId) {
          careerApi
            .update(this.careerEntryId, this.careerEntry)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          careerApi
            .create(this.careerEntry)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      }
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.apiCompanyParams = {
          ...this.apiCompanyParams,
          page: 1,
          search_tags: {
            autocomplete: this.search,
          },
        };
        this.getCompanies(this.apiCompanyParams);
      } else {
        this.isLoading = false;
      }
    }, 500),

    visibleFcCode(item) {
      return `${item.code} ${item.function}`;
    },

    selectCompany(val) {
      if (val) this.careerEntry.company_id = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-career-entry {
  padding: 8px;
  margin-top: -28px;
}
</style>
