<template>
  <div class="app-form job-description-form">
    <v-row>
      <v-col cols="12">
        <h2 clas="title">
          <template v-if="isEditForm">
            Edit job description
          </template>
          <template v-else>
            Add job description
          </template>
        </h2>
      </v-col>
    </v-row>

    <v-form ref="jobDescriptionForm" v-model="isFormValid" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">FC Code</label>
          <v-text-field
            v-model="jobDescription.code"
            rel="jobDescriptionCode"
            :rules="[rules.required]"
            height="36"
            solo
          />
        </v-col>
        <v-col cols="6" class="pb-0">
          <label class="app-form__label">Function</label>
          <v-text-field
            v-model="jobDescription.function"
            :rules="[rules.required]"
            height="36"
            solo
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12 text-right">
          <div
            class="job-description-form__actions"
            :class="{ 'job-description-form__actions--double': isEditForm }"
          >
            <v-btn
              v-if="isEditForm"
              v-shortkey="['ctrl', 'shift', 'd']"
              color="error"
              :loading="isProcessing"
              elevation="0"
              @shortkey="deleteJobDescription"
              @click="deleteJobDescription"
            >
              Delete FC
            </v-btn>

            <v-btn
              v-shortkey="['ctrl', 'shift', 's']"
              :loading="isProcessing"
              :disabled="!isFormValid"
              color="secondary"
              elevation="0"
              @shortkey="submitForm"
              @click="submitForm"
              @keyup.enter="submitForm"
            >
              <template v-if="isEditForm">
                Save FC
              </template>
              <template v-else>
                Add FC
              </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import externalRules from '@/helpers/validators';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },

  props: {
    jobDescriptionData: {
      type: Object,
      required: false,
      default: () => {},
    },
    formContext: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFormValid: true,
      isProcessing: false,
      rules: externalRules,
      jobDescription: {
        code: '',
        function: '',
      },
    };
  },

  computed: {
    isEditForm() {
      return this.formContext === 'edit';
    },
  },

  watch: {
    jobDescriptionData: {
      handler() {
        if (this.$refs.jobDescriptionForm) this.$refs.jobDescriptionForm.reset();
        if (this.formContext === 'edit') {
          this.jobDescription = Object.assign({}, this.jobDescriptionData);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    submitForm() {
      this.$refs.jobDescriptionForm.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        if (this.isEditForm) {
          jobDescriptionsApi
            .update(this.jobDescriptionData.id, this.jobDescription)
            .then(() => {
              this.$refs.jobDescriptionForm.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          jobDescriptionsApi
            .create(this.jobDescription)
            .then(() => {
              this.$refs.jobDescriptionForm.reset();
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    deleteJobDescription() {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            jobDescriptionsApi
              .delete(this.jobDescriptionData.id)
              .then(() => {
                this.$refs.jobDescriptionForm.reset();
                this.$emit('success');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              })
              .finally(() => {
                this.isProcessing = false;
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.job-description-form {
  max-width: 450px;

  &__actions {
    display: flex;
    justify-content: flex-end;

    &--double {
      justify-content: space-between;
    }
  }
}
</style>
