<style lang="scss" scoped></style>
<template>
  <div class="app-index">
    <Navbar />

    <v-main transition="slide-x-transition">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import Navbar from '@/components/Recruiter/Navbar.vue';
import tagsApi from '@/api/v1/library/tags';

export default {
  components: {
    Navbar,
  },
  created() {
    this.getAllFilters();
  },

  methods: {
    getAllFilters() {
      tagsApi.filters
        .index()
        .then(resp => {
          this.$store.dispatch('setFilters', resp.data);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },
  },
};
</script>
