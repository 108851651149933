import axios from '@/api/axios';

const NOTIFICATIONS_URL = '/notifications';

export default {
  index(params) {
    return axios.get(NOTIFICATIONS_URL, { params: params });
  },
  clearAll() {
    return axios.patch(`${NOTIFICATIONS_URL}/clear_all`);
  },
  setViewed(id) {
    return axios.patch(`${NOTIFICATIONS_URL}/${id}`);
  },
};
