<template>
  <div class="calendar-index">
    <v-fade-transition mode="out-in">
      <calendar-sidebar
        v-if="isNotActivity"
        class="calendar-index__sidebar"
        @add="openDialog"
      ></calendar-sidebar>
    </v-fade-transition>

    <div
      class="calendar-index__content"
      :class="{ 'calendar-index__content--with-sidebar': isNotActivity }"
    >
      <CalendarNavbar class="calendar-index__navbar" />

      <div v-if="isNotActivity" class="calendar-index__datepicker-bar">
        <Dateswitcher />

        <download-button class="mr-8" :download-params="reportParams">
          REPORT
        </download-button>
      </div>

      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>

    <app-dialog :show="isDialogVisible" @close="closeDialog">
      <FormEvent v-if="isDialogVisible" :form-context="formContext" @success="submitSucces" />
    </app-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import eventBus from '@/eventBus';
import CalendarSidebar from '@/components/Recruiter/Calendar/Sidebar.vue';
import CalendarNavbar from '@/components/Recruiter/Calendar/Navbar.vue';
import Dateswitcher from '@/components/Recruiter/Calendar/Dateswitcher.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import FormEvent from '@/components/Recruiter/Calendar/FormEvent.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';

export default {
  components: {
    CalendarSidebar,
    CalendarNavbar,
    Dateswitcher,
    AppDialog,
    FormEvent,
    DownloadButton,
  },

  data() {
    return {
      isDialogVisible: false,
      formContext: {
        calendarDate: true,
        place_id: null,
        owner_id: null,
      },
    };
  },

  computed: {
    isNotActivity() {
      return this.$route.name !== 'calendar-activity';
    },

    selectedUserId() {
      return this.$store.getters.getUser;
    },

    selectedPlaceId() {
      return this.$store.getters.getPlace;
    },

    calendarDate() {
      return this.$store.getters.getDate;
    },

    reportParams() {
      const params = {
        date: [moment(this.calendarDate).format('YYYY-MM-DD')],
        events: true,
      };

      if (this.$route.name === 'calendar-current') {
        params.user_id = this.selectedUserId;
      } else {
        params.place_id = this.selectedPlaceId ? this.selectedPlaceId : null;
      }
      return params;
    },
  },

  methods: {
    openDialog(data) {
      if (data.type === 'user') {
        this.formContext.owner_id = data.id;
        this.formContext.place_id = null;
      } else {
        this.formContext.place_id = data.id;
        this.formContext.owner_id = null;
      }
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },

    submitSucces() {
      this.closeDialog();
      eventBus.$emit('update-events');
    },
  },
};
</script>

<style lang="scss">
.calendar-index {
  &__sidebar {
    width: 100%;
  }

  &__navbar {
    display: none;
  }

  &__datepicker-bar {
    display: none;
  }

  &__raport {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    text-transform: uppercase;
  }

  .v-card {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
}
@media (min-width: 960px) {
  .calendar-index {
    display: flex;
    flex-direction: row;

    &__sidebar {
      flex: 0 0 260px;
      position: fixed;
      height: 100%;
      width: 260px;
    }

    &__content {
      flex: 1;

      &--with-sidebar {
        margin-left: 260px;
      }
    }

    &__navbar {
      display: block;
    }

    &__datepicker-bar {
      display: block;
      height: 70px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 72px;
      margin-top: 54px;
    }

    .v-card {
      -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
