/* eslint-disable vue/no-v-html */
<template>
  <v-app-bar class="top-navbar" elevation="1" height="54" color="primary" app>
    <v-toolbar-title class="headline mr-md-12">
      <span class="d-none d-md-block display-1 text_reversed--text font-weight-bold">Signium</span>

      <v-btn class="d-md-none" icon @click.stop="mobileMenu = !mobileMenu">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-toolbar-title>

    <NavMenu class="d-none d-md-block" />

    <div class="flex-grow-1"></div>

    <span class="top-navbar__route-name d-md-none">{{ currentRoute }}</span>

    <div class="flex-grow-1"></div>

    <div class="notifications-widget">
      <v-btn
        v-shortkey="['ctrl', 'shift', 'n']"
        class="notifications-widget__btn d-none d-md-inline-flex"
        icon
        @shortkey="showNotificationDropdown"
        @click="showNotificationDropdown"
      >
        <v-badge color="transparent">
          <span
            class="top-navbar__badge"
            :class="{ 'top-navbar__badge--new': notificationsData.length }"
          ></span>
          <v-icon color="white">mdi-bell</v-icon>
        </v-badge>
      </v-btn>

      <div
        v-if="isNotificationDropdownVisible"
        ref="notificationWidget"
        class="notifications-widget__dropdown"
      >
        <div class="notifications-widget__dropdown-header">
          <v-btn
            :loading="isProcessing"
            :disabled="!notificationsData.length"
            text
            small
            @click="setAllAsViewed"
          >
            Set all as viewed
          </v-btn>
          <v-btn :loading="isProcessing" text small @click="goToNotifications">See all</v-btn>
        </div>

        <div class="notifications-widget__dropdown-content">
          <template v-if="notificationsData.length">
            <div
              v-for="notification in notificationsData"
              :key="notification.id"
              class="notifications-widget__dropdown-element"
            >
              <div
                class="notifications-widget__message"
                :class="{
                  'notifications-widget__message--no-ref':
                    notification.reference_type && notification.reference_type === 'event',
                }"
                @click="goToNotificationTarget(notification)"
                v-html="notification.message"
              >
                {{ notification.message }}
              </div>
              <div class="notifications-widget__details">
                <span>{{ timeAgo(notification.created_at) }}</span>
                <v-btn x-small icon @click="setAsViewed(notification.id)">
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="notifications-widget__empty">
              Empty
            </div>
          </template>
        </div>
      </div>
    </div>

    <span class="top-navbar__separator d-none d-md-inline-flex"></span>

    <v-btn icon @click="logout">
      <v-icon color="white">mdi-arrow-expand-right</v-icon>
    </v-btn>

    <ConfirmDialog ref="confirmDialog" />

    <NavDrawer :open="mobileMenu" />
  </v-app-bar>
</template>

<script>
import * as timeago from 'timeago.js';
import notificationsApi from '@/api/v1/recruiter/notifications';

import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import NavDrawer from '@/components/Recruiter/NavDrawer.vue';
import NavMenu from '@/components/Recruiter/NavMenu.vue';

export default {
  components: {
    ConfirmDialog,
    NavDrawer,
    NavMenu,
  },

  data() {
    return {
      mobileMenu: false,
      isNotificationDropdownVisible: false,
      notificationsData: [],
    };
  },

  computed: {
    currentRoute() {
      const route_name = this.$route.meta.title;
      return route_name;
    },
    currentUserId() {
      return parseInt(this.$store.getters.getUser);
    },
  },

  created() {
    this.getNotifications();
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutsideDropdown);
  },

  destroyed() {
    document.removeEventListener('click', this.handleClickOutsideDropdown);
  },

  methods: {
    showNotificationDropdown() {
      this.isNotificationDropdownVisible = !this.isNotificationDropdownVisible;

      if (this.isNotificationDropdownVisible) {
        setTimeout(() => {
          this.isListTransparent = false;
        });
      }
    },

    handleClickOutsideDropdown(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isNotificationDropdownVisible = false;
      }
    },

    getNotifications() {
      this.isProcessing = true;

      notificationsApi
        .index({ viewed: false, user_id: this.currentUserId })
        .then(resp => {
          this.notificationsData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    setAsViewed(id) {
      notificationsApi
        .setViewed(id)
        .then(() => {
          this.getNotifications();
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    setAllAsViewed() {
      notificationsApi
        .clearAll()
        .then(() => {
          this.getNotifications();
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    goToNotifications() {
      this.$router.push({ name: 'notifications' });
      this.isNotificationDropdownVisible = false;
    },

    goToNotificationTarget(notification) {
      if (notification.reference_type && notification.reference_type !== 'event') {
        this.$router.push({
          name: `${notification.reference_type}-summary`,
          params: { id: notification.reference_id },
        });
        this.isNotificationDropdownVisible = false;
      }
    },

    timeAgo(time) {
      return timeago.format(time);
    },

    logout() {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to logout?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/login');
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-navbar {
  z-index: 200 !important;

  &__route-name {
    color: $white;
    text-transform: uppercase;
    font-size: 16px;
  }

  &__separator {
    width: 1px;
    height: 27px;
    margin: 0 8px;
    border-right: 1px solid $white;
  }

  &__badge {
    color: $white;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 12px;
    height: 12px;
    text-align: center;
    background: $secondary;
    border-radius: 50%;
    font-size: 8px;
    line-height: 16px;
    border: 1px solid $white;

    &--new {
      background: #b71c1c;
    }
  }
}

.notifications-widget {
  position: relative;

  &__dropdown {
    position: absolute;
    right: 0;
    width: 252px;
    max-height: 250px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    background-color: #ffffff;
  }

  &__dropdown-header {
    border-bottom: 1px solid #ddd;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__dropdown-content {
    max-height: 210px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__dropdown-element {
    border-bottom: 1px solid #ddd;
    padding: 10px 16px;
  }

  &__message {
    font-size: 12px;
    margin-bottom: 6px;
    cursor: pointer;

    &--no-ref {
      cursor: default;
    }
  }

  &__details {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__empty {
    font-size: 12px;
    padding: 10px 16px;
    text-align: center;
  }
}
</style>
