const props = {
  select: {
    'append-icon': 'mdi-chevron-down',
    'background-color': '#ffffff',
    'item-color': 'secondary',
    color: '#fff',
    dense: true,
    solo: true,
  },
  simpleSelect: {
    height: '36',
    dense: true,
    solo: true,
    'hide-details': true,
  },
  autocomplete: {
    'append-icon': 'mdi-chevron-down',
    'background-color': '#ffffff',
    'item-color': 'secondary',
    color: '#fff',
    dense: true,
    solo: true,
    clearable: true,
  },
  multipleSelect: {
    'append-icon': 'mdi-chevron-down',
    'background-color': '#ffffff',
    'item-color': 'secondary',
    color: '#fff',
    dense: true,
    solo: true,
    chips: true,
    'small-chips': true,
    'deletable-chips': true,
    multiple: true,
  },
  simpleTextField: {
    height: '36',
    solo: true,
  },
  sidebarSelect: {
    'append-icon': 'mdi-chevron-down',
    'background-color': '#3F7E89',
    'item-color': 'secondary',
    color: '#fff',
    height: '36',
    width: '168',
    'hide-details': true,
    dense: true,
    solo: true,
  },
};

export default props;
