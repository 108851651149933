<template>
  <div class="dateswitcher">
    <v-btn icon>
      <v-icon
        v-shortkey="['ctrl', 'alt', 'arrowleft']"
        class="dateswitcher__icon"
        @shortkey="changeDate(-1)"
        @click="changeDate(-1)"
      >
        mdi-chevron-left
      </v-icon>
    </v-btn>

    <span class="dateswitcher__date">
      {{ formattedCalendarDate }}
    </span>

    <v-btn icon>
      <v-icon
        v-shortkey="['ctrl', 'alt', 'arrowright']"
        class="dateswitcher__icon"
        @shortkey="changeDate(1)"
        @click="changeDate(1)"
      >
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {};
  },

  computed: {
    formattedCalendarDate() {
      return moment(this.$store.getters.getDate).format('dd, Do MMMM, YYYY');
    },
  },

  methods: {
    changeDate(val) {
      this.$store.dispatch('increaseDecreaseDate', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.dateswitcher {
  font-size: 18px;
  display: flex;
  align-items: center;

  &__date {
    padding: 0px 8px;
    display: inline-block;
    cursor: default;
  }
}
</style>
