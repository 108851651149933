<template>
  <div class="projects-contacts">
    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getCandidates"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="targetHeaders"
            :items="targets"
            item-key="id"
            :items-per-page="10"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :expanded.sync="expanded"
            :show-expand="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="custom-table elevation-1"
            @update:page="debounceGetCandidates"
            @update:items-per-page="debounceGetCandidates"
            @update:sort-by="getCandidates"
            @update:sort-desc="getCandidates"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item.company_name="{ item }">
              <router-link
                :to="{ name: 'company-summary', params: { id: item.company_id } }"
                class="table-cell-link"
              >
                {{ item.company_name }}
              </router-link>
            </template>

            <template #item.last_name="{ item }">
              <router-link
                :to="{ name: 'contact-summary', params: { id: item.contact_id } }"
                class="table-cell-link"
              >
                {{ item.last_name }}
              </router-link>
            </template>

            <template #item.first_name="{ item }">
              <router-link
                :to="{ name: 'contact-summary', params: { id: item.contact_id } }"
                class="table-cell-link"
              >
                {{ item.first_name }}
              </router-link>
            </template>

            <template #item.function="{ item }">
              <div>{{ item.function }}</div>
            </template>

            <template #item.target_remark_id="{ item }">
              <div class="custom-table__select">
                <v-select
                  v-model="item.target_remark"
                  :items="optionsTargetRemarks"
                  item-value="id"
                  item-text="name"
                  return-object
                  label="Select"
                  v-bind="componentsProps.simpleSelect"
                  @change="updateCandidate(item)"
                ></v-select>
              </div>
            </template>

            <template #item.interest_level_id="{ item }">
              <div class="custom-table__select">
                <v-select
                  v-model="item.interest_level"
                  :items="optionsLevelOfInterest"
                  item-value="id"
                  item-text="name"
                  return-object
                  label="Select"
                  v-bind="componentsProps.simpleSelect"
                  @change="updateCandidate(item)"
                ></v-select>
              </div>
            </template>

            <template #item.action="{ item }">
              <v-btn color="primary" icon @click="deleteCandidate(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-icon class="custom-table__expand-toggler" @click="toggleExtend(item)">
                mdi-chevron-down
              </v-icon>
            </template>

            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <v-data-table
                  :headers="targetDetailsHeaders"
                  :items="item.activities"
                  hide-default-footer
                  class="custom-table__expanded-table elevation-0"
                >
                  <template #item.start_datetime="{ value }">
                    <template v-if="value">
                      {{ value | displayDate('YYYY-MM-DD') }}
                    </template>
                  </template>
                  <template #item.comment="{ item }">
                    <div
                      v-if="item.comment"
                      class="cursor-default py-2"
                      @click="showFullTextDialog(item.comment)"
                    >
                      {{ getExcerpt(item.comment, 150) }}
                    </div>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="isFullTextDialogVisible"
      :max-width="720"
      content-class="confirm-dialog"
      scrollable
      @keydown.esc="closeFullTextDialog"
      @click:outside="selectedFullText = ''"
    >
      <v-card>
        <v-card-text>
          <v-textarea v-model="selectedFullText" flat auto-grow readonly no-resize solo />
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import eventBus from '@/eventBus';
import consts from '@/api/consts';
import customComponentsProps from '@/helpers/customComponentsProps';
import projectTargetsApi from '@/api/v1/recruiter/projectTargets';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const componentsProps = customComponentsProps;
const targetHeaders = consts.targets.targetHeaders;
const targetDetailsHeaders = consts.targets.targetDetailsHeaders;

export default {
  components: {
    FiltersBar,
    ConfirmDialog,
    TableFooterCurrentPage,
  },

  data() {
    return {
      componentsProps,
      isLoading: false,
      isProcessing: false,
      isFullTextDialogVisible: false,
      selectedFullText: '',
      expanded: [],
      targets: [],
      targetHeaders,
      targetDetailsHeaders,
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.targets.pagination;
      },
      set(val) {
        this.$store.dispatch('setTargetsPagination', val);
      },
    },

    optionsTargetRemarks() {
      return this.$store.getters.getFilterItems('target_remarks') || [];
    },

    optionsLevelOfInterest() {
      return this.$store.getters.getFilterItems('interest_levels') || [];
    },

    totals() {
      return this.$store.state.targets.totals;
    },

    allFilters() {
      return this.$store.state.targets.search || {};
    },

    searchParams() {
      return {
        name: {
          key: 'name',
          displayName: 'Name',
          hidden: false,
        },
        phone_number: {
          key: 'phone_number',
          displayName: 'Phone number',
          hidden: false,
        },
        email: {
          key: 'email',
          displayName: 'Email',
          hidden: false,
        },
        job_title: {
          key: 'job_title',
          displayName: 'Job title',
          hidden: false,
        },
        fc_code: {
          key: 'fc_code',
          displayName: 'FC code',
          hidden: false,
        },
        location: {
          key: 'location',
          displayName: 'Location',
          hidden: false,
        },
        nationality_id: {
          key: 'nationality_id',
          displayName: 'Nationality',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('nationalities'),
        },
        language_ids: {
          key: 'language_ids',
          displayName: 'Language',
          autocomplete: true,
          data: this.$store.getters.getFilterItems('languages'),
        },
        target_remark_id: {
          key: 'target_remark_id',
          displayName: 'Target remark',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('target_remarks'),
        },
        interest_level_id: {
          key: 'interest_level_id',
          displayName: 'Level of interest',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('interest_levels'),
        },
        gdpr_status_id: {
          key: 'gdpr_status_id',
          displayName: 'Rodo',
          hidden: false,
          autocomplete: true,
          data: this.$store.getters.getFilterItems('gdpr_statuses'),
        },
      };
    },
  },

  mounted() {
    this.getCandidates();

    eventBus.$on('update-project-contacts', () => {
      this.getCandidates();
    });
  },

  methods: {
    debounceGetCandidates: debounce(function() {
      this.getCandidates();
    }, 500),
    getCandidates(filters) {
      if (filters) this.$store.dispatch('setTargetsFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setTargetsPage', 1);

      this.isDialogVisible = false;
      this.isLoading = true;

      const params = this.$store.getters.getTargetsSearchParams;
      params.project_id = this.$route.params.id;

      projectTargetsApi
        .index(params)
        .then(resp => {
          this.targets = resp.data.collection;
          this.$store.dispatch('setTargetsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    toggleExtend(contact) {
      const contactPosition = this.expanded.findIndex(el => el.id === contact.id);

      if (contactPosition < 0) {
        this.expanded.push(contact);
      } else {
        this.expanded.splice(contactPosition, 1);
      }
    },

    getExcerpt(str, limit) {
      if (!str) return;
      const fullText = str;
      let shortText = fullText.substr(0, limit);
      if (fullText.length > limit) {
        shortText += '...';
      }
      return shortText;
    },

    showFullTextDialog(text) {
      this.selectedFullText = text;
      this.isFullTextDialogVisible = true;
    },

    closeFullTextDialog() {
      this.isFullTextDialogVisible = false;
    },

    updateCandidate(target) {
      this.isProcessing = true;

      const payload = {
        ...target,
        target_remark_id: target.target_remark.id,
        interest_level_id: target.interest_level.id,
      };

      projectTargetsApi
        .update(target.id, payload)
        .then(() => {
          this.getCandidates;
          this.$store.dispatch('showSuccess', ['Candidate updated']);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    deleteCandidate(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            projectTargetsApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Deleted']);
                this.getCandidates();
              })
              .catch(err => {
                this.$store.dispatch('showSuccess', err.response.data.errors);
              });
          }
        });
    },
  },
};
</script>
