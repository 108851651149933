<template>
  <div class="project-activity">
    <v-container fluid>
      <v-row>
        <v-col class="text-right">
          <DaterangePicker :value="activityDateRange" @input="changeRangeDate" />
        </v-col>
      </v-row>

      <ActivityList :activity-data="activityData" />
    </v-container>
  </div>
</template>

<script>
import notificationsApi from '@/api/v1/recruiter/notifications';
import ActivityList from '@/components/Common/ActivityList.vue';
import DaterangePicker from '@/components/Recruiter/Calendar/Daterange.vue';

export default {
  components: {
    ActivityList,
    DaterangePicker,
  },

  data() {
    return {
      activityData: [],
    };
  },

  computed: {
    activityDateRange() {
      return this.$store.getters.getActivityDateRange;
    },
  },

  watch: {
    activityDateRange() {
      this.getActivities();
    },
  },

  created() {
    this.getActivities();
  },

  methods: {
    getActivities() {
      this.isProcessing = true;

      const parameters = {
        date_range: this.activityDateRange,
        project_id: this.$route.params.id,
      };

      notificationsApi
        .index(parameters)
        .then(resp => {
          this.activityData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    changeRangeDate(dateRange) {
      this.$store.dispatch('setActivityDateRange', dateRange);
    },
  },
};
</script>

<style lang="scss" scoped>
.project-activity {
  max-width: 820px;
  margin: 48px auto 0;
}
</style>
