<template>
  <div class="multisearch">
    <v-menu
      v-model="isSelectVisible"
      :close-on-content-click="false"
      transition="scale-transition"
      full-width
      offset-y
      bottom
      nudge-bottom="9"
      content-class="multisearch__menu"
    >
      <template v-slot:activator="{ on }">
        <div class="d-flex align-center">
          <div class="d-flex flex-wrap">
            <div
              ref="paramNameField2"
              class="multisearch__placeholder d-flex align-center mr-2"
              :class="{ 'multisearch__placeholder--active': isSelectVisible }"
              v-on="on"
            >
              <span class="mr-1">Add filter</span>
              <v-icon :color="isSelectVisible ? 'white' : 'black'" size="22">mdi-plus</v-icon>
            </div>

            <template v-if="selectedSearchParams.length > 0">
              <v-chip
                v-for="(selectedSearchParam, index) in groupedSearchParams"
                :key="index"
                close
                class="mr-2 mb-1"
                @click:close="removeParam(index, selectedSearchParam.key)"
              >
                <span class="mr-1 v-chip__text">{{ selectedSearchParam.displayName }}: &nbsp;</span>
                <template v-if="selectedSearchParam.select || selectedSearchParam.autocomplete">
                  <span class="v-chip__value">{{ selectedSearchParam.value.name }}</span>
                </template>
                <template v-else-if="selectedSearchParam.multiselect">
                  <span class="v-chip__value">
                    {{ selectedSearchParam.value.map(obj => obj.name).join(', ') }}
                  </span>
                </template>
                <template v-else-if="selectedSearchParam.dateRange">
                  <span class="mr-1 v-chip__text">from</span>
                  <span class="mr-1 v-chip__value">{{ selectedSearchParam.value.from }}</span>
                  <span class="mr-1 v-chip__text">to</span>
                  <span class="v-chip__value">{{ selectedSearchParam.value.to }}</span>
                </template>
                <span v-else class="v-chip__value">{{ selectedSearchParam.value }}</span>
              </v-chip>
            </template>
          </div>
          <v-divider></v-divider>
          <div
            v-if="selectedParams.length"
            class="multisearch__placeholder multisearch__clear-button d-flex align-center mx-2"
            @click="removeAllParams"
          >
            <span class="mr-1">Clear</span>
            <v-icon color="white" size="22">mdi-delete-sweep-outline</v-icon>
          </div>
        </div>
      </template>

      <div class="multisearch__select">
        <ul v-if="!isParamSelected" class="multisearch__list">
          <li
            v-for="(param, index) in availableSearchParams"
            :key="index"
            class="multisearch__element"
            @click="selectParam(param)"
          >
            {{ param.displayName }}
          </li>
        </ul>

        <div v-show="isParamSelected">
          <v-form
            ref="multiselectSearch"
            v-model="isFormValid"
            class="multisearch__form"
            @submit.prevent="addParam"
          >
            <div class="multisearch__form-header">
              <span>{{ selectedParam.displayName }}</span>
              <v-icon size="24" color="primary" @click="abortSelect">mdi-close</v-icon>
            </div>
            <div class="multisearch__form-body">
              <v-select
                v-if="selectedParam.select"
                v-model="selectedParam.value"
                :items="selectedParam.data"
                item-value="id"
                item-text="name"
                return-object
                :placeholder="selectedParam.displayName"
                autocomplete="new-password"
                class="multisearch__select-menu"
              />
              <v-autocomplete
                v-else-if="selectedParam.autocomplete"
                v-model="selectedParam.value"
                :items="selectedParam.data"
                item-value="id"
                item-text="name"
                return-object
                dense
                :placeholder="selectedParam.multiselect ? 'Search' : selectedParam.displayName"
                autocomplete="new-password"
                class="multisearch__select-menu"
              />
              <InputMultiple
                v-else-if="selectedParam.multiselect"
                :key="selectedParam.key + selectParamKey"
                v-model="selectedParam.value"
                :selected-items-prop="selectedParam.value"
                :items="selectedParam.data"
                label="Search"
              />
              <div v-else-if="selectedParam.dateRange">
                <DatePickerRangeGroup v-model="selectedParam.value" is-filters-list />
              </div>
              <v-text-field
                v-else
                ref="paramNameField"
                v-model="selectedParam.value"
                dense
                color="#4BBBC1"
                placeholder="Enter key word"
                append-icon="mdi-close-circle"
                @click:append="abortSelect"
              />
            </div>
            <v-divider></v-divider>
            <div class="multisearch__form-footer mt-3">
              <button class="multisearch__btn" type="submit">Save filter</button>
            </div>
          </v-form>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { cloneDeep, pickBy } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import externalRules from '@/helpers/validators';
import DatePickerRangeGroup from '@/components/Common/Filters/DatePickerRangeGroup.vue';
import InputMultiple from '@/components/Common/Filters/InputMultiple.vue';

const componentsProps = customComponentsProps;

export default {
  components: {
    DatePickerRangeGroup,
    InputMultiple,
  },
  props: {
    params: {
      type: Object,
      required: false,
      default: () => {},
    },
    selectedParams: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      searchParams: this.params,
      componentsProps,
      rules: externalRules,
      isFormValid: true,
      isSelectVisible: false,
      isParamSelected: false,
      selectParamKey: 0,

      selectedParam: {
        key: '',
        displayName: '',
        value: '',
      },

      selectedSearchParams: [],
    };
  },

  computed: {
    availableSearchParams() {
      const filtered = pickBy(this.searchParams, param => {
        return !param.hidden;
      });
      return filtered;
    },
    groupedSearchParams() {
      let grouped = [];
      this.selectedSearchParams.forEach(param => {
        const foundParamIndex = grouped.findIndex(item => item.key === param.key);
        if (foundParamIndex !== -1) {
          if (param.multiselect) {
            grouped[foundParamIndex].value = grouped[foundParamIndex].value.concat(param.value);
          } else if (param.autocomplete) {
            grouped[foundParamIndex].value.name =
              grouped[foundParamIndex].value.name + ', ' + param.value.name;
          } else {
            grouped[foundParamIndex].value = grouped[foundParamIndex].value + ', ' + param.value;
          }
        } else {
          grouped.push(cloneDeep(param));
        }
      });
      return grouped;
    },
  },

  watch: {
    isSelectVisible(val) {
      if (!val) this.abortSelect();
    },

    selectedSearchParams: {
      handler(val) {
        this.$emit('input', 'search_tags', val);
      },
      deep: true,
    },

    $route() {
      this.selectedSearchParams = [];
    },

    params() {
      this.searchParams = this.params;
    },
  },

  created() {
    if (this.selectedParams && this.selectedParams.length) {
      this.selectedSearchParams = this.selectedParams;

      this.selectedParams.forEach(item => {
        if (item.key === 'name') {
          this.searchParams[item.key].hidden = true;
        }
      });
    }
  },

  methods: {
    selectParam(param) {
      this.selectParamKey++;
      this.selectedParam = cloneDeep(param);
      if (
        this.selectedParams.length &&
        (this.selectedParam.key === 'name' ||
          this.selectedParam.key === 'date_range' ||
          !!this.selectedParam.multiselect)
      ) {
        const foundObject = this.selectedParams.find(item => item.key === param.key);
        this.selectedParam.value = foundObject ? foundObject.value : null;
      }
      this.isParamSelected = true;
      if (this.$refs.paramNameField) this.$nextTick(this.$refs.paramNameField.focus);
    },

    abortSelect() {
      this.isParamSelected = false;
      this.selectedParam.value = null;
    },

    addParam() {
      if (this.selectedParam.value && this.selectedParam.value !== '') {
        if (this.selectedParam.dateRange) {
          const foundParamIndex = this.selectedSearchParams.findIndex(item => !!item.dateRange);
          if (foundParamIndex !== -1) {
            this.selectedSearchParams.splice(foundParamIndex, 1);
          }
          this.selectedSearchParams.push(cloneDeep(this.selectedParam));
          this.abortSelect();
          return;
        } else if (this.selectedParam.multiselect) {
          const foundParamIndex = this.selectedSearchParams.findIndex(
            item => item.key === this.selectedParam.key,
          );
          if (foundParamIndex !== -1) {
            this.selectedSearchParams[foundParamIndex].value = this.selectedParam.value;
          } else {
            this.selectedSearchParams.push(cloneDeep(this.selectedParam));
          }
          this.abortSelect();
          return;
        } else if (this.selectedParam.autocomplete) {
          const foundParamIndex = this.selectedSearchParams.findIndex(
            item => item.key === this.selectedParam.key,
          );
          if (
            foundParamIndex !== -1 &&
            this.selectedSearchParams[foundParamIndex].value.id === this.selectedParam.value.id
          ) {
            this.abortSelect();
            return;
          } else {
            this.selectedSearchParams.push(cloneDeep(this.selectedParam));
          }
          this.abortSelect();
          return;
        }
        if (this.selectedParam.key === 'name') {
          this.searchParams[this.selectedParam.key].hidden = true;
        }
        this.selectedSearchParams.push(cloneDeep(this.selectedParam));
        this.abortSelect();
      }
    },

    removeParam(index, key) {
      this.selectedSearchParams.splice(index, 1);
      const newParams = [];
      this.selectedSearchParams.forEach(item => {
        if (item.key !== key) newParams.push(item);
      });
      this.selectedSearchParams = newParams;
      if (key === 'name') this.searchParams[key].hidden = false;
    },

    mapTagsArrayToSearchObject(arr) {
      let searchTagsObject = {};
      arr.forEach(item => {
        if (!searchTagsObject[item.key]) {
          searchTagsObject[item.key] = new Array();
        }
        if (item.select || item.autocomplete) {
          searchTagsObject[item.key].push(item.value.id);
        } else {
          searchTagsObject[item.key].push(item.value);
        }
      });
      return searchTagsObject;
    },
    removeAllParams() {
      this.selectedSearchParams = [];
      this.searchParams = Object.keys(this.params).reduce((acc, key) => {
        acc[key] = { ...this.params[key], hidden: false };
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.multisearch {
  display: block;
  min-height: 40px;
  cursor: pointer;

  &__placeholder {
    height: 40px;
    background: $white;
    padding: 8px 8px 8px 12px;
    color: $black;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    &--active {
      background: $secondary;
      color: $white;
    }
  }

  &__clear-button {
    background: $secondary;
    color: $white;
    box-shadow: none;
    margin-bottom: 2px;
  }

  &__select {
    width: 189px;
    background-color: $white;
  }

  &__list {
    list-style-type: none;
    padding-left: 0px !important;
  }
  &__element {
    color: $gray;
    height: 41px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    color: $black;
    cursor: pointer;

    &:hover {
      background: #dfdede;
    }
  }
  &__form-header {
    height: 41px;
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #dfdede;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
  }
  &__form-body {
    padding: 6px 10px;
  }
  &__form-footer {
    text-align: left;
    padding: 0 12px 10px;
  }
  &__btn {
    font-size: 14px;
    font-weight: 700;
    border: none;
    background: $secondary;
    border-radius: 4px;
    padding: 8px 12px;
    color: $white;
    &:hover {
      background: #4bbbc1c0;
    }
  }

  .v-chip {
    height: 40px;
    border: 1px solid #e0e0e0;
    background: $white !important;
    color: $black;
    border-radius: 4px;
    padding: 12px 12px;
    font-weight: 700;
    font-size: 16px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    .v-icon.mdi-close-circle {
      color: $black;

      &::before {
        content: '\F156' !important;
      }
    }
    &__text {
      color: #6c7b93;
      font-weight: 400;
      font-size: 16px;
    }
    &__value {
      color: $black;
      font-weight: 400;
      font-size: 16px;
      max-width: 450px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
}
.multisearch__menu.v-menu__content {
  min-width: unset !important;
  max-height: 568px;
  background: $white;
}
.multisearch__select-menu {
  .v-list-item {
    min-height: 40px;
    .v-list-item__content {
      padding: 8px 0;
      .v-list-item__title {
        font-size: 14px;
      }
    }
  }
}

::v-deep .mdi-close-circle {
  font-size: 15px !important;
  cursor: pointer;
}

::v-deep .v-text-field input {
  font-size: 16px;
  font-weight: 700;
}
</style>
