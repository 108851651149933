<template>
  <div class="header-single-page mb-2">
    <v-container fluid class="d-flex flex-column justify-space-between">
      <div class="header-single-page__top">
        <v-row class="align-center">
          <v-col class="headline font-weight-bold">
            {{ title }}
            <template v-if="additionalInfo">
              <span class="header-single-page__additional">({{ additionalInfo }})</span>
            </template>
          </v-col>

          <v-col class="text-right">
            <slot name="action"></slot>
          </v-col>
        </v-row>
      </div>

      <div class="header-single-page__navbar" elevation="2">
        <slot name="tabs"></slot>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    additionalInfo: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.header-single-page {
  min-height: 96px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: $white;

  &__top {
    min-height: 56px;
  }
  &__navbar {
    width: 100%;
  }
  &__additional {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
  }
}
.container {
  padding: 12px 12px 0 12px !important;
}
.text-right {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: end;
}
</style>
