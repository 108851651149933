<template>
  <div class="daterange-picker">
    <v-menu offset-y :close-on-content-click="false">
      <template #activator="{ on }">
        <v-btn class="daterange-picker__btn elevation-0" height="34" v-on="on">
          <v-icon class="daterange-picker__ico" left dark>mdi-calendar</v-icon>

          <span v-if="displayData" class="daterange-picker__date">{{ displayData }}</span>

          <v-icon
            v-if="clearable && selectedDates.length"
            class="daterange-picker__clear"
            @click.prevent="clearDates"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </template>

      <v-date-picker
        class="daterange-picker__calendar"
        :value="value"
        :events="selectedRange"
        color="secondary"
        event-color="secondary"
        no-title
        multiple
        @input="onInput"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedDates: [],
    };
  },

  computed: {
    displayData() {
      let data;
      if (this.selectedDates.length) {
        data = moment(this.selectedDates[0]).format('D MMM');
        if (this.selectedDates.length > 1) {
          data += '-' + moment(this.selectedDates[1]).format('D MMM');
        }
      }
      return data;
    },
  },

  watch: {
    value: {
      handler: function(val) {
        this.selectedDates = val;
        this.$emit('change', val);
      },
      immediate: true,
    },
  },

  methods: {
    diff(a, b) {
      return a.filter(v => !b.includes(v));
    },

    onInput(val) {
      let newRange;
      if (val.length > 2) {
        let newDate = this.diff(val, this.selectedDates)[0];
        let currentRange = this.selectedDates.sort();
        newRange = [currentRange[0], newDate];
      } else {
        newRange = val;
      }
      this.selectedDates = newRange.sort();
      this.$emit('input', this.selectedDates);
    },

    selectedRange(date) {
      if (this.selectedDates.length == 2) {
        return moment(date).isBetween(...this.selectedDates);
      }
    },

    clearDates() {
      this.selectedDates = [];
      this.$emit('input', this.selectedDates);
    },
  },
};
</script>

<style lang="scss" scoped>
.daterange-picker {
  &__btn {
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    background: #f4f4f4;
  }
  &__ico {
    font-size: 22px;
    margin-right: 0;
  }
  &__date {
    margin-left: 8px;
    margin-right: 4px;
  }
  &__clear {
    margin-right: -8px;
  }
}
</style>
