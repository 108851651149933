<template>
  <div id="form-project" class="app-form form-project">
    <v-form ref="formProject" v-model="isFormValid" @submit.prevent="submitForm">
      <AppFormSubmit :processing="isProcessing" @submit="submitForm" />

      <v-row>
        <v-col cols="4" class="py-0">
          <h2 class="app-form__title app-form__title--low">Project</h2>
          <div class="app-form__group">
            <label class="app-form__label">Project name *</label>
            <v-text-field
              v-model="project.name"
              :rules="[rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>
        </v-col>
        <v-col cols="5" class="py-0">
          <h2 class="app-form__spacer app-form__spacer--low"></h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Project type</label>
                <v-select
                  v-model="project.project_type_id"
                  :items="projectTypes"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Project status *</label>
                <v-select
                  v-model="project.project_status_id"
                  :items="projectStatuses"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Priority level</label>
                <v-select
                  v-model="project.priority_level_id"
                  :items="priorityLevels"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="projectData" cols="3" class="py-0">
          <h2 class="app-form__spacer  app-form__spacer--low"></h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Created</label>
                <v-text-field
                  v-model="project.created_at"
                  v-bind="componentsProps.simpleTextField"
                  disabled
                />
              </v-col>
              <v-col>
                <label class="app-form__label">By</label>
                <v-text-field
                  v-if="project.creating_user"
                  v-model="project.creating_user.login"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                  disabled
                />
              </v-col>
              <v-col>
                <label class="app-form__label">Project no.</label>
                <v-text-field
                  v-model="project.id"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                  disabled
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="py-0">
          <h2 class="app-form__title">Client</h2>
          <div id="client-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label">Name *</label>
            <v-autocomplete
              v-model="project.client_id"
              :items="clientsList"
              :loading="isClientLoading"
              :search-input.sync="searchClient"
              :rules="[rules.required]"
              no-filter
              attach="#client-search"
              item-value="id"
              item-text="name"
              placeholder="Start typing to Search"
              v-bind="componentsProps.autocomplete"
              :disabled="!!clientData"
              :clearable="!clientData"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
              <template v-slot:append-item>
                <div v-intersect="endClientIntersect" />
              </template>
            </v-autocomplete>
          </div>

          <h2 class="app-form__title">Executive</h2>
          <div
            id="contact-search"
            class="app-form__group app-form__group--autocomplete app-form__group--switch-autocomplete"
          >
            <v-switch v-model="showOnlyHidden" label="Search only in executive for client" />
            <label class="app-form__label">Name *</label>
            <v-autocomplete
              v-model="project.contact_id"
              :items="contactsList"
              :loading="isContactLoading"
              :search-input.sync="searchContact"
              :rules="[rules.required]"
              no-filter
              attach="#contact-search"
              item-value="id"
              :item-text="visibleContactName"
              placeholder="Search contact"
              v-bind="componentsProps.autocomplete"
              clearable
              :menu-props="{ offsetY: true, top: true }"
              @change="getContactData"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
              <template v-slot:append-item>
                <div v-intersect="endContactIntersect" />
              </template>
            </v-autocomplete>
          </div>
          <div class="app-form__group">
            <label class="app-form__label">Job title</label>
            <v-text-field
              v-model="project.contact.company_info.job_title"
              v-bind="componentsProps.simpleTextField"
              disabled
            />
          </div>
          <div class="app-form__group">
            <label class="app-form__label">Company</label>
            <v-text-field
              v-model="project.contact.company_info.company.name"
              v-bind="componentsProps.simpleTextField"
              disabled
            />
          </div>
        </v-col>

        <v-col cols="4" class="py-0">
          <h2 class="app-form__title">Offices</h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Originating</label>
                <v-select
                  v-model="project.origin_office_id"
                  :items="officeList"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Handling</label>
                <v-select
                  v-model="project.handling_office_id"
                  :items="officeList"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <h2 class="app-form__title">Consultants</h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Originating</label>
                <v-select
                  v-model="project.origin_user_id"
                  :items="usersList"
                  item-value="id"
                  item-text="login"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Handling</label>
                <v-select
                  v-model="project.handling_user_id"
                  :items="usersList"
                  item-value="id"
                  item-text="login"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Researcher</label>
                <v-select
                  v-model="project.researching_user_id"
                  :items="usersList"
                  item-value="id"
                  item-text="login"
                  v-bind="componentsProps.select"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <h2 class="app-form__title">Dates</h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Confirmation</label>
                <v-menu
                  ref="menuDateConfirmation"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="project.confirmation_date"
                      v-bind="componentsProps.simpleTextField"
                      clearable
                      readonly
                      class="app-form__menu app-form__menu--datepicker"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="project.confirmation_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateConfirmation.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <label class="app-form__label">Start</label>
                <v-menu
                  ref="menuDateStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="project.start_date"
                      v-bind="componentsProps.simpleTextField"
                      readonly
                      clearable
                      class="app-form__menu app-form__menu--datepicker"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="project.start_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateStart.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <label class="app-form__label">End</label>
                <v-menu
                  ref="menuDateEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="project.end_date"
                      v-bind="componentsProps.simpleTextField"
                      readonly
                      clearable
                      class="app-form__menu app-form__menu--datepicker"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="project.end_date"
                    no-title
                    scrollable
                    @input="$refs.menuDateEnd.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="4" class="py-0">
          <h2 class="app-form__title">
            Hints
            <v-icon
              v-if="project.notes"
              class="app-form__hints ml-4"
              @click="showFullTextDialog(project.notes)"
            >
              mdi-arrow-expand
            </v-icon>
          </h2>
          <v-textarea v-model="project.notes" solo />

          <h2 class="app-form__title">Attachments</h2>
          <div class="app-form__attachments">
            <div class="app-form__add-attachment">
              <label class="app-form__label" for="attachmentInput" style="cursor: pointer;">
                <v-icon>mdi-attachment</v-icon> File upload
                <input
                  id="attachmentInput"
                  name="attachmentInput"
                  type="file"
                  multiple
                  style="display: none;"
                  @input="addAttachments"
                />
              </label>

              <div class="app-form__attachments-list">
                <v-chip
                  v-for="(attachment, index) in project.attachments"
                  :key="attachment.id"
                  class="mr-6 mb-4 py-4"
                  label
                  close
                  close-icon="mdi-close"
                  @click:close="removeAttachment(attachment.id, index)"
                >
                  <span
                    class="app-form__attachment-name"
                    @click="downloadAtachment(attachment.url)"
                  >
                    {{ attachment.name }}
                  </span>
                  <v-icon
                    v-if="isEditForm"
                    class="app-form__attachment-edit ml-3"
                    small
                    @click="editAttachmentName(attachment)"
                  >
                    mdi-pencil
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="isProcessingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="isFullTextDialogVisible"
      :max-width="720"
      content-class="confirm-dialog"
      scrollable
      @keydown.esc="closeFullTextDialog"
      @click:outside="selectedFullText = ''"
    >
      <v-card>
        <v-card-text>
          <v-textarea v-model="selectedFullText" auto-grow flat readonly no-resize solo />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AttachmentNameChange
      :show="isAttachmentNameChangeDialogVisible"
      :attachment="selectedAttachment"
      @close="isAttachmentNameChangeDialogVisible = false"
      @submit="changeAttachmentName"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import utils from '@/helpers/utils';
import externalRules from '@/helpers/validators';
import clientsApi from '@/api/v1/recruiter/clients';
import contactsApi from '@/api/v1/recruiter/contacts';
import projectsApi from '@/api/v1/recruiter/projects';
import usersApi from '@/api/v1/library/users';
import tagsApi from '@/api/v1/library/tags';
import officesApi from '@/api/v1/library/offices';
import AppFormSubmit from '@/components/Common/AppForm/AppFormSubmit.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import AttachmentNameChange from '@/components/Common/AttachmentNameChange.vue';

const componentsProps = customComponentsProps;
const rules = externalRules;
const project = cloneDeep(models.project);
const projectBaseState = cloneDeep(models.project);

export default {
  components: {
    AppFormSubmit,
    ConfirmDialog,
    AttachmentNameChange,
  },

  props: {
    clientData: {
      type: Object,
      required: false,
      default: () => {},
    },
    projectData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      project,
      projectBaseState,
      componentsProps,
      formData: new FormData(),
      isFormValid: true,
      isProcessing: false,
      isProcessingOverlay: false,
      isClientLoading: false,
      isContactLoading: false,
      isFullTextDialogVisible: false,
      selectedFullText: '',
      isAttachmentNameChangeDialogVisible: false,
      selectedAttachment: {},
      rules,
      searchClient: null,
      searchContact: null,
      clientsList: [],
      usersList: [],
      contactsList: [],
      officeList: [],
      projectStatuses: [],
      projectTypes: [],
      priorityLevels: [],
      showOnlyHidden: false,
      apiClientParams: {
        itemsPerPage: 20,
        page: 1,
      },
      apiContactParams: {
        show_hidden: false,
        show_only_hidden: false,
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  computed: {
    isEditForm() {
      return !!this.projectData;
    },
    apiClientParamsChange() {
      return Object.assign({}, this.apiClientParams);
    },
    apiContactParamsChange() {
      return Object.assign({}, this.apiContactParams);
    },
  },

  watch: {
    searchClient() {
      if (
        !this.searchClient ||
        this.searchClient === null ||
        this.searchClient === '' ||
        (this.searchClient && this.searchClient.length < 2)
      )
        return;

      this.debouncedClientSearch();
    },

    searchContact() {
      if (
        !this.searchContact ||
        this.searchContact === null ||
        this.searchContact === '' ||
        (this.searchContact && this.searchContact.length < 2)
      )
        return;

      this.debouncedContactSearch();
    },

    showOnlyHidden() {
      this.apiContactParams.show_hidden = this.showOnlyHidden;
      this.apiContactParams.show_only_hidden = this.showOnlyHidden;
    },

    apiClientParamsChange: {
      handler(newV, oldV) {
        this.getClients(this.apiClientParams, newV.page > oldV.page);
      },
      deep: true,
    },

    apiContactParamsChange: {
      handler(newV, oldV) {
        if (newV.show_only_hidden !== oldV.show_only_hidden) {
          this.getContacts(this.apiContactParams);
        } else {
          this.getContacts(this.apiContactParams, newV.page > oldV.page);
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.projectData) {
      this.project = cloneDeep(this.projectData);
      this.projectBaseState = cloneDeep(this.projectData);
      this.clientsList.push(this.projectData.client);
      this.contactsList.push(this.projectData.contact);
    } else if (this.clientData) {
      this.project = cloneDeep(models.project);
      this.clientsList.push(this.clientData);
      this.project.client_id = this.clientData.id;
    } else {
      this.project = cloneDeep(models.project);
      this.getClients(this.apiClientParams);
      this.getContacts(this.apiContactParams);
    }
    this.getUsers();
    this.getOffices();
    this.getTags('projectStatuses');
    this.getTags('projectTypes');
    this.getTags('priorityLevels');
  },

  methods: {
    getUsers() {
      this.isProcessing = true;
      usersApi
        .index({ active: true })
        .then(resp => {
          if (this.projectData && this.projectData.used_users) {
            this.usersList = [...resp.data.collection, ...this.projectData.used_users];
          } else {
            this.usersList = resp.data.collection;
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getOffices() {
      this.isProcessing = true;
      officesApi
        .index()
        .then(resp => {
          this.officeList = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getClients(params, append) {
      this.isProcessing = true;
      clientsApi
        .index(params)
        .then(resp => {
          if (append) {
            this.clientsList.push(...resp.data.collection);
          } else {
            this.clientsList = resp.data.collection;
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isClientLoading = false;
        });
    },

    getContacts(params, append) {
      this.isProcessing = true;
      contactsApi
        .index(params)
        .then(resp => {
          if (append) {
            this.contactsList.push(...resp.data.collection);
          } else {
            this.contactsList = resp.data.collection;
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isContactLoading = false;
        });
    },

    getTags(type) {
      tagsApi[type]
        .index()
        .then(response => {
          this[type] = response.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getContactData(id) {
      if (id) {
        contactsApi
          .show(id)
          .then(resp => {
            this.project.contact.company_info = resp.data.company_info;
          })
          .catch(err => {
            this.$store.dispatch('showError', err.response.data.errors);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.project.contact.company_info.job_title = '';
        this.project.contact.company_info.company.name = '';
      }
    },

    submitForm() {
      this.$refs.formProject.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        const newProject = utils.trimObj(this.project);

        if (this.projectData) {
          // Edit
          projectsApi
            .update(this.$route.params.id, newProject)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          // Create
          projectsApi
            .create(newProject)
            .then(resp => {
              if (this.project.attachments.length) {
                this.isProcessingOverlay = true;

                projectsApi
                  .createAttachment(resp.data.id, this.formData)
                  .then(() => {
                    this.$emit('success');
                  })
                  .catch(err => {
                    this.$store.dispatch('showError', err.response.data.errors);
                    this.$router.push({ name: 'project-summary', params: { id: resp.data.id } });
                  })
                  .finally(() => {
                    this.isProcessingOverlay = false;
                    this.isProcessing = false;
                  });
              } else {
                this.$emit('success');
              }
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    visibleContactName(item) {
      return `${item.last_name} ${item.first_name} ${item.company_name}`;
    },

    downloadAtachment(url) {
      if (url) window.open(url, '_blank');
    },

    addAttachments(ev) {
      const fileList = ev.target.files;
      let errors = [];
      this.formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].size / 1048576 < 10) {
          this.formData.append('attachments[]', fileList[i]);
          this.project.attachments.push(fileList[i]);
        } else {
          errors.push(`File ${fileList[i].name} is to big`);
        }
      }
      if (errors.length) this.$store.dispatch('showError', errors);

      if (this.projectData) this.submitAttachments(this.formData);
    },

    submitAttachments(attachmentsFormData) {
      this.isProcessingOverlay = true;

      projectsApi
        .createAttachment(this.$route.params.id, attachmentsFormData)
        .then(resp => {
          this.project.attachments = resp.data.attachments;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    removeAttachment(id, index) {
      if (!id) {
        this.project.attachments.splice(index, 1);
      } else {
        this.$refs.confirmDialog
          .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
          .then(confirm => {
            if (confirm) {
              projectsApi
                .deleteAttachment(this.$route.params.id, id)
                .then(() => {
                  this.$emit('success');
                })
                .catch(err => {
                  this.$store.dispatch('showError', err.response.data.errors);
                })
                .finally(() => {
                  this.isProcessing = false;
                });
            }
          });
      }
    },

    editAttachmentName(attachment) {
      this.selectedAttachment = attachment;
      this.isAttachmentNameChangeDialogVisible = true;
    },

    changeAttachmentName(editedAttachment) {
      this.isAttachmentNameChangeDialogVisible = false;
      this.selectedAttachment = {};
      this.isProcessingOverlay = true;

      projectsApi
        .updateAttachment(this.$route.params.id, editedAttachment.id, {
          filename: editedAttachment.filename,
        })
        .then(() => {
          this.$emit('success');
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    showFullTextDialog(text) {
      this.selectedFullText = text;
      this.isFullTextDialogVisible = true;
    },

    closeFullTextDialog() {
      this.isFullTextDialogVisible = false;
    },

    endClientIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.apiClientParams.page += 1;
      }
    },

    endContactIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.apiContactParams.page += 1;
      }
    },

    debouncedClientSearch: debounce(function() {
      this.isClientLoading = true;

      if (this.searchClient) {
        this.apiClientParams = {
          ...this.apiClientParams,
          page: 1,
          search_tags: {
            autocomplete: this.searchClient,
          },
        };
        this.getClients(this.apiClientParams);
      } else {
        this.isClientLoading = false;
      }
    }, 500),

    debouncedContactSearch: debounce(function() {
      this.isContactLoading = true;

      if (this.searchContact) {
        this.apiContactParams = {
          ...this.apiContactParams,
          page: 1,
          search_tags: {
            autocomplete: this.searchContact,
          },
        };
        this.getContacts(this.apiContactParams);
      } else {
        this.isContactLoading = false;
      }
    }, 500),

    isProjectModified() {
      return new Promise(resolve => {
        resolve(!isEqual(this.project, this.projectBaseState));
      });
    },
  },
};
</script>

<style lang="scss">
.form-project {
  margin-top: -20px;

  &.app-form {
    padding-bottom: 110px;
  }

  textarea {
    margin-top: 0px !important;
  }
}
</style>
