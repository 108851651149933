<template>
  <div class="contact-contacs">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="contactsHistoryHeaders"
            :items="contactsHistory"
            :loading="isLoading"
            disable-filtering
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template #item.date="{ item }">
              <template v-if="item.start_datetime">
                {{ getDateFromDatetime(item.start_datetime) }}
              </template>
            </template>

            <template #item.time="{ item }">
              <template v-if="item.start_datetime">
                {{ getTimeFromDatetime(item.start_datetime) }}
              </template>
            </template>

            <template #item.project_name="{ item }">
              <router-link
                v-if="item.project_name"
                :to="{ name: 'project-summary', params: { id: item.project_id } }"
                class="table-cell-link"
              >
                {{ item.project_name }}
              </router-link>
            </template>

            <template #item.client_name="{ item }">
              <router-link
                v-if="item.client_name"
                :to="{ name: 'client-summary', params: { id: item.client_id } }"
                class="table-cell-link"
              >
                {{ item.client_name }}
              </router-link>
            </template>

            <template #item.comment="{ item }">
              <div
                class="py-2 cursor-pointer caption"
                style="min-width: 300px;"
                @click="showFullTextDialog(item.comment)"
              >
                {{ getExcerpt(item.comment, 150) }}
              </div>
            </template>

            <template #item.action="{ item }">
              <v-btn color="primary" icon @click="editEvent(item.id)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="primary" icon @click="deleteEvent(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <app-dialog :show="isEventFormVisible" @close="closeDialog">
      <FormEvent
        v-if="isEventFormVisible"
        :contact-id="$route.params.id"
        :event-id="selectedEvent"
        :form-context="formContext"
        @success="eventEdited"
      />
    </app-dialog>

    <v-dialog
      v-model="isFullTextDialogVisible"
      :max-width="720"
      content-class="confirm-dialog"
      scrollable
      @keydown.esc="closeFullTextDialog"
      @click:outside="selectedFullText = ''"
    >
      <v-card>
        <v-card-text>
          <v-textarea v-model="selectedFullText" flat auto-grow readonly no-resize solo />
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import moment from 'moment';
import eventBus from '@/eventBus';
import eventsApi from '@/api/v1/recruiter/events';
import consts from '@/api/consts';
import AppDialog from '@/components/Common/Dialog.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import FormEvent from '@/components/Recruiter/Calendar/FormEvent.vue';

const contactsHistoryHeaders = consts.contacts.contactsHistoryHeaders;

export default {
  components: {
    AppDialog,
    ConfirmDialog,
    FormEvent,
  },

  data() {
    return {
      isLoading: false,
      isEventFormVisible: false,
      isFullTextDialogVisible: false,
      selectedFullText: '',
      formContext: {
        calendarDate: false,
      },
      contactsHistory: [],
      contactsHistoryHeaders,
      selectedEvent: null,
    };
  },

  computed: {
    currentUserId() {
      return parseInt(this.$store.getters.getUser);
    },
  },

  created() {
    this.getContactHistory();
  },

  mounted() {
    eventBus.$on('update-contact-events', () => {
      this.getContactHistory();
    });
  },

  methods: {
    getContactHistory() {
      this.isLoading = true;

      eventsApi
        .index({ contact_id: this.$route.params.id, from_contact: true })
        .then(resp => {
          this.contactsHistory = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getDateFromDatetime(datetime) {
      return moment(datetime).format('YYYY-MM-DD');
    },

    getTimeFromDatetime(datetime) {
      return moment(datetime).format('HH:mm');
    },

    getExcerpt(str, limit) {
      if (!str) return;
      const fullText = str;
      let shortText = fullText.substr(0, limit);
      if (fullText.length > limit) {
        shortText += '...';
      }
      return shortText;
    },

    showFullTextDialog(text) {
      this.selectedFullText = text;
      this.isFullTextDialogVisible = true;
    },

    closeFullTextDialog() {
      this.isFullTextDialogVisible = false;
    },

    deleteEvent(id) {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Delete' })
        .then(confirm => {
          if (confirm) {
            this.isTooltipVisible = false;

            eventsApi
              .delete(id)
              .then(() => {
                this.$store.dispatch('showSuccess', ['Event deleted']);
                this.getContactHistory();
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    editEvent(id) {
      this.selectedEvent = id;
      this.isEventFormVisible = true;
    },

    eventEdited() {
      this.$store.dispatch('showSuccess', ['Event updated']);
      this.closeDialog();
      this.getContactHistory();
    },

    closeDialog() {
      this.isEventFormVisible = false;
      this.selectedEvent = null;
    },
  },
};
</script>
