<template>
  <div class="contact-show">
    <header-single-page
      :title="displayTitle"
      :additional-info="
        contactData.executive_info && contactData.executive_info.client_id ? 'Executive' : ''
      "
    >
      <template #action>
        <template v-if="isContactSummary">
          <v-btn
            v-shortkey="['ctrl', 'shift', 'a']"
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @shortkey="showAssignForm"
            @click="showAssignForm"
          >
            Assign to project
          </v-btn>
          <v-btn
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @click="showExecutiveAssignForm"
          >
            Assign as executive
          </v-btn>
          <v-btn
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @click="archiveContact"
          >
            Archive
          </v-btn>
          <v-btn
            v-shortkey="['ctrl', 'shift', 'd']"
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @shortkey="deleteContact"
            @click="deleteContact"
          >
            Delete
          </v-btn>
        </template>

        <template v-else>
          <download-button class="mr-8" :download-params="reportParams">
            REPORT
          </download-button>
        </template>

        <template v-if="isContactProjects">
          <v-btn
            v-shortkey="['ctrl', 'shift', 'a']"
            class="mr-3"
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @shortkey="showAssignForm"
            @click="showAssignForm"
          >
            Assign to project
          </v-btn>
        </template>

        <template v-if="isContactHistory">
          <v-btn
            class="mr-3"
            color="secondary"
            elevation="0"
            min-width="112"
            :loading="isProcessing"
            @click="showAddEventForm"
          >
            Add contact event
          </v-btn>
        </template>
      </template>

      <template #tabs>
        <v-tabs height="40" color="primary" slider-color="secondary" slider-size="4" elevation="2">
          <v-tab :to="{ name: 'contact-summary' }">Summary</v-tab>
          <v-tab :to="{ name: 'contact-projects' }">Projects</v-tab>
          <v-tab :to="{ name: 'contact-contact-history' }">Contact history</v-tab>
          <v-tab :to="{ name: 'contact-career-history' }">Career history</v-tab>
        </v-tabs>
      </template>
    </header-single-page>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <app-dialog :show="isAssignWizardVisible" @close="closeDialog">
      <WizardAddCandidate v-if="isAssignWizardVisible" :start-step="3" @success="contactAssigned" />
    </app-dialog>

    <app-dialog :show="isExecutiveAssignFormVisible" @close="closeDialog">
      <FormAssignExecutive
        v-if="isExecutiveAssignFormVisible"
        :executive-data="contactData.executive_info"
        @success="executiveAssigned"
      />
    </app-dialog>

    <app-dialog :show="isAddEventFormVisible" @close="closeDialog">
      <FormEvent
        v-if="isAddEventFormVisible"
        :contact-id="$route.params.id"
        :form-context="formContext"
        @success="eventSubmited"
      />
    </app-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import contactsApi from '@/api/v1/recruiter/contacts';
import HeaderSinglePage from '@/components/Common/HeaderSinglePage.vue';
import AppDialog from '@/components/Common/Dialog.vue';
import WizardAddCandidate from '@/components/Common/WizardAddCandidate/Index.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import DownloadButton from '@/components/Common/DownloadButton.vue';
import FormEvent from '@/components/Recruiter/Calendar/FormEvent.vue';
import FormAssignExecutive from '@/components/Recruiter/Contacts/FormAssignExecutive.vue';

export default {
  components: {
    HeaderSinglePage,
    AppDialog,
    WizardAddCandidate,
    ConfirmDialog,
    DownloadButton,
    FormEvent,
    FormAssignExecutive,
  },

  data() {
    return {
      isProcessing: false,
      isAssignWizardVisible: false,
      isAddEventFormVisible: false,
      isExecutiveAssignFormVisible: false,
      formContext: {
        calendarDate: false,
        owner_id: null,
      },
      contactData: {},
      selectedEvent: null,
    };
  },

  computed: {
    isContactSummary() {
      return this.$route.name === 'contact-summary';
    },

    isContactHistory() {
      return this.$route.name === 'contact-contact-history';
    },

    isContactProjects() {
      return this.$route.name === 'contact-projects';
    },

    displayTitle() {
      return this.contactData.last_name
        ? `${this.contactData.last_name} ${this.contactData.first_name}`
        : '';
    },

    executiveData() {
      return {};
    },

    reportParams() {
      const params = {
        contact_id: this.$route.params.id,
      };
      if (this.$route.name === 'contact-projects') {
        params.projects = true;
      } else if (this.$route.name === 'contact-career-history') {
        params.career = true;
      } else {
        params.events = true;
      }
      return params;
    },
  },

  created() {
    this.getContact();
  },

  methods: {
    getContact() {
      contactsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.dataLoaded = true;
          this.contactData = resp.data;
        })
        .catch(err => {
          this.dataLoaded = true;
          this.$store.dispatch('showError', err.response.data.errors);
        });
    },

    archiveContact() {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to archive?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            contactsApi
              .archiveUser(this.$route.params.id, { archived: true })
              .then(() => {
                this.$store.dispatch('showSuccess', ['User archived']);
                this.$router.push('/contacts');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    deleteContact() {
      const currentContactId = this.$router.history.current.params.id;

      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            contactsApi
              .delete(currentContactId)
              .then(() => {
                this.$router.push('/contacts');
              })
              .catch(err => {
                this.$store.dispatch('showError', err.response.data.errors);
              });
          }
        });
    },

    showAssignForm() {
      this.isProcessing = true;
      contactsApi
        .show(this.$route.params.id)
        .then(resp => {
          this.$store.dispatch('setCandidateCompany', resp.data.company_info.company);

          const candidateContact = {
            id: resp.data.id,
            name: `${resp.data.first_name} ${resp.data.last_name}`,
          };
          this.$store.dispatch('setCandidateContact', candidateContact);

          this.$store.dispatch('clearCandidateAdditionalParams');

          this.isAssignWizardVisible = true;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    showExecutiveAssignForm() {
      this.isExecutiveAssignFormVisible = true;
    },

    showAddEventForm() {
      this.formContext.owner_id = parseInt(this.$store.state.user.userId);
      this.isAddEventFormVisible = true;
    },

    contactAssigned() {
      this.$store.dispatch('showSuccess', ['Contact assigned']);
      this.closeDialog();
    },

    executiveAssigned() {
      this.$store.dispatch('showSuccess', ['Contact assigned as Executive']);
      this.closeDialog();
      this.getContact();
    },

    eventSubmited() {
      this.$store.dispatch('showSuccess', ['Event added']);
      eventBus.$emit('update-contact-events');
      this.closeDialog();
    },

    closeDialog() {
      this.isAssignWizardVisible = false;
      this.isAddEventFormVisible = false;
      this.isExecutiveAssignFormVisible = false;
      this.selectedEvent = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-show {
  background: inherit;
}
</style>
