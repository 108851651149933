<template>
  <div class="app-form form-contact">
    <v-form ref="formContact" v-model="isFormValid" @submit.prevent="submitForm">
      <AppFormSubmit :processing="isProcessing" @submit="submitForm" />

      <v-row>
        <v-col cols="12 py-0">
          <h2 class="app-form__title app-form__title--low">Personal information</h2>
          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">Last name *</label>
                <v-text-field
                  v-model="contact.last_name"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                />
              </v-col>
              <v-col>
                <label class="app-form__label">First name *</label>
                <v-text-field
                  v-model="contact.first_name"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                />
              </v-col>
              <v-col>
                <label class="app-form__label">Job title *</label>
                <v-text-field
                  v-model="contact.job_title"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                />
              </v-col>
            </v-row>
            <v-row class="app-form__multiple-row">
              <v-col>
                <label class="app-form__label">City *</label>
                <v-text-field
                  v-model="contact.city"
                  :rules="[rules.required]"
                  v-bind="componentsProps.simpleTextField"
                />
              </v-col>
              <v-col>
                <label class="app-form__label">Nationality *</label>
                <v-autocomplete
                  v-model="contact.nationality_id"
                  :items="nationalities"
                  item-value="id"
                  item-text="name"
                  :rules="[rules.required]"
                  v-bind="componentsProps.autocomplete"
                />
              </v-col>
              <v-col>
                <label class="app-form__label">RODO</label>
                <v-select
                  v-model="contact.gdpr_status_id"
                  :items="gdprStatuses"
                  item-value="id"
                  item-text="name"
                  v-bind="componentsProps.select"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="app-form__group">
            <v-row class="app-form__multiple-row">
              <v-col
                v-for="(phoneNumber, index) in contact.phone_numbers"
                :key="index"
                class="col-lg5-custom"
              >
                <label class="app-form__label">Phone number {{ index + 1 }}</label>
                <v-text-field
                  v-model="contact.phone_numbers[index].phone_number"
                  v-bind="componentsProps.simpleTextField"
                  :append-icon="index !== 0 || contact.phone_numbers.length > 1 ? 'mdi-close' : ''"
                  @click:append="deletePhoneNumber(index)"
                />
              </v-col>
              <v-col class="app-form__add-col col-lg5-custom">
                <v-btn class="app-form__add-btn" color="#fff" depressed @click="addPhoneNumber">
                  <v-icon dark>mdi-plus</v-icon>
                  <template>
                    Add phone number
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="app-form__group">
            <v-row class="app-form__multiple-row mb-3">
              <v-col v-for="(email, index) in contact.emails" :key="index" class="col-lg5-custom">
                <label class="app-form__label">Email {{ index + 1 }}</label>
                <v-text-field
                  v-model="contact.emails[index].email"
                  v-bind="componentsProps.simpleTextField"
                  :append-icon="index !== 0 || contact.emails.length > 1 ? 'mdi-close' : ''"
                  @click:append="deleteEmail(index)"
                />
              </v-col>
              <v-col class="app-form__add-col col-lg5-custom">
                <v-btn class="app-form__add-btn" color="#fff" depressed @click="addEmail">
                  <v-icon dark>mdi-plus</v-icon>
                  <template>
                    Add email
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="5" class="py-0">
          <div class="app-form__group">
            <template v-if="contact.languages.length">
              <v-row
                v-for="(language, index) in contact.languages"
                :key="index"
                class="app-form__multiple-row mb-4"
              >
                <v-col cols="5">
                  <label class="app-form__label">Language {{ index + 1 }}</label>
                  <v-select
                    :id="`lang_${index}`"
                    v-model="contact.languages[index].language_id"
                    :items="languages"
                    :prepend-icon="contact.languages.length > 1 ? 'mdi-close' : ''"
                    item-value="id"
                    item-text="name"
                    :menu-props="{ 'offset-y': true }"
                    v-bind="componentsProps.select"
                    @click:prepend="deleteLanguage(index)"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <label class="app-form__label">Level</label>
                  <v-select
                    :id="`lang_lvl_${index}`"
                    v-model="contact.languages[index].language_level_id"
                    :items="languageLevels"
                    item-value="id"
                    item-text="name"
                    :menu-props="{ 'offset-y': true }"
                    v-bind="componentsProps.select"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="index + 1 === contact.languages.length"
                  cols="1"
                  class="app-form__add-col"
                >
                  <v-btn class="app-form__add-btn" color="#fff" depressed @click="addLanguage">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row class="app-form__multiple-row mb-4">
                <v-col cols="5">
                  <v-btn class="app-form__add-btn mt-1" color="#fff" depressed @click="addLanguage">
                    <v-icon dark>mdi-plus</v-icon>
                    <template>
                      Add language
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-col>
      </v-row>

      <v-row class="app-form__multiple-row">
        <v-col cols="5">
          <h2 class="app-form__title title">Career</h2>
          <div id="company-search" class="app-form__group app-form__group--autocomplete">
            <label class="app-form__label"
              >Company
              <template v-if="!isEditForm">
                *
              </template>
            </label>
            <v-text-field
              v-if="isEditForm"
              :value="contact.company_info.company ? contact.company_info.company.name : ''"
              :disabled="isEditForm"
              v-bind="componentsProps.simpleTextField"
            />
            <v-autocomplete
              v-else
              v-model="contact.company_info.company.id"
              :items="companies"
              :loading="isLoading"
              :disabled="$route.name === 'company-contacts'"
              :search-input.sync="search"
              :rules="[rules.required]"
              no-filter
              attach="#company-search"
              item-value="id"
              item-text="name"
              label="Select company"
              v-bind="componentsProps.autocomplete"
              clearable
              @clear="getCompanies"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  color="secondary"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>
            </v-autocomplete>
          </div>
          <div class="app-form__group">
            <label class="app-form__label">
              Job title
              <template v-if="!isEditForm">
                *
              </template>
            </label>
            <v-text-field
              v-model="contact.company_info.job_title"
              :disabled="isEditForm"
              :rules="isEditForm ? [] : [rules.required]"
              v-bind="componentsProps.simpleTextField"
            />
          </div>
          <div class="app-form__group">
            <label class="app-form__label">Function</label>
            <v-select
              v-model="contact.company_info.job_description_id"
              :items="fcCodes"
              item-value="id"
              item-text="function"
              label="Select function"
              v-bind="componentsProps.select"
              append-icon
              disabled
            ></v-select>
          </div>
          <div class="app-form__group">
            <label class="app-form__label"
              >FC Code
              <template v-if="!isEditForm">
                *
              </template>
            </label>
            <v-autocomplete
              v-model="contact.company_info.job_description_id"
              :items="fcCodes"
              :disabled="isEditForm"
              :rules="isEditForm ? [] : [rules.required]"
              item-value="id"
              :item-text="visibleFcCode"
              label="Select code"
              v-bind="componentsProps.select"
            />
          </div>

          <div class="app-form__group">
            <v-row>
              <v-col>
                <label class="app-form__label">
                  From
                  <template v-if="!isEditForm">
                    *
                  </template>
                </label>
                <v-menu
                  ref="menuDateFrom"
                  :close-on-content-click="false"
                  :disabled="isEditForm"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="contact.company_info.from"
                      :disabled="isEditForm"
                      :rules="isEditForm ? [] : [rules.required]"
                      v-bind="componentsProps.simpleTextField"
                      readonly
                      class="app-form__date"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contact.company_info.from"
                    no-title
                    scrollable
                    @input="$refs.menuDateFrom.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="contact.company_info.to">
                <label class="app-form__label">To</label>
                <v-menu
                  ref="menuDateTo"
                  :close-on-content-click="false"
                  :disabled="isEditForm"
                  transition="scale-transition"
                  full-width
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="contact.company_info.to"
                      :disabled="isEditForm"
                      v-bind="componentsProps.simpleTextField"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contact.company_info.to"
                    no-title
                    scrollable
                    @input="$refs.menuDateTo.save(true)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div v-if="isEditForm">
            <v-btn
              color="secondary"
              :loading="isProcessing"
              min-width="112"
              elevation="0"
              class="app-form__submit"
              @click="addCareerEntry"
            >
              Add current career entry
            </v-btn>
          </div>
        </v-col>
        <v-col cols="5" offset="1">
          <template v-if="contactData">
            <h2 class="app-form__title title">Background information</h2>
            <div class="app-form__group">
              <v-row class="app-form__multiple-row">
                <v-col>
                  <label class="app-form__label">Created</label>
                  <v-text-field
                    :value="contact.created_at | displayDate('YYYY-MM-DD')"
                    v-bind="componentsProps.simpleTextField"
                    disabled
                  />
                </v-col>
                <v-col>
                  <label class="app-form__label">By</label>
                  <v-text-field
                    v-model="contact.created_by.login"
                    v-bind="componentsProps.simpleTextField"
                    disabled
                  />
                </v-col>
                <v-col v-if="contact.updated_by">
                  <label class="app-form__label">Last update by</label>
                  <v-text-field
                    v-model="contact.updated_by.login"
                    v-bind="componentsProps.simpleTextField"
                    disabled
                  />
                </v-col>
              </v-row>
            </div>
          </template>

          <h2 class="app-form__title title">Attachments</h2>
          <div class="app-form__attachments">
            <div class="app-form__add-attachment">
              <label class="app-form__label" for="attachmentInput" style="cursor: pointer;">
                <v-icon>mdi-attachment</v-icon> File upload
                <input
                  id="attachmentInput"
                  name="attachmentInput"
                  type="file"
                  multiple
                  style="display: none;"
                  @input="addAttachments"
                />
              </label>

              <div class="app-form__attachments-list">
                <v-chip
                  v-for="(attachment, index) in contact.attachments"
                  :key="attachment.id"
                  class="mr-6 mb-4 py-4"
                  label
                  close
                  close-icon="mdi-close"
                  @click:close="removeAttachment(attachment.id, index)"
                >
                  <span
                    class="app-form__attachment-name"
                    @click="downloadAtachment(attachment.url)"
                  >
                    {{ attachment.name }}
                  </span>
                  <v-icon
                    v-if="isEditForm"
                    class="app-form__attachment-edit ml-3"
                    small
                    @click="editAttachmentName(attachment)"
                  >
                    mdi-pencil
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </div>

          <h2 class="app-form__title title">
            Hints
            <v-icon
              v-if="contact.notes"
              class="app-form__hints ml-4"
              @click="showFullTextDialog(contact.notes)"
            >
              mdi-arrow-expand
            </v-icon>
          </h2>
          <v-textarea v-model="contact.notes" solo />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-0">
          <h2 class="app-form__title">Contact status</h2>
          <div class="app-form__group">
            <v-row>
              <v-col>
                <label class="app-form__label">Placed by</label>
                <v-select
                  v-model="contact.placed_by_users_ids"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="[componentsProps.multipleSelect]"
                  hide-details
                  class="app-form__multiselect"
                ></v-select>
              </v-col>

              <v-col>
                <label class="app-form__label">Presented by</label>
                <v-select
                  v-model="contact.presented_by_users_ids"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="[componentsProps.multipleSelect]"
                  hide-details
                  class="app-form__multiselect"
                ></v-select>
              </v-col>

              <v-col>
                <label class="app-form__label">Business D.T.</label>
                <v-select
                  v-model="contact.business_dt_users_ids"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="[componentsProps.multipleSelect]"
                  hide-details
                  class="app-form__multiselect"
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="app-form__group">
            <v-row>
              <v-col>
                <label class="app-form__label">Interviewed by</label>
                <v-select
                  v-model="contact.interviewed_by_users_ids"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="[componentsProps.multipleSelect]"
                  hide-details
                  class="app-form__multiselect"
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Source of</label>
                <v-select
                  v-model="contact.source_of_user_id"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="componentsProps.select"
                  hide-details
                ></v-select>
              </v-col>
              <v-col>
                <label class="app-form__label">Client of</label>
                <v-select
                  v-model="contact.client_of_user_id"
                  :items="users"
                  item-value="id"
                  item-text="login"
                  label="Select users"
                  v-bind="componentsProps.select"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="isProcessingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="isFullTextDialogVisible"
      :max-width="720"
      content-class="confirm-dialog"
      scrollable
      @keydown.esc="isFullTextDialogVisible = false"
      @click:outside="selectedFullText = ''"
    >
      <v-card>
        <v-card-text>
          <v-textarea v-model="selectedFullText" auto-grow flat readonly no-resize solo />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AttachmentNameChange
      :show="isAttachmentNameChangeDialogVisible"
      :attachment="selectedAttachment"
      @close="isAttachmentNameChangeDialogVisible = false"
      @submit="changeAttachmentName"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import customComponentsProps from '@/helpers/customComponentsProps';
import models from '@/helpers/models';
import utils from '@/helpers/utils';
import usersApi from '@/api/v1/library/users';
import tagsApi from '@/api/v1/library/tags';
import jobDescriptionsApi from '@/api/v1/library/jobDescriptions';
import companiesApi from '@/api/v1/recruiter/companies';
import contactsApi from '@/api/v1/recruiter/contacts';
import externalRules from '@/helpers/validators';
import AppFormSubmit from '@/components/Common/AppForm/AppFormSubmit.vue';
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';
import AttachmentNameChange from '@/components/Common/AttachmentNameChange.vue';

const componentsProps = customComponentsProps;
const contact = cloneDeep(models.contact);
const contactBaseState = cloneDeep(models.contact);

export default {
  components: {
    AppFormSubmit,
    ConfirmDialog,
    AttachmentNameChange,
  },

  props: {
    contactData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      contact,
      contactBaseState,
      componentsProps,
      isFormValid: true,
      isLoading: false,
      isProcessing: false,
      isProcessingOverlay: false,
      isFullTextDialogVisible: false,
      selectedFullText: '',
      isAttachmentNameChangeDialogVisible: false,
      selectedAttachment: {},
      rules: externalRules,
      search: null,
      companies: [],
      users: [],
      gdprStatuses: [],
      nationalities: [],
      languages: [],
      languageLevels: [],
      fcCodes: [],
      formData: new FormData(),
      attachments: [],
    };
  },

  computed: {
    isEditForm() {
      return !!this.contactData;
    },
  },

  watch: {
    search() {
      if (
        !this.search ||
        this.search === null ||
        this.search === '' ||
        (this.search && this.search.length < 2)
      )
        return;

      this.debouncedSearch();
    },

    'contact.job_title'(val) {
      if (!this.isEditForm) {
        this.contact.company_info.job_title = val;
      }
    },
  },

  created() {
    this.getUsers();
    this.getFcCodes();
    this.getTags('nationalities');
    this.getTags('languages');
    this.getTags('languageLevels');
    this.getTags('gdprStatuses');

    if (this.contactData) {
      this.contact = cloneDeep(this.contactData);
      this.contactBaseState = cloneDeep(this.contactData);
      this.companies.push(this.contactData.company_info.company);
    } else {
      this.contact = cloneDeep(models.contact);
      this.getCompanies();
    }
  },

  mounted() {
    if (this.$route.name === 'company-contacts') {
      this.contact.company_info.company.id = parseInt(this.$route.params.id);
    }
  },

  methods: {
    getUsers() {
      this.isProcessing = true;
      usersApi
        .index({ active: true })
        .then(resp => {
          if (this.contactData && this.contactData.used_users) {
            this.users = [...resp.data.collection, ...this.contactData.used_users];
          } else {
            this.users = resp.data.collection;
          }
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getCompanies(params) {
      this.isProcessing = true;
      companiesApi
        .index(params)
        .then(resp => {
          this.companies = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
          this.isLoading = false;
        });
    },

    getFcCodes() {
      this.isProcessing = true;
      jobDescriptionsApi
        .index()
        .then(resp => {
          this.fcCodes = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getTags(type) {
      this.isProcessing = true;
      tagsApi[type]
        .index()
        .then(response => {
          this[type] = response.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    submitForm() {
      this.$refs.formContact.validate();

      if (this.isFormValid) {
        this.isProcessing = true;

        const newContact = utils.trimObj(this.contact);

        if (this.contactData) {
          // Edit
          contactsApi
            .update(this.$route.params.id, newContact)
            .then(() => {
              this.$emit('success');
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        } else {
          // Create
          contactsApi
            .create(newContact)
            .then(resp => {
              if (this.contact.attachments.length) {
                this.isProcessingOverlay = true;

                contactsApi
                  .createAttachment(resp.data.id, this.formData)
                  .then(() => {
                    this.$emit('success');
                  })
                  .catch(err => {
                    this.$store.dispatch('showError', err.response.data.errors);
                    this.$router.push({ name: 'contact-summary', params: { id: resp.data.id } });
                  })
                  .finally(() => {
                    this.isProcessingOverlay = false;
                    this.isProcessing = false;
                  });
              } else {
                this.$emit('success');
              }
            })
            .catch(err => {
              this.$store.dispatch('showError', err.response.data.errors);
            })
            .finally(() => {
              this.isProcessing = false;
            });
        }
      } else {
        this.$store.dispatch('showError', ['Form is invalid']);
      }
    },

    addPhoneNumber() {
      this.contact.phone_numbers.push({ phone_number: '' });
    },
    deletePhoneNumber(index) {
      this.contact.phone_numbers.splice(index, 1);
    },

    addEmail() {
      this.contact.emails.push({ email: '' });
    },
    deleteEmail(index) {
      this.contact.emails.splice(index, 1);
    },

    addLanguage() {
      this.contact.languages.push({
        language_id: '',
        language_level_id: '',
      });
    },
    deleteLanguage(index) {
      this.contact.languages.splice(index, 1);
    },

    addCareerEntry() {
      this.$emit('addCareer');
    },

    downloadAtachment(url) {
      if (url) window.open(url, '_blank');
    },

    addAttachments(ev) {
      const fileList = ev.target.files;
      let errors = [];
      this.formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].size / 1048576 < 10) {
          this.formData.append('attachments[]', fileList[i]);
          this.contact.attachments.push(fileList[i]);
        } else {
          errors.push(`File ${fileList[i].name} is to big`);
        }
      }
      if (errors.length) this.$store.dispatch('showError', errors);

      if (this.contactData) this.submitAttachments(this.formData);
    },

    submitAttachments(attachmentsFormData) {
      this.isProcessingOverlay = true;

      contactsApi
        .createAttachment(this.$route.params.id, attachmentsFormData)
        .then(resp => {
          this.contact.attachments = resp.data.attachments;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    removeAttachment(id, index) {
      if (!id) {
        this.contact.attachments.splice(index, 1);
      } else {
        this.$refs.confirmDialog
          .open({ message: 'Are you sure you want to delete?', confirmButtonText: 'Yes' })
          .then(confirm => {
            if (confirm) {
              contactsApi
                .deleteAttachment(this.$route.params.id, id)
                .then(() => {
                  this.$emit('success');
                })
                .catch(err => {
                  this.$store.dispatch('showError', err.response.data.errors);
                })
                .finally(() => {
                  this.isProcessing = false;
                });
            }
          });
      }
    },

    editAttachmentName(attachment) {
      this.selectedAttachment = attachment;
      this.isAttachmentNameChangeDialogVisible = true;
    },

    changeAttachmentName(editedAttachment) {
      this.isAttachmentNameChangeDialogVisible = false;
      this.selectedAttachment = {};
      this.isProcessingOverlay = true;

      contactsApi
        .updateAttachment(this.$route.params.id, editedAttachment.id, {
          filename: editedAttachment.filename,
        })
        .then(() => {
          this.$emit('success');
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessingOverlay = false;
        });
    },

    debouncedSearch: debounce(function() {
      this.isLoading = true;

      if (this.search) {
        this.getCompanies({ 'search_tags[autocomplete]': this.search });
      } else {
        this.isLoading = false;
      }
    }, 500),

    visibleFcCode(item) {
      return `${item.code} ${item.function}`;
    },

    showFullTextDialog(text) {
      this.selectedFullText = text;
      this.isFullTextDialogVisible = true;
    },

    isContactModified() {
      return new Promise(resolve => {
        resolve(!isEqual(this.contact, this.contactBaseState));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-contact {
  margin-top: -20px;
}
</style>
