<template>
  <div class="project-index-active">
    <v-container fluid>
      <v-row>
        <v-col>
          <FiltersBar
            :filters-list-params="searchParams"
            :all-filters="allFilters"
            @search="getProjects"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="projectsHeaders"
            :items="projects"
            :options.sync="pagination"
            :server-items-length="totals"
            :loading="isLoading"
            :multi-sort="false"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 25, 50, 100],
            }"
            class="elevation-1"
            @update:page="debounceGetProjects"
            @update:items-per-page="debounceGetProjects"
            @update:sort-by="getProjects"
            @update:sort-desc="getProjects"
          >
            <template v-slot:footer.page-text>
              <TableFooterCurrentPage :pagination="pagination" :totals="totals" />
            </template>
            <template #item.created_at="{ value }">
              {{ value | displayDate('YYYY-MM-DD') }}
            </template>

            <template #item.id="{ value }">
              <router-link
                :to="{ name: 'project-summary', params: { id: value } }"
                class="table-cell-link"
              >
                {{ value }}
              </router-link>
            </template>

            <template #item.name="{ item }">
              <router-link
                :to="{ name: 'project-summary', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.name }}
              </router-link>
            </template>

            <template #item.client_name="{ item }">
              <router-link
                :to="{ name: 'client-summary', params: { id: item.client_id } }"
                class="table-cell-link"
              >
                {{ item.client_name }}
              </router-link>
            </template>

            <template #item.target_count="{ item }">
              <router-link
                :to="{ name: 'project-candidates', params: { id: item.id } }"
                class="table-cell-link"
              >
                {{ item.target_count }}
              </router-link>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import eventBus from '@/eventBus';
import consts from '@/api/consts';
import projectsApi from '@/api/v1/recruiter/projects';
import tagsApi from '@/api/v1/library/tags';
import FiltersBar from '@/components/Common/Filters/Index.vue';
import TableFooterCurrentPage from '@/components/Recruiter/TableFooterCurrentPage.vue';

const projectsHeaders = consts.projects.projectsHeaders;

const baseSearchParams = {
  name: {
    key: 'name',
    displayName: 'Name',
    hidden: false,
  },
  project_type_id: {
    key: 'project_type_id',
    displayName: 'Project Type',
    hidden: false,
    autocomplete: true,
    data: [],
  },
  priority_level_id: {
    key: 'priority_level_id',
    displayName: 'Priority level',
    hidden: false,
    autocomplete: true,
    data: [],
  },
  client_name: {
    key: 'client_name',
    displayName: 'Client name',
    hidden: false,
  },
  origin_user_name: {
    key: 'origin_user_name',
    displayName: 'Origin user',
    hidden: false,
  },
  researching_user_name: {
    key: 'researching_user_name',
    displayName: 'Reasearcher',
    hidden: false,
  },
};

export default {
  components: {
    FiltersBar,
    TableFooterCurrentPage,
  },

  props: {
    statusId: {
      type: [String, Number],
      default: '1',
    },
  },

  data() {
    return {
      isLoading: false,
      optionsProjectTypes: [],
      optionsPriorityLevels: [],
      projectsHeaders,
      projects: [],
      searchParams: JSON.parse(JSON.stringify(baseSearchParams)),
    };
  },

  computed: {
    pagination: {
      get() {
        return this.$store.state.projects.search.pagination;
      },
      set(val) {
        this.$store.dispatch('setProjectsPagination', val);
      },
    },

    totals() {
      return this.$store.state.projects.search.totals;
    },

    selectedSearchTags() {
      return this.$store.state.projects.search.search_tags;
    },
    allFilters() {
      return this.$store.state.projects.search || {};
    },
  },

  watch: {
    statusId() {
      this.searchParams = JSON.parse(JSON.stringify(baseSearchParams));
      this.getProjects();
    },
  },

  mounted() {
    this.getProjects();
    eventBus.$on('update-projects', () => {
      this.getProjects();
    });
  },

  created() {
    this.getProjectTypes();
    this.getPriorityLevels();
    this.$store.subscribe(mutation => {
      if (mutation.type === 'CHANGE_PROJECTS_DATE_RANGE') {
        this.getProjects();
      }
    });
  },

  methods: {
    debounceGetProjects: debounce(function() {
      this.getProjects();
    }, 500),
    getProjects(filters) {
      this.isLoading = true;
      if (filters) this.$store.dispatch('setProjectsFilters', filters);

      if (typeof filters !== 'undefined') this.$store.dispatch('setProjectsPage', 1);

      let params = this.$store.getters.getProjectsSearchParams;
      params.project_status_id = this.statusId;

      projectsApi
        .index(params)
        .then(resp => {
          this.projects = resp.data.collection;
          this.$store.dispatch('setProjectsTotals', resp.data.pagination.itemsLength);
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.dataLoaded = true;
          this.isLoading = false;
        });
    },

    getProjectTypes() {
      this.isProcessing = true;
      tagsApi.projectTypes
        .index()
        .then(resp => {
          this.searchParams.project_type_id.data = resp.data;
          this.optionsProjectTypes = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getPriorityLevels() {
      this.isProcessing = true;
      tagsApi.priorityLevels
        .index()
        .then(resp => {
          this.searchParams.priority_level_id.data = resp.data;
          this.optionsPriorityLevels = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    goToProject(project) {
      this.$router.push({ name: 'project-summary', params: { id: project.id } });
    },
  },
};
</script>
