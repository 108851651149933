<template>
  <div class="calendar-sidebar">
    <div class="calendar-sidebar__datepicker-container d-print-none">
      <v-date-picker
        :value="calendarDateFormatted"
        :weekday-format="getDay"
        :events="eventsDates"
        event-color="secondary"
        header-color="white"
        append-icon="mdi-delete"
        first-day-of-week="1"
        color="secondary"
        width="260px"
        locale="en"
        no-title
        @change="setDate"
        @update:picker-date="getEvents"
      />

      <v-btn
        class="calendar-sidebar__add-floating"
        small
        fab
        dark
        color="secondary"
        @click="addEvent"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <div class="calendar-sidebar__actions">
      <template v-if="isCurrentCalendar">
        <v-select
          :value="currentUserId"
          :items="users"
          item-value="id"
          item-text="login"
          label="Select user"
          class="mb-4"
          v-bind="[componentsProps.sidebarSelect]"
          @change="selectUser"
        ></v-select>
        <v-btn
          v-shortkey="['ctrl', 'shift', 'a']"
          color="secondary"
          height="36"
          width="100%"
          class="calendar-sidebar__btn"
          @click="addEvent"
          @shortkey="addEvent"
        >
          Add new event
        </v-btn>
      </template>

      <template v-else>
        <v-select
          :value="currentPlaceId"
          :items="places"
          item-value="id"
          item-text="name"
          label="Select room"
          class="mb-4"
          v-bind="[componentsProps.sidebarSelect]"
          @change="selectPlace"
        ></v-select>
        <v-btn
          v-shortkey="['ctrl', 'shift', 'a']"
          color="secondary"
          height="36"
          width="100%"
          class="calendar-sidebar__btn"
          @click="bookPlace"
          @shortkey="bookPlace"
        >
          Book place
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import componentsProps from '@/helpers/customComponentsProps';
import tagsApi from '@/api/v1/library/tags';
import usersApi from '@/api/v1/library/users';
import eventsApi from '@/api/v1/recruiter/events';

const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export default {
  data() {
    return {
      componentsProps,
      users: [],
      places: [],
      eventsDates: null,
    };
  },

  computed: {
    isCurrentCalendar() {
      return this.$route.name === 'calendar-current';
    },
    calendarDate() {
      return this.$store.getters.getDate;
    },
    calendarEventsMonth() {
      return this.$store.getters.getEventsMonth;
    },
    currentUserId() {
      return parseInt(this.$store.getters.getUser);
    },
    currentPlaceId() {
      return this.$store.getters.getPlace;
    },
    calendarDateFormatted() {
      return this.calendarDate.toISOString().substr(0, 10);
    },
  },

  watch: {
    $route() {
      this.getEvents();
    },
    currentUserId() {
      this.getEvents();
    },
    currentPlaceId() {
      this.getEvents();
    },
  },

  mounted() {
    this.eventsDates = [...Array(6)].map(() => {
      const day = Math.floor(Math.random() * 30);
      const d = new Date();
      d.setDate(day);
      return d.toISOString().substr(0, 10);
    });
  },

  created() {
    this.getUsers();
    this.getPlaces();
  },

  methods: {
    getUsers() {
      this.isProcessing = true;

      usersApi
        .index({ active: true })
        .then(resp => {
          this.users = resp.data.collection;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getPlaces() {
      this.isProcessing = true;

      tagsApi.places
        .index()
        .then(resp => {
          this.places = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getDay(date) {
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    setDate(date) {
      this.$store.dispatch('setExactDate', date);
    },
    setEventsMonth(val) {
      this.$store.dispatch('setEventsMonth', val);
    },
    addEvent() {
      this.$emit('add', { type: 'user', id: this.currentUserId });
    },
    bookPlace() {
      this.$emit('add', { type: 'place', id: this.currentPlaceId });
    },
    selectUser(id) {
      this.$store.dispatch('selectUser', id);
    },
    selectPlace(id) {
      this.$store.dispatch('selectPlace', id);
    },

    getEvents(val) {
      if (val) this.setEventsMonth(val);

      const eventsParams = {
        date: this.calendarEventsMonth,
      };

      if (this.$route.name === 'calendar-current') {
        eventsParams.user_id = this.currentUserId;
      } else if (this.$route.name === 'calendar-place' && this.currentPlaceId) {
        eventsParams.place_id = this.currentPlaceId;
      }

      eventsApi
        .checkEvents(eventsParams)
        .then(resp => {
          this.eventsDates = resp.data;
        })
        .catch(err => {
          this.$store.dispatch('showError', err.response.data.errors);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss">
.calendar-sidebar {
  text-align: center;
  @media (min-width: 960px) {
    background-color: $primary;
    min-height: calc(100vh - 54px);
    padding-top: 0px;
  }

  &__datepicker-container {
    background-color: $primary;
    margin: 24px 24px 10px 24px;
    padding-bottom: 15px;
    padding-top: 10px;
    position: relative;
    @media (min-width: 960px) {
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__add-floating {
    position: absolute;
    bottom: -15px;
    right: 20px;
    @media (min-width: 960px) {
      display: none;
    }
  }

  &__actions {
    padding: 24px 60px;
    text-align: center;
    @media (min-width: 960px) {
      padding: 24px 40px;
    }
  }

  &__btn {
    display: none;
    @media (min-width: 960px) {
      display: inline-flex;
    }
  }

  .v-icon.v-icon {
    color: $white;
  }
  .v-text-field.v-text-field--solo .v-label {
    color: $white;
    text-align: center;
    width: 100%;
  }
  .v-select__selection--comma {
    margin: 7px 0px 7px 8px;
    color: $white;
    width: 100%;
    justify-content: center;
  }
  .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    border-radius: 10px;
  }

  .v-picker__body {
    background: $primary !important;
  }
  .v-btn {
    color: $white;
  }
  .theme--light.v-date-picker-header
    .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
    button:not(:hover):not(:focus) {
    color: $white;
  }
  .theme--light.v-date-picker-table th,
  .theme--light.v-date-picker-table .v-date-picker-table--date__week {
    color: $white;
    font-weight: 700 !important;
  }
}
.v-date-picker-table--date .v-date-picker-table__events {
  bottom: 0;
  left: 8px !important;
  width: 32px;
  height: 32px;
}
</style>
