import utils from '@/helpers/utils';

export default {
  state: {
    totals: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
    search: {
      search_tags: [],
      search_text: '',
      start_date: '',
      end_date: '',
    },
  },
  mutations: {
    CHANGE_CONTACTS_SEARCH_TAGS(state, payload) {
      state.search.search_tags = payload;
    },
    CHANGE_CONTACTS_TOTALS(state, payload) {
      state.totals = payload;
    },
    CHANGE_CONTACTS_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    CHANGE_CONTACTS_FILTERS(state, payload) {
      Object.keys(payload).forEach(key => {
        state.search[key] = payload[key];
      });
    },
  },
  actions: {
    setContactsSearchTags(context, payload) {
      context.commit('CHANGE_CONTACTS_SEARCH_TAGS', payload);
    },
    setContactsTotals(context, payload) {
      context.commit('CHANGE_CONTACTS_TOTALS', payload);
    },
    setContactsPagination(context, payload) {
      context.commit('CHANGE_CONTACTS_PAGINATION', payload);
    },
    setContactsFilters(context, payload = {}) {
      context.commit('CHANGE_CONTACTS_FILTERS', payload);
    },
    setContactsPage(context, payload) {
      context.commit('CHANGE_CONTACTS_PAGINATION', {
        ...context.state.pagination,
        page: payload,
      });
    },
  },
  getters: {
    getContactsSearchParams(state) {
      const params = {
        ...utils.getSearchParams(state.search),
        ...state.pagination,
      };
      if (state.pagination.sortBy) {
        params.order = state.pagination.sortBy[0];
        params.direction = state.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      return params;
    },
  },
};
