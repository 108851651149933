<template>
  <div class="navbar">
    <v-app-bar class="navbar__top" elevation="0" height="54" color="primary" app>
      <v-toolbar-title class="headline d-none d-md-block mr-12">
        <span class="display-1 text_reversed--text font-weight-bold">Signium</span>
      </v-toolbar-title>

      <v-toolbar-items>
        <v-tabs
          height="54"
          background-color="primary"
          color="white"
          slider-color="secondary"
          slider-size="4"
        >
          <v-tab :to="{ name: 'library-project' }">Library</v-tab>
          <v-tab :to="{ name: 'calendar-index' }">Recruiter Panel</v-tab>
        </v-tabs>
      </v-toolbar-items>

      <div class="flex-grow-1 d-none d-md-block"></div>

      <v-btn icon @click="logout">
        <v-icon color="white">mdi-arrow-expand-right</v-icon>
      </v-btn>
    </v-app-bar>

    <v-app-bar class="navbar__alt py-0 px-4 elevation-2" height="54" color="white" app>
      <v-tabs
        height="54"
        background-color="white"
        color="primary"
        slider-color="secondary"
        slider-size="4"
      >
        <v-tab
          v-shortkey="['ctrl', 'alt', '1']"
          :to="{ name: 'library-project' }"
          @shortkey="goTo('library-project')"
        >
          Project
        </v-tab>
        <v-tab
          v-shortkey="['ctrl', 'alt', '2']"
          :to="{ name: 'library-groups' }"
          @shortkey="goTo('library-groups')"
        >
          Groups
        </v-tab>
        <v-tab
          v-shortkey="['ctrl', 'alt', '3']"
          :to="{ name: 'library-job-description' }"
          @shortkey="goTo('library-job-description')"
        >
          Job Description
        </v-tab>
        <v-tab
          v-shortkey="['ctrl', 'alt', '4']"
          :to="{ name: 'library-employees' }"
          @shortkey="goTo('library-employees')"
        >
          Employees
        </v-tab>
        <v-tab
          v-shortkey="['ctrl', 'alt', '5']"
          :to="{ name: 'library-i18n' }"
          @shortkey="goTo('library-i18n')"
        >
          Internationalization
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Common/ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },
  methods: {
    logout() {
      this.$refs.confirmDialog
        .open({ message: 'Are you sure you want to logout?', confirmButtonText: 'Yes' })
        .then(confirm => {
          if (confirm) {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/login');
            });
          }
        });
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 9999;

  &__top {
    align-items: flex-end !important;
    .v-tab {
      font-size: 18px;
      color: #fff !important;
      text-transform: uppercase;
    }
  }

  &__alt {
    top: 54px;
    font-size: 16px;
    .v-tab {
      font-size: 16px;
    }
  }
}
</style>
